import React from "react";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";

import { LocationSuggestion, ZipCodeSuggestion } from "../../types/fieldTypes";

const styles = {
  autocomplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      background: "#f8f9fd !important;",
    },
  },
};

export default function CustomAutoComplete(props: any) {
  const {
    addressOptions,
    zipCodeOptions,
    selectedAddressValue,
    selectedZipCodeValue,
    getAddressOptionLabel,
    getPostalOptionLabel,
    addressOnChange,
    postalOnChange,
    addressInputValue,
    zipCodeInputValue,
    postalCodeOnInputChange,
    addressOnInputChange,
    errorMsg,
    component,
    childIdx,
    isApplicationSubmitted,
    onBlur,
    label,
    field,
    translation,
    loanContextData,
    isExpandedView
  } = props;
  return (
    <FormControl variant="outlined" sx={isExpandedView ? { width: "100%" }  : { width: "80%" }}>
      <Autocomplete
        className="truncate-words"
        options={(label === "Address" ? addressOptions : zipCodeOptions) ?? []}
        size="small"
        filterOptions={(x: any) => x}
        filterSelectedOptions
        disableClearable
        value={
          (label === "Address" ? selectedAddressValue : selectedZipCodeValue) ??
          null
        }
        getOptionLabel={
          label === "Address" ? getAddressOptionLabel : getPostalOptionLabel
        }
        disabled={isApplicationSubmitted}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: LocationSuggestion | ZipCodeSuggestion | null
        ) => {
          label === "Address"
            ? addressOnChange(event, newValue, childIdx, field, translation,
              loanContextData)
            : postalOnChange(event, newValue, childIdx, field, translation,
              loanContextData);
        }}
        inputValue={label === "Address" ? addressInputValue : zipCodeInputValue}
        onInputChange={(event: React.SyntheticEvent<Element, Event>, value: string) =>
          label === "Address"
            ? addressOnInputChange(event, value, translation,
              loanContextData, childIdx, field)
            : postalCodeOnInputChange(event, value, translation, loanContextData, childIdx)
        }
        onBlur={onBlur}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            value={
              (label === "Address"
                ? selectedAddressValue?.street_line
                : selectedZipCodeValue) || ""
            }
            margin="dense"
            className={`${!!errorMsg && "app-theme-data-points-error"}`}
            style={ isExpandedView ? { width: "calc(100% - 2px)" } : {
              width: "100%",
            }}
            variant="outlined"
            disabled={isApplicationSubmitted}
            sx={
              (styles.autocomplete,
                { borderRadius: "6px", background: "#f8f9fd !important;" })
            }
          />
        )}
        selectOnFocus
      />
      <FormHelperText className="break-word">{errorMsg}</FormHelperText>
    </FormControl>
  );
}
