import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { ObjectType } from "../../types/tableTypes";

export default function CheckBox({
  label,
  field,
  value,
  handleChange,
  subComponentLabel,
  component,
  translation,
  loanContextData,
  childIdx,
  unitIdx,
  errorMsg,
  isApplicationSubmitted,

}: {
  label: any;
  field: any;
  value: any;
  handleChange: any;
  subComponentLabel: string;
  component: ObjectType | undefined;
  translation: any;
  loanContextData: ObjectType;
  childIdx?: number | undefined;
  unitIdx?: number;
  errorMsg?: string;
  isApplicationSubmitted?: boolean;
}) {
  let checkedValue = value;

  if (field.id === "includeOutOfPocketBudgetARLTV" && value === undefined) {
    checkedValue = true;
  }
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={checkedValue}
          onChange={(e: any) =>
            handleChange(
              e.target.checked,
              field,
              subComponentLabel,
              translation,
              loanContextData,
              component,
              childIdx,
              unitIdx
            )
          }
          disabled={isApplicationSubmitted || field?.disableField}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
    />
  );
}
