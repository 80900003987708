import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute" as "absoulte",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "80%",
  overflowY: "auto",
  backgroundColor: "background.paper",
  borderRadius: "8px",
  outline: "none",
};

const saveApplication = {
  width: "40%",
};

const condoEligibility = {
  width: "80%",
  padding: "16px",
};

export default function CustomModal({
  open,
  handleClose,
  children,
  className,
}: {
  open: boolean;
  handleClose: any;
  children: any;
  className?: string;
}) {
  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="custom-modal-os"
    >
      <Box
        sx={style}
        style={
          className === "saveApplication" ? saveApplication : condoEligibility
        }
      >
        {children}
      </Box>
    </Modal>
  );
}
