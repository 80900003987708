import { Typography } from "@mui/material";
import NoFailedTestIcon from "../../assets/NoFailedTests.svg";

import React from "react";


const style = {
  text: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "center",
    color: "#666666"
  },
  positionCenter: {
    display: "flex", alignItems: "center", justifyContent: "center"
  }
}
export default function NoFailedTest() {
  return (
    <div >
      <div style={style?.positionCenter}>
        <img src={NoFailedTestIcon} alt="No Failed Tests" style={{ marginBottom: "24px" }}/>
      </div>
      <div style={style?.positionCenter}>
        <Typography sx={style?.text}>No failed tests
          for the Program</Typography>
      </div>

    </div>
  );
}