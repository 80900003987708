import React from "react";
import { useTranslation } from "react-i18next";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { ObjectType } from "../../types/tableTypes";

export default function CustomButtonGroup({
  buttons,
  selectedButton,
  handleDataViewChange,
  className,
  buttonSize,
  translation,
  loanContextData,
  field,
  subComponentLabel,
  theme,
  errorMsg,
  isDataView,
  component,
  childIdx,
  unitIdx,
  isApplicationSubmitted,
  isExpandedView,
}: {
  buttons: any;
  selectedButton: string;
  handleDataViewChange: any;
  className: string;
  buttonSize: string;
  translation: any;
  loanContextData: ObjectType;
  field?: any;
  subComponentLabel?: any;
  theme?: any;
  errorMsg?: string;
  isDataView?: boolean;
  component?: ObjectType | undefined;
  childIdx?: number | undefined;
  unitIdx?: number;
  isApplicationSubmitted?: boolean;
  isExpandedView?: boolean;
}) {
  const { t } = useTranslation();

  let selectedBtn = selectedButton;

  if (field?.label === "decliningMarkets" && selectedButton === undefined) {
    selectedBtn = "No";
  }

  return (
    <FormControl variant="outlined" sx={!isDataView ? { width: "80%" } : isExpandedView ? { width: "100%" } : {}}>
      <ButtonGroup>
        {buttons?.map((btn: any, idx: number) => {
          return (
            <Button
              key={idx}
              className={`${btn.value === selectedBtn
                ? `${className} ${className}-active`
                : className
                } ${!!errorMsg && "app-theme-data-points-error"}`}
              variant={btn.label === selectedBtn ? "contained" : "outlined"}
              disabled={isApplicationSubmitted}
              onClick={() =>
                handleDataViewChange(
                  btn.value,
                  field,
                  subComponentLabel,
                  translation,
                  loanContextData,
                  component,
                  childIdx,
                  unitIdx
                )
              }
              data-testid={btn.label}
            >
              {t(btn.label)}
            </Button>
          );
        })}
      </ButtonGroup>
      <FormHelperText className="break-word">{errorMsg}</FormHelperText>
    </FormControl>
  );
}
