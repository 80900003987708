import React, { useState, useRef, useEffect, useMemo } from "react";
import { createStyles, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8,
    },
    menuRoot: {
      width: 200,
      // marginTop: 22
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      maxHeight: "250px",
      overflow: "auto",
    },
    menuList: {
      padding: 0,
    },
    listIconRoot: {
      minWidth: 20,
      position: "relative",
      paddingRight: 13,
      left: 10,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
    },
    zeroState: {
      padding: 10,
    },
    badgeFilter: {
      height: "6px !important",
      width: "6px !important",
      backgroundColor: "#5566d6",
      zIndex: "auto",
      minWidth: "6px",
    },
  })
);

export function useColumnTypeComponents(props: any) {
  const {
    handleFilterToggle,
    columnName,
    loanTypeOptions,
    setSelected,
    setLocalSelections,
    columnLoanTypeFilter,
    handleCheckBoxChange,
    selected,
    localSelections,
    checked,
    tfChecked,
    otherTagsChecked,
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open); // return focus to the button when we transitioned from !open -> open

  const sortedOptions = useMemo(() => {
    const clone = [...loanTypeOptions];
    return clone?.sort((a, b) => {
      const temp1 = a?.key?.toString()?.toLowerCase();
      const temp2 = b?.key?.toString()?.toLowerCase();
      if (temp1 < temp2) {
        return -1;
      }
      if (temp1 > temp2) {
        return 1;
      }
      return 0;
    });
  }, [loanTypeOptions]);

  const handleToggle = () => {
    setOpen(!open);
    handleFilterToggle(columnName);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    if (columnLoanTypeFilter) {
      setSelected({
        "loan.loanDetailId.fundingType.raw": tfChecked,
        "loan.loanProcessType.raw": otherTagsChecked,
      });
      setLocalSelections([]);
    }
  };

  const handleCheckBoxToggle = (e: any, title: any) => {
    if (columnLoanTypeFilter) {
      const val = e.currentTarget.id;
      const key = e.currentTarget.dataset.type;
      const temp = selected?.[key] ?? [];
      const isChecked = temp.includes(val);
      let newChecked = [...temp];
      let newLocal = [...localSelections];
      if (isChecked) {
        newChecked = newChecked.filter((i: any) => i !== val);
      } else {
        newChecked.push(val);
      }
      if (newLocal.includes(val)) {
        newLocal = newLocal.filter((i: string) => i !== val);
      } else {
        newLocal.push(val);
      }
      setSelected({ ...selected, [key]: newChecked });
      setLocalSelections(newLocal);
    } else {
      const val = e.currentTarget?.id;
      const isChecked = checked.includes(val);
      //handle Select All options
      if (val === "Select All") {
        if (!isChecked) {
          const allItems: any[] = [];
          sortedOptions.forEach((item) => {
            allItems.push(item.key);
          });
          return handleCheckBoxChange({ [columnName]: allItems });
        } else {
          return handleCheckBoxChange({ [columnName]: [] });
        }
      }

      let newChecked = [...checked];
      if (isChecked) {
        newChecked = newChecked.filter(
          (i: any) => i !== "Select All" && i !== val
        );
      } else {
        newChecked.push(val);

        const allKeysAdded = sortedOptions.every((item) =>
          item.key === "Select All" ? true : newChecked.includes(item.key)
        );

        if (allKeysAdded) {
          newChecked.push("Select All");
        }
      }
      const headerFilterTags = { [columnName]: { [title]: newChecked } };
      handleCheckBoxChange({ [columnName]: newChecked }, headerFilterTags);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return {
    handleListKeyDown,
    handleToggle,
    handleClose,
    handleCheckBoxToggle,
    sortedOptions,
    open,
    setOpen,
    anchorRef,
    useStyles,
  };
}
