/* eslint-disable no-shadow */
import { Grid, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import KDMHeader from "./KDMHeader";

export interface KeyDealMetricsProps {
  loanId?: string;
  loanStage?: any;
  loanType?: string;
  takeoutPartnerId?: string | null;
  KDMBody?: any
  logo?: any
  header?: any
}


/** This is a KeyDealMetrics wrapper component that contains all state variables and logic */
export const KDMExport = forwardRef<HTMLDivElement, KeyDealMetricsProps>((props, ref) => {
  const { KDMBody, header, logo } = props;
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const getPageMargins = () => {
    return `@page { 
        margin: ${10} ${10} ${10} ${10} !important;
        size: 210mm 297mm;
      }`;
  };
  const defaultMsg: string =
    "The information provided in this system is for guidance purposes only and should not be considered as a commitment to buy any loan. All loan acquisitions, including the related pricing and terms, are subject to mutual execution of the related purchase advice following Toorak’s satisfactory completion of due diligence. All loan acquisitions are made pursuant to the applicable master loan purchase agreement.";
  return (
    <>
      <div
        className="print-source"
        data-testid="loanlist-container"
        ref={ref}
        style={{ width: "100%", zIndex: 999 }}
      >
        <KDMHeader label={header} handleShowKDMBody={() => {}} icon={logo}  />
        <style>{getPageMargins()}</style>


        <div>
          <KDMBody isPrint={true}/>
          {errorMessage && (
            <Grid
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid style={{ marginTop: "15vh", marginBottom: "15vh" }}>
                <Typography>{errorMessage}</Typography>
              </Grid>
            </Grid>
          )}
        </div>
        {/* </Grid> */}

        <div className="page-break" />
        <div style={{ position: "relative", bottom: "0", width: "100%" }}>
          <section>
            <Typography
              style={{
                top: 0,
                fontSize: 12,
                color: "#ffffff",
                lineHeight: 1,
                fontWeight: "normal",
                maxWidth: "100%",
                padding: "6px 20px 6px 20px",
                backgroundColor: "#6A7490"
              }}
            >
              {defaultMsg}
            </Typography>
          </section>
        </div>
      </div>
    </>
  );
});
