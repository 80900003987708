import React from "react";
import { SortOrder } from "../../../utils/mappings";

export const FilterFunnelIcon = (props: { filled: boolean }) => {
  const { filled } = props;
  return (
    <svg className="App-SvgIcon" viewBox="0 0 14 14" data-testid="filter-funnel-icon">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-160.000000, -169.000000)">
          <g transform="translate(37.000000, 166.000000)">
            <g transform="translate(123.000000, 3.000000)">
              <rect
                fillRule="nonzero"
                x="0"
                y="0"
                width="14"
                height="14"
              ></rect>
              <path
                d="M11.2042893,2.79571068 L8.09375,5.90625 L8.09375,5.90625 L8.09375,11.3350893 C8.09375,11.3903178 8.04897847,11.4350893 7.99375,11.4350893 C7.9655755,11.4350893 7.93870821,11.423204 7.91975599,11.4023566 L5.93225599,9.21610659 C5.91552218,9.1976994 5.90625,9.17371592 5.90625,9.14883931 L5.90625,5.90625 L5.90625,5.90625 L2.79571068,2.79571068 C2.75665825,2.75665825 2.75665825,2.69334175 2.79571068,2.65428932 C2.81446432,2.63553568 2.83989971,2.625 2.86642136,2.625 L11.1335786,2.625 C11.1888071,2.625 11.2335786,2.66977153 11.2335786,2.725 C11.2335786,2.75152165 11.223043,2.77695704 11.2042893,2.79571068 Z"
                stroke={filled ? "#5566d6" : "#32325D"}
                strokeWidth="0.9"
                fill={filled ? "#5566d6" : "none"}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const FilterSortIcon = (props: { sortState: string }) => {
  const { sortState } = props;
  return (
    <svg className="App-SvgIcon" viewBox="0 0 14 14" data-testid="filter-sort-icon">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path d="M0 0h16v16H0z" />
        <path d="M0 0h16v16H0z" />
        <g fill="#32325D" fillRule="nonzero">
          <path
            d="M11.286 3.475c.136 0 .267.05.364.14l3.086 2.857c.13.12.18.296.133.46a.497.497 0 01-.364.338.544.544 0 01-.498-.123L11.8 5.1l.001 8.375c0 .263-.23.476-.514.476-.284 0-.515-.213-.515-.476V5.101L8.564 7.147a.544.544 0 01-.497.123.497.497 0 01-.364-.337.453.453 0 01.133-.46l3.086-2.858a.536.536 0 01.364-.14z"
            opacity={[SortOrder.ascending, ""].includes(sortState) ? 1 : 0.3}
          />
          <path
            d="M1.664 10.282a.536.536 0 01.365-.14c.136 0 .267.05.364.14l2.721 2.52 2.722-2.52c.13-.12.32-.168.497-.123a.497.497 0 01.364.337.453.453 0 01-.133.46l-3.086 2.857a.536.536 0 01-.364.14.536.536 0 01-.364-.14l-3.086-2.857a.46.46 0 01-.15-.337.46.46 0 01.15-.337z"
            opacity={[SortOrder.descending, ""].includes(sortState) ? 1 : 0.3}
          />
          <path
            d="M5.114 3.476c.284 0 .515.213.515.476v8.572c0 .263-.23.476-.515.476-.284 0-.514-.213-.514-.476V3.952c0-.263.23-.476.514-.476z"
            opacity={[SortOrder.descending, ""].includes(sortState) ? 1 : 0.3}
          />
        </g>
      </g>
    </svg>
  );
};
