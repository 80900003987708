import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

type DisclaimerPopupWithButtonsPropTypes = {
    openDisclaimer : boolean 
    closeDisclaimer? : () => void
    disclaimerHeading: string
    disclaimerText: string
    containedButtonLabel: string
    containedButtonHandler? : () => void
    outlinedButtonLabel: string
    outlinedButtonHandler? : () => void
}

const DisclaimerPopupWithButtons = (props: DisclaimerPopupWithButtonsPropTypes) => {
  const { 
    openDisclaimer,
    closeDisclaimer,
    disclaimerHeading,
    disclaimerText,
    containedButtonLabel,
    containedButtonHandler,
    outlinedButtonLabel,
    outlinedButtonHandler
    } = props;

  return (
    <Dialog
      open={openDisclaimer}
      keepMounted
      onClose={closeDisclaimer}
      aria-describedby='disclaimer-dialog'
      data-testid='disclaimer-dialog'
      sx={{ borderRadius: "8px 8px 0px 0px" }}
    >
      <DialogTitle
        sx={{
          background: "#433182",
          color: "white",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <span>{disclaimerHeading}</span>
        <IconButton onClick={closeDisclaimer} sx={{padding:"0px"}}>
          <CloseIcon sx={{color: "#fff", fontSize: '2rem'}} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "32px 16px 16px 16px"
        }}
      >
        <DialogContentText
          id='alert-dialog-slide-description'
          sx={{ paddingTop: "20px" }}
        >
          {disclaimerText}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "flex-start", padding: "16px" }}>
        <Button variant='contained' onClick={containedButtonHandler}>
          {containedButtonLabel}
        </Button>
        <Button variant='outlined' onClick={outlinedButtonHandler}>
          {outlinedButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerPopupWithButtons;
