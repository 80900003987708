export const validateEmail = (email: string) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (email.length === 0) {
    return { isValid: false, key: "enterEmail" };
  }

  if (!emailRegex.test(email)) {
    return { isValid: false, key: "validEmail" };
  }

  return { isValid: true, key: "validEmail" };
};

export const validateOTP = (otp: string) => {
  if (typeof +otp !== "number") {
    return { isValid: false, key: "validOTP" };
  }
  if (!/^\d{4}$/.test(otp)) {
    return { isValid: false, key: "validOTP" };
  }
  return { isValid: true, key: "" };
};

export const validateForm = (form: any) => {
  const hasfalsyValue = Object.values(form).filter((value: unknown) => !value);
  return hasfalsyValue.length > 0;
};

export function isFalsyButNotZero(value: any) {
  return ![false, null, undefined, NaN, ""].includes(value);
}

export const isLetters = (str: any) => /^[A-Za-z]*$/.test(str);
