/* eslint-disable react-refresh/only-export-components */
import React from "react";

import { Badge, IconButton } from "@mui/material";

import { FilterFunnelIcon } from "./TableIcons";

import { isString, sanitizeCurrency } from "../../../utils/Common";

export const filterMasterView = (props: any) => {
  const { anchorRef, open, isfilterApplied, classes } = props;
  const handleToggle = (e: any) => {
    props.handleToggle();
  };
  return (
    <IconButton
      data-testid="filter-master-view-icon-button"
      size="small"
      ref={anchorRef}
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={(e) => handleToggle(e)}
    >
      <Badge
        color="primary"
        variant="dot"
        invisible={!isfilterApplied}
        classes={{ badge: classes.badgeFilter }}
      >
        <FilterFunnelIcon filled={isfilterApplied} />
      </Badge>
    </IconButton>
  );
};

export const AmountFormatter = (props: { data: any; classes: any }) => {
  const { data, classes } = props;
  let valueString: string = data?.toString();
  if (isString(valueString) && valueString && !valueString.match(/[^$,.\d]/)) {
    valueString = sanitizeCurrency(valueString);
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};

export const PercentFormatter = (props: {
  data: any;
  classes: any;
  multiplyFactor?: number;
}) => {
  const { data, classes, multiplyFactor = 1 } = props;
  let valueString = data?.toString();
  if (![null, undefined, ""].includes(data) && !valueString.match(/[^%,.\d]/)) {
    const value = (Number(data) * multiplyFactor).toFixed(4);
    const paddedUnit = valueString.indexOf("%") === -1 ? "%" : "";
    valueString = `${value}${paddedUnit}`;
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};
