import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en", // default language
    fallbackLng: "en", // Fallback language if the translation for the selected language is not available
    interpolation: {
      escapeValue: false, // Allow using HTML tags in translations
    },
  });

export default i18n;
