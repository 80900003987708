import React from 'react';
import { renderSubComponentUI } from './Common';

export default function AutoCalculatedFields(props: any) {
  const { loanContextData, loanType, theme, translation, idx } = props;
  const { autoCalculatedFields, propertyDetails, loanDetails } = loanContextData;

  return (
    <div>
      {autoCalculatedFields?.fields?.map((field: any, index: number) => {
        if (Array.isArray(field)) {
          return field.map((subField: any, propIdx: number) => {
            return Object.keys((subField))?.map((fieldKey: any, fieldIdx: number) => {
              return <div key={fieldIdx}>
                {renderSubComponentUI(
                  subField[fieldKey],
                  propIdx,
                  propertyDetails[index]?.[field[propIdx]?.label],
                  field[propIdx],
                  loanType,
                  theme,
                  translation,
                  loanContextData,
                  idx,
                  { isAutoCalculated: true, propLevel: true },
                )}
              </div>
            })
          })
        }
        return <div key={index}>
          <div key={index}>
            {renderSubComponentUI(
              field,
              index,
              loanDetails?.[field?.label],
              field,
              loanType,
              theme,
              translation,
              loanContextData,
              idx,
              { isAutoCalculated: true, propLevel: false },
            )}
          </div>
        </div>
      })}
    </div>
  )
}
