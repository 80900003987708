const estimatedClosingCosts = {
  id: "estimatedClosingCosts",
  label: "Estimated Closing Costs",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const downPayment = {
  id: "downPayment",
  label: "Down Payment",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const originationFee = {
  id: "originationFee",
  label: "Origination Fee",
  isVisible: () => true,
  isEditable: () => true,
  isCurrency: true
};

const taxesAndInsuranceReserves = {
  id: "taxesAndInsuranceReserves",
  label: "Taxes and Insurance Reserves",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const closingFee = {
  id: "closingFee",
  label: "Closing Fee",
  isVisible: () => true,
  isEditable: () => true,
  isCurrency: true
};

const underWritingFee = {
  id: "underwritingfee",
  label: "Underwriting Fee",
  isVisible: () => true,
  isEditable: () => true,
  isCurrency: true
};

const totalEstimatedClosingCosts = {
  id: "totalEstimatedClosingCosts",
  label: "Total Estimated Closing Costs",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const appraisalFee = {
  id: "appraisalFee",
  label: "Appraisal/Appraisal Review Fee",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const interestReserves = {
  id: "interestReserves",
  label: "Interest Reserves",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

const outOfPocketRehabCosts = {
  id: "outOfPocketRehabCosts",
  label: "Out of Pocket Rehab Costs",
  isVisible: () => false,
  isEditable: () => true,
  isCurrency: true
};

export const DSCRDefaultFeeDetailsDefault = [
  originationFee,
  estimatedClosingCosts,
  downPayment,
  taxesAndInsuranceReserves,
  closingFee,
  underWritingFee,
  totalEstimatedClosingCosts,
  appraisalFee
];

export const BridgeFeeDetails = [
  estimatedClosingCosts,
  downPayment,
  interestReserves,
  underWritingFee,
  originationFee,
  outOfPocketRehabCosts,
  closingFee,
  totalEstimatedClosingCosts
];   

const convertStringToCamelCase = (str: string) => {
return str
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word; // Keep the first word lowercase
      }
      // Capitalize the first letter of subsequent words
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
};

function toTitleCaseWithSpaces(str: string) {
  return str
    // .toLowerCase() // Convert the string to lowercase
    .split('_') // Split by underscores
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with spaces
}

export function convertStringToSnakeCase(str: string) {
   return str.toLowerCase().replace(/\s+/g, '_');
}

export const normalizeFeeDetailsConfig = (feeConfigs: any, type: any) => {
  const dscrConfig = feeConfigs?.termSheetDynamicFields?.filter((field: any) => field?.loanType === "30 Year Loan");
  const bridgeConfig = feeConfigs?.termSheetDynamicFields?.filter((field: any) => field?.loanType === "Bridge Loan");

  switch (type) {
    case "InvestorDSCR":
      return dscrConfig?.map((field: any) => {
        const id = convertStringToCamelCase(field?.field);
        return {
          id,
          label: toTitleCaseWithSpaces(field?.field),
          // isVisible: () => field?.isActive,
          isVisible: () => true,
          isEditable: () => true,
          isCurrency: field.dataType === "currency"
        };
      });
      case "BridgeLoan":
        return bridgeConfig?.map((field: any) => {
          const id = convertStringToCamelCase(field?.field);
          return {
            id,
            label: toTitleCaseWithSpaces(field?.field),
            // isVisible: () => field?.isActive,
            isVisible: () => true,
            isEditable: () => true,
            isCurrency: field.dataType === "currency"
          };
        });
        default:
          return [];
  }
}