import { Button, Grid } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { useTranslation } from 'react-i18next';
import { LoansContext } from '../../context/LoansContext';
import CloseIcon from '../../assets/Close-Icon-lg.svg'
import InfoIcon from '../../assets/InfoIconBlack.svg'
import ToorakLogo from '../../assets/Toorak_Logo.svg'
import KDMModal from './KDMModal';
import { useParams } from 'react-router-dom';
import { formatValueByType } from '../../utils/Common';
import { orgBucketResponse } from '../../services/network';

interface KDMWrapperProps {
  openGoogleMap?: (label: string) => void; // Add the prop definition
}


type ParamType = {
  loanType: string | undefined;
  loanId: string | undefined;
  propType: string | undefined;
};

const KDMWrapper = (props: KDMWrapperProps) => {
    const { t } = useTranslation();
    const loanContextData = useContext(LoansContext);
    const [showKDMBody, setShowKDMBody] = useState<boolean>(false)
    const [data, setData] = React.useState<{
      loanDetails: any;
      propertyDetails: any;
      ruleResult: any;
      propertiesResults: any;
      loanStage: string;
      loanType: string | undefined;
      originatorInfo: any;
    } | null>(null);

    const params = useParams<ParamType>();
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ 
      contentRef
    });

    const { loanType, loanId } = params;

    const handleShowKDMBody = (status: boolean) => {
      setShowKDMBody(status)
    }

    const openGoogleMap = (label: string) => {
      const query = `http://maps.google.com/?q=${label}`;
      window.open(query);
    };

    useEffect(() => {
      if(loanId) {
        init(loanId)
      }

    },[loanContextData, loanId])

    const init = async (loanId: string) => {
      let originator: any = null
      const assignedPartner = loanContextData?.assignedPartner
        const rulesResponse = loanContextData?.rulesResponse ? loanContextData?.rulesResponse|| {} : loanContextData?.loanDetails?.rulesResponse || {}
        const loanResultData = rulesResponse?.loanResult ? rulesResponse?.loanResult[loanId] : rulesResponse ? rulesResponse[assignedPartner ? assignedPartner : Object.keys(rulesResponse)[0]]?.loanResult[loanId]: {}
        const propertiesResults = rulesResponse?.propertiesResults ? rulesResponse?.propertiesResults["0"] : rulesResponse ? rulesResponse[assignedPartner ? assignedPartner : Object.keys(rulesResponse)[0]]?.propertiesResults["1"] : {}
        if(loanContextData?.loanDetails?.orgId) {
          const response = await orgBucketResponse(loanContextData?.loanDetails?.orgId)
          if(response?.data?.accounts?.length){

            originator = response?.data?.accounts?.find((ele: any) => ele?.partyId === loanContextData?.loanDetails?.orgId)
            
          }
        }
        setData({
          loanDetails: loanContextData?.loanDetails,
          propertyDetails: loanContextData?.propertyDetails,
          ruleResult: loanResultData,
          propertiesResults: propertiesResults,
          loanStage: "",
          loanType,
          originatorInfo: originator
        });
    }

    console.log("KDM WRAPPER", {loanContextData, data})
    

    return (
        <div>
          <Grid item className="gray-border">
            <Button
              className="mr-12"
              variant="outlined"
              onClick={() => handleShowKDMBody(true)}
              data-testid="key-deal-metrics-button"
            >
              {t("kdm")}
            </Button>
            {showKDMBody ? 
              <KDMModal
                header= {t("kdm")}
                handleShowKDMBody={handleShowKDMBody}
                icons={{CloseIcon, InfoIcon, ToorakLogo}}
                data={data}
                config={{
                  formatValueByType: formatValueByType,
                  openGoogleMap: openGoogleMap,
                  componentRef: contentRef,
                  reactToPrintFn: reactToPrintFn,
                  loanId: loanId
                }}
              /> 
              : null}
          </Grid>
        </div>
      )
}

export default KDMWrapper
