import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";

import { ObjectType } from "../../../types/tableTypes";
import { SortOrder } from "../../../utils/mappings";
// import { FilterSortIcon } from "./MasterViewIcons";

interface SortFilterProps {
  columnName: string;
  activeSortState: string;
  onSort: (val: ObjectType) => void;
}

const SortFilter = (props: SortFilterProps) => {
  // const { tableSortConfig } = useSelector<RootState, MasterViewStore>(
  //   (state) => state.masterViewStore
  // );
  const [sortState, setSortState] = useState("");
  const { columnName, activeSortState = "", onSort } = props;
  const onClick = () => {
    const orderValue =
      activeSortState === SortOrder.ascending
        ? SortOrder.descending
        : SortOrder.ascending;
    onSort({ [columnName]: orderValue });
    setSortState(orderValue);
  };
  useEffect(() => {
    setSortState(activeSortState);
  }, [activeSortState]);

  return (
    <IconButton size="small" onClick={onClick} data-testid="sort-button">
      {/* <FilterSortIcon sortState={sortState} /> */}
    </IconButton>
  );
};

export default SortFilter;
