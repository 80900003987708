import React from "react";

import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import NoFailedTest from "./NoFailedTest";

export default function TestTabResultView({
  tabData,
  isExpanded,
  expandLeverageAndTests,
  setIsExpanded,
  loanDetails,
  eligiblePartnerResults,
  changePartner,
  isLoanDSCR,
  ratesAsOfDate,
  CustomCard,
  CustomChip,
  DSCROrgPartnerMapping,
  DownArrow,
  InfoIcon,
  NonCriticalTestIcon,
  CriticalTestIcon,
  isApplicationSubmitted,
  CheckCirclePass,
  showRadioSelection,
}: {
  tabData: any;
  isExpanded: any;
  expandLeverageAndTests: boolean;
  setIsExpanded: any;
  loanDetails: any;
  eligiblePartnerResults: any;
  changePartner: any;
  CustomCard: any;
  CustomChip: any;
  DSCROrgPartnerMapping: any;
  DownArrow: any;
  InfoIcon: any;
  isLoanDSCR?: boolean;
  ratesAsOfDate?: string;
  NonCriticalTestIcon?: any,
  CriticalTestIcon?: any
  isApplicationSubmitted?: boolean;
  CheckCirclePass?: any;
  showRadioSelection?: boolean;
}) {
  const contentDiv = {
    padding: "12px",
    borderRadius: "6px",
    background: "white",
  };

  const gridSize = Math.floor(12 / tabData?.length);
  const finalDataToBeDisplayed = tabData.sort((a: any, b: any) => {
    const countCriticalFailures = (obj: any) => {
      // Get the first key of the object and count the number of critical failures
      const key = Object.keys(obj)[0];
      return obj[key].filter((item: any) => item.isCriticalTest).length;
    };

    return countCriticalFailures(a) - countCriticalFailures(b);
  });

  return (
    <div>
      <Grid
        container
        spacing={expandLeverageAndTests ? 0 : 1}
        className="mt-12"
        style={
          expandLeverageAndTests
            ? { overflowX: "auto", flexWrap: "nowrap" }
            : {}
        }
      >
        {finalDataToBeDisplayed?.map((test: any, idx: number) => {
          const criticalFailedTests: any = test[Object.keys(test)[0]]?.filter((test: any) => test?.isCriticalTest)?.length;
          const nonCriticalFailedTests: any = test[Object.keys(test)[0]]?.filter((test: any) => !test?.isCriticalTest)?.length;

          const currentTabKey = Object.keys(test)[0];
          const currentId = Object.keys(DSCROrgPartnerMapping).find((key: string) => DSCROrgPartnerMapping[key] === currentTabKey);
          const currentData: any = {
            title: currentTabKey,
            id: currentId
          };

          return !expandLeverageAndTests ? (
            <Grid item xs={gridSize} key={idx}>
              {isLoanDSCR ? (
                <div style={{
                  padding: "12px",
                  background: "#E4EAFFCC",
                  border: "1px solid #DBE2FF",
                  fontSize: "18px",
                  fontWeight: 600,
                  minHeight: "100px"
                  }}>
                  <Grid sx={{
                    padding: "12px",
                    // background: "#E4EAFFCC",
                    // border: "1px solid #DBE2FF",
                    // fontSize: "18px",
                    // fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    {showRadioSelection ? <Radio
                      checked={
                        [loanDetails?.takeOutPartner].includes(currentData?.id) &&
                        eligiblePartnerResults?.dailyCreditLimit?.[currentData?.id]?.isWithinLimit
                      }
                      disabled={!eligiblePartnerResults?.dailyCreditLimit?.[currentData?.id]?.isWithinLimit || isApplicationSubmitted}
                      onChange={(e) => changePartner(currentData)}
                    /> : null}
                    <span>{Object.keys(test)[0]}</span>&ensp;
                    {criticalFailedTests ? <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={CriticalTestIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          {`Critical Fails - ${criticalFailedTests}`}
                        </div>
                      }
                      variant="outlined"
                      className="critical-failed-chip"
                    /> : null}
                    {nonCriticalFailedTests ? <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={NonCriticalTestIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          {`Non-critical Fails - ${nonCriticalFailedTests}`}
                        </div>
                      }
                      variant="outlined"
                      className="non-critical-chip-data"
                    /> : null}
                    {!nonCriticalFailedTests && !criticalFailedTests ?
                      <img
                        src={CheckCirclePass}
                        alt="check-pass-circle"
                        className="mr-8"
                      /> : null}
                  </Grid>
                  <Grid
                    style={
                      test[Object.keys(test)[0]]?.length
                        ? { background: "white", padding: 16, minHeight: "90%" }
                        : {
                          background: "white",
                          padding: 16,
                          minHeight: "90%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }
                    }
                  >
                    {test[Object.keys(test)[0]]?.map(
                      (testEle: any, idx: number) => {
                        if (testEle) {
                          return (
                            <CustomCard
                              key={idx}
                              headingText={testEle.title}
                              subHeadingText={testEle.subText}
                              loanDetails={loanDetails}
                              fullView={true}
                              ratesAsOfDate={ratesAsOfDate}
                              gridSpace={testEle?.gridSpace ?? 4}
                              showFailed={true}
                              icon={testEle?.isCriticalTest ? CriticalTestIcon : NonCriticalTestIcon}
                            />
                          );
                        }
                      }
                    )}
                    {!test[Object.keys(test)[0]]?.length ? <NoFailedTest /> : null}
                  </Grid>
                </div>
              ) : (
                <CustomCard
                  key={idx}
                  headingText={test.title}
                  subHeadingText={test.subText}
                  loanDetails={loanDetails}
                  fullView={true}
                  ratesAsOfDate={ratesAsOfDate}
                  gridSpace={test?.gridSpace ?? 4}
                  showFailed={true}
                />
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={gridSize <= 4 ? 5 : gridSize}
              style={{
                borderRight: "1px solid #A9A9A9",
                minWidth: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px 0px 0px 0px",
                  border: "1px solid #DBE2FF",
                  background: "rgba(228, 234, 255, 0.80)",
                  padding: "0px 10px",
                }}
              >
                <h3>{isLoanDSCR ? currentTabKey : test.title}</h3>
                <CustomChip
                  size="small"
                  label={
                    <div className="div-space-between">
                      <img src={InfoIcon} alt="info-icon" className="mr-8" />{" "}
                      {isLoanDSCR
                        ? `Failed - ${test[currentTabKey]?.length}`
                        : "Failed"}
                    </div>
                  }
                  variant="outlined"
                  className="error-chipData"
                  data-testid="custom-chip"
                />
              </div>
              <div>
                {isLoanDSCR ? (
                  test[currentTabKey]?.map(
                    (testEle: any, idx: number) => {
                      return (
                        <div key={idx} style={contentDiv}>
                          <CustomCard
                            headingText={testEle.title}
                            subHeadingText={testEle.subText}
                            loanDetails={loanDetails}
                            fullView={true}
                            ratesAsOfDate={ratesAsOfDate}
                            gridSpace={testEle?.gridSpace ?? 4}
                            cardCss={true}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <CustomCard
                    key={idx}
                    headingText={test.title}
                    subHeadingText={test.subText}
                    loanDetails={loanDetails}
                    fullView={true}
                    ratesAsOfDate={ratesAsOfDate}
                    gridSpace={test?.gridSpace ?? 4}
                    showFailed={true}
                  />
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
