import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  return {
    termSheetHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px",
      backgroundColor: "#362866",
      width: "100%",
      height: "60px",
    }
  }  
});

