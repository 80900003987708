import { Box, Typography } from '@mui/material'
import KDMPopOver from './KDMPopOver'

type KDMCardPropTypes = {
    label: string
    redirectionType: string
    value: any
    key: string
    popOverData: any
    icons: any
    openGoogleMap: (value: any) => void
}

const KDMCard = (props: KDMCardPropTypes) => {
    const {
        label,
        redirectionType,
        value,
        key,
        popOverData,
        icons,
        openGoogleMap
    } = props
    
  return (
    <Box sx={{border: "1px solid #DBE2FF", borderRadius: "6px", padding: "8px 0px", width: "100%", minHeight: "60px"}}>
        <Box sx={{padding: "0px 16px 8px 16px"}}>
            <Typography sx={{fontSize: "12px", color: "#362866", lineHeight: "20px", letterSpacing: "0.4"}}>{label}</Typography>
        </Box>
        <Box sx={{padding: "8px 16px", display: "flex", justifyContent: "space-between"}}>
            <Typography 
                sx={{
                    fontSize: "14px", 
                    lineHeight: "20px", 
                    letterSpacing: "0.4",
                    color: redirectionType && !popOverData?.length ? "#5e72e4" : "#000",
                    cursor: redirectionType && !popOverData?.length ? "pointer" : "default",
                }}
                onClick={() => {
                    if (redirectionType === "googleMap" && !popOverData?.length) {
                      openGoogleMap(value);
                    }
                  }}
                >{value}</Typography>
            {popOverData && popOverData.length > 0 && (
            <span
              style={{
                display: "inline-block",
                height: "16px",
                verticalAlign: "middle",
                width: "16px"
              }}
            >
              <KDMPopOver
                popOverData={Array.isArray(popOverData) ? popOverData : []}
                redirectionType={redirectionType}
                icons={icons}
                openGoogleMap={openGoogleMap}
              />
            </span>
          )}
        </Box>
    </Box>

  )
}

export default KDMCard
