import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export const ELButtonGroup = (props: any) => {
  const { list, groupLabel, onClick, selectedValue } = props;

  const { t } = useTranslation();

  return (
    <div data-testid="button-group-component">
      <h3 className="el-sub-header">{groupLabel}</h3>
      {list.map((item: any) => {
        return (
          <Button
            key={item.key}
            disabled={item.disable}
            className={
              item.disable
                ? "loan-btns disabled-btn"
                :  selectedValue === item.key
                ? "loan-btns active-btn"
                : "loan-btns"
            }
            size="large"
            onClick={() => onClick(item.key)}
            data-testid={item.key}
          >
            {t(`${item.key}`)}
          </Button>
        );
      })}
    </div>
  );
};
