import { TableCell, Box, TableRow, Checkbox, Tooltip } from "@mui/material";
import React from "react";

// import ColumnSearchFilter from "./ColumnSearchFilter";
import ColumnTypeFilter from "./ColumnTypeFilter";
import SortFilter from "./SortFilter";
// import Arrow_expand from "../images/Arrow_expand.svg";
// import Arrow_collapse from "../images/Arrow_collapse.svg";
// import { AutoCompleteFilter } from "./autoCompleteFilter/AutoCompleteFilter";
// import DateRangeFilter from "./DateRangeFilter";
// import { ColumnLoanTypeFilterMasterView } from "./ColumnLoanTypeFilterMasterView";
import { ObjectType } from "../../../types/tableTypes";

interface TableHeaderProps {
  classes: any;
  columns: ObjectType[];
  gridTemplate?: string;
  showCheckBox?: boolean;
  selectAllRows?: () => void;
  allSelected?: boolean;
  isCollapsed?: boolean;
  showCollapseButton?: boolean;
  collapsColumnIndex?: number;
  expandColumnIndex?: number;
  handleCheckBoxChange?: () => void;
  handleFilterToggle?: () => void;
  tableSearchConfig?: any;
  filtersOptions?: any;
  tableFilterConfig?: any;
  filtersLoader?: any;
  overRideStyles?: any;
  handleSearchChange?: () => void;
  handleUpdateSelectedCheckboxes?: () => void;
  handleAutoCompleteSearch?: () => void;
  tableSortConfig?: any;
  onSort?: (val: ObjectType) => void;
  // onCollapseColumn?: any;
  disableSelectAll?: boolean;
  handleDateRangeFilter?: () => void;
  fewRowsSelected?: boolean;
  selectAllTootltipText?: string;
  getRowData?: any;
  handleCheckboxOnchange?: any;
  selectedLoanType?: any;
  uncheckAll?: any;
  checkboxRef?: any;
  handleRateAsOfFilter?: any;
  dateSelected?: any;
  setDateSelected?: any;
  filterData: { [key: string]: any };
}

const TableHeaderSelect = React.memo((props: any) => {
  const {
    classes,
    selectAllRows,
    allSelected,
    disableSelectAll,
    fewRowsSelected,

    selectAllTootltipText
  } = props;

  return (
    <TableCell className={classes.stickyHeader}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        placement="right"
        arrow
        title={selectAllTootltipText}
        disableHoverListener={!selectAllTootltipText || fewRowsSelected}
      >
        <div>
          {fewRowsSelected && !allSelected ? (
            <Checkbox
              checked={!allSelected}
              indeterminate={fewRowsSelected}
              onChange={(e) => {
                selectAllRows(false);
              }}
              className={classes.stickyHeader}
              classes={{
                indeterminate: classes.resolvedCheckboxColor,
                root: classes.checkboxBase,
              }}
            />
          ) : (
            <Checkbox
              checked={allSelected}
              onChange={(e) => {
                selectAllRows(e.target.checked, true);
              }}
              className={classes.stickyHeader}
              classes={{
                checked: classes.resolvedCheckboxColor,
                root: classes.checkboxBase,
              }}
              disabled={disableSelectAll}
            />
          )}
        </div>
      </Tooltip>
    </TableCell>
  );
});

const TableHeaderRow = React.memo((props: any) => {
  const {
    classes,
    columns,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    handleCheckBoxChange,
    handleFilterToggle,
    tableSearchConfig,
    filtersOptions,
    tableFilterConfig,
    filtersLoader,
    overRideStyles,
    handleDateRangeFilter,
    handleSearchChange,
    handleUpdateSelectedCheckboxes,
    handleAutoCompleteSearch,
    tableSortConfig,
    onSort,
    // onCollapseColumn,
    showCheckBox,
    getRowData,
    handleCheckboxOnchange,
    selectedLoanType,
    uncheckAll,
    checkboxRef,
    handleRateAsOfFilter,
    dateSelected,
    setDateSelected,
    filterData
  } = props;
  let cumulativeWidth = 0;
  return columns.map((col: ObjectType, index: number) => {
    if (col?.frozen) {
      if (index === 0) {
        cumulativeWidth = showCheckBox ? 50 : 0;
      } else {
        cumulativeWidth += columns[index - 1].width;
      }
    }
    return (
      <TableCell
        component="div"
        key={col.id}
        className={`${col?.headClass ?? ""} ${col?.frozen ? classes.stickyHeader : ""
          }`}
        style={{
          ...col?.headStyle,
          ...(col?.frozen && {
            left: cumulativeWidth,
            zIndex: 1,
            boxShadow:
              (col?.expandBtn && isCollapsed) ||
                (showCollapseButton && collapsColumnIndex === index) ||
                ((isCollapsed || !showCollapseButton) &&
                  expandColumnIndex === index)
                ? "5px 0px 5px -1px #dedede"
                : undefined,
          }),
          width: col.width || "auto",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{col.title}</span>
          <span>{col.subTitle}</span>
        </div>
        {(col?.filterList?.length ||
          col?.preFilterList?.length ||
          col.showDropDownFilter ||
          col.showSort ||
          col.showSearchFilter ||
          col.showAutoSearchFilter ||
          col.showDateRangeFilter ||
          showCollapseButton) && (
            <Box {...col.boxProps}>
              {col?.preFilterList?.map((filter: any) => filter.component)}
              {col.showDropDownFilter && (
                <ColumnTypeFilter
                  columnName={col.tbField}
                  filterTitle={col.title}
                  filterData={filterData}
                  handleCheckBoxChange={
                    col.handleCheckBoxChange || handleCheckBoxChange
                  }
                  handleFilterToggle={
                    col.handleFilterToggle || handleFilterToggle
                  }
                  options={col.filtersOptions || filtersOptions?.[col.tbField]}
                  isLoading={true} // TODO update it to this later -- col.optionsLoading || filtersLoader?.[col.tbField]
                  checked={col.checkedOptions || tableFilterConfig?.[col.tbField]}
                  overRideStyles={overRideStyles}
                  showSelectAll={col.showSelectAllOption}
                  getRowData={getRowData}
                  handleCheckboxOnchange={handleCheckboxOnchange}
                  selectedLoanType={selectedLoanType}
                  uncheckAll={uncheckAll}
                  checkboxRef={checkboxRef}
                  handleRateAsOfFilter={handleRateAsOfFilter}
                  dateSelected={dateSelected}
                  setDateSelected={setDateSelected}
                />
              )}
              {/* {col.showSearchFilter && (
              <ColumnSearchFilter
                searchValue={
                  col.searchConfig || tableSearchConfig?.[col.tbField]
                }
                filterTitle={col.title}
                columnName={col.tbField}
                onSearch={handleSearchChange}
                overRideStyles={overRideStyles}
              />
            )} */}
              {/* {col.showAutoSearchFilter && (
              <AutoCompleteFilter
                options={col.filtersOptions || filtersOptions?.[col.tbField]}
                isLoading={col.optionsLoading || filtersLoader?.[col.tbField]}
                columnName={col.tbField}
                columnLabel={col.title}
                checked={col.checkedOptions || tableFilterConfig?.[col.tbField]}
                handleAutoCompleteSearch={
                  col.handleAutoCompleteSearch || handleAutoCompleteSearch
                }
                handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
                searchConfig={col.autoSearchConfig}
                filterSelections={tableFilterConfig}
              />
            )}{" "} */}
              {/* {col.showLoanTypeFilter && (
              <ColumnLoanTypeFilterMasterView
                handleFilterToggle={
                  col.handleFilterToggle || handleFilterToggle
                }
                isLoading={col.optionsLoading || filtersLoader?.[col.tbField]}
              />
            )} */}
              {/* {col.showDateRangeFilter && (
              <DateRangeFilter
                filterTitle={col.title}
                columnName={col.tbField}
                handleDateRangeChange={handleDateRangeFilter}
                filterSelections={tableFilterConfig}
              />
            )} */}
              {col.showSort && (
                <SortFilter
                  columnName={col.tbField}
                  onSort={onSort}
                  activeSortState={tableSortConfig?.[col.tbField]}
                />
              )}
              {showCollapseButton &&
                collapsColumnIndex === index &&
                !isCollapsed && (
                  <></>
                  // <img
                  //   src={Arrow_collapse}
                  //   style={{ position: "absolute" }}
                  //   className={`left-arrow ${classes.arrowLeft}`}
                  //   alt="Collapse"
                  //   onClick={onCollapseColumn}
                  // />
                )}
              {showCollapseButton &&
                expandColumnIndex === index &&
                isCollapsed && (
                  // <img
                  //   src={Arrow_expand}
                  //   style={{ position: "absolute" }}
                  //   className={`left-arrow ${classes.arrowLeft}`}
                  //   alt="Expand"
                  //   onClick={onCollapseColumn}
                  // />
                  <></>
                )}
              {col?.filterList?.map((filter: any) => filter.component)}
            </Box>
          )}
      </TableCell>
    );
  });
});

export const TableHeader = React.memo((props: TableHeaderProps) => {
  const {
    classes,
    columns,
    gridTemplate,
    showCheckBox,
    selectAllRows,
    allSelected,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    handleCheckBoxChange,
    handleFilterToggle,
    tableSearchConfig,
    filtersOptions,
    tableFilterConfig,
    filtersLoader,
    overRideStyles,
    handleDateRangeFilter,
    handleSearchChange,
    handleUpdateSelectedCheckboxes,
    handleAutoCompleteSearch,
    tableSortConfig,
    onSort,
    // onCollapseColumn,
    disableSelectAll,
    fewRowsSelected,
    selectAllTootltipText,
    getRowData,
    handleCheckboxOnchange,
    selectedLoanType,
    uncheckAll,
    checkboxRef,
    handleRateAsOfFilter,
    dateSelected,
    setDateSelected,
    filterData
  } = props;

  return (
    <TableRow
      component="div"
      className={`${classes?.tableRowGrid} ${classes?.headerRowBackground}`}
      style={{
        gridTemplateColumns: `${gridTemplate}`,
      }}
    >
      {showCheckBox && (
        <TableHeaderSelect
          classes={classes}
          selectAllRows={selectAllRows}
          allSelected={allSelected}
          disableSelectAll={disableSelectAll}
          fewRowsSelected={fewRowsSelected}
          selectAllTootltipText={selectAllTootltipText}
        />
      )}
      <TableHeaderRow
        classes={classes}
        columns={columns}
        isCollapsed={isCollapsed}
        showCollapseButton={showCollapseButton}
        collapsColumnIndex={collapsColumnIndex}
        expandColumnIndex={expandColumnIndex}
        handleCheckBoxChange={handleCheckBoxChange}
        handleFilterToggle={handleFilterToggle}
        tableSearchConfig={tableSearchConfig}
        filtersOptions={filtersOptions}
        tableFilterConfig={tableFilterConfig}
        filtersLoader={filtersLoader}
        overRideStyles={overRideStyles}
        handleDateRangeFilter={handleDateRangeFilter}
        handleSearchChange={handleSearchChange}
        handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
        handleAutoCompleteSearch={handleAutoCompleteSearch}
        tableSortConfig={tableSortConfig}
        onSort={onSort}
        // onCollapseColumn={onCollapseColumn}
        showCheckBox={showCheckBox}
        getRowData={getRowData}
        handleCheckboxOnchange={handleCheckboxOnchange}
        selectedLoanType={selectedLoanType}
        uncheckAll={uncheckAll}
        checkboxRef={checkboxRef}
        handleRateAsOfFilter={handleRateAsOfFilter}
        dateSelected={dateSelected}
        setDateSelected={setDateSelected}
        filterData={filterData}
      />
    </TableRow>
  );
});
