import axios from "axios";
import { postFetchRefreshToken, postForceLogout } from "../services/network";
import { ObjectType } from "../types/tableTypes";
import { getCookie, removeAllCookie, setCookie } from "../utils/Common";

export const useAxios = (clientConfig: any) => {
  async function forceLogout() {
      // const url = `${process.env.VITE_APP_REALMS}/logout`;
    const refreshToken: any = getCookie("sizer_refresh_token");
    let formBody: any = [];
    const logoutObject: ObjectType = {
        refresh_token: refreshToken,
        client_id: clientConfig?.ViteAppClientId,
    };
    Object.keys(logoutObject).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(logoutObject[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    await postForceLogout(formBody, headers, clientConfig);
    removeAllCookie();
    if (window.location.hostname !== "localhost") {
        window.location.replace(`https://${window.location.hostname}/`);
    } else {
        window.location.replace(
            `http://${window.location.hostname}:${Number(window.location.port)}/`
        );
    }
  }
  const fetchRefreshToken = async () => {
    const refreshToken: any = getCookie("sizer_refresh_token");
    let formBody: any = [];
    const loginObject: ObjectType = {
        client_id: clientConfig?.ViteAppClientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    };
    Object.keys(loginObject).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(loginObject[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    // const url = `${process.env.VITE_APP_REALMS}/token`;

    return postFetchRefreshToken(formBody, headers, clientConfig);
  };

  const axiosClient = axios.create({
    baseURL: clientConfig?.ViteAppApiHostUrl ?? "",
    headers: {
        Accept: "application/json",
    },
  });

  // const exceptionUrls = ["/logout", "smartystreets.com"];
  const exceptionUrls = [
    {
        url: "logout",
    },
    {
        url: "smartystreets.com",
    },
    {
        url: "token"
    }
  ];

  axiosClient.interceptors.request.use((config: any) => {
    let accessToken: string = getCookie("sizer_access_token") ?? "";
    if (!accessToken) {
        accessToken = getCookie("sizer_access_token") ?? "{}";
    }
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    const noCustomHeaders = exceptionUrls.some(({ url }) => config.url.includes(url));
    if (!noCustomHeaders) {
        const partyId = getCookie("partyId") || clientConfig?.partyId;
        const kcRealm = clientConfig?.ViteAppKcRealm;
        config.headers["kc-realm"] = kcRealm;
        config.headers["partyid"] = partyId;
    }
    return config;
  });

  axiosClient.interceptors.response.use(
    (config: any) => {
        return config;
    },
    (err: any) => {
        const originalReq = err?.config;
        // const { errorConfig } = originalReq || {};
        // const { skipErrorHandling, skipForStatus } = errorConfig || {};
        const errorStatus = err?.response?.status;

        if (originalReq?.url && originalReq?.url?.includes("smartystreets.com")) {
            return err;
        }

        if (
            (errorStatus === 401 || errorStatus === 403) &&
            originalReq?.url &&
            originalReq?.url?.includes("smartystreets.com")
        ) {
            Promise.reject(err);
        }
        if (
            (errorStatus === 401 || errorStatus === 403) &&
            originalReq &&
            !originalReq?._retry
        ) {
            originalReq._retry = true;
            fetchRefreshToken()
                .then((res: any) => {
                    if (!res && !res.data) forceLogout();
                    setCookie("sizer_access_token", res?.data?.access_token);
                    setCookie("sizer_access_expiry", res?.data?.expires_in);
                    setCookie("sizer_refresh_token", res?.data?.refresh_token);
                    setCookie("sizer_refresh_expiry", res?.data?.refresh_expires_in);
                    const token: string = res?.access_token ?? "{}";
                    originalReq.headers.Authorization = `Bearer ${JSON.parse(token).access_token
                        }`;
                    return axiosClient(originalReq);
                })
                .catch(() => {
                    const token: string = getCookie("sizer_access_token") ?? "{}";
                    originalReq.headers.Authorization = `Bearer ${token}`;
                    return axiosClient(originalReq);
                });
        } else if (
            (errorStatus === 401 || errorStatus === 403) &&
            err.config?._retry
        ) {
            forceLogout();
        } else {
            Promise.reject(err);
        }
    }
  );
  return axiosClient;
}

export const useTemplateAxios = (clientConfig: any) => {
  async function forceLogout() {
      // const url = `${process.env.VITE_APP_REALMS}/logout`;
    const refreshToken: any = getCookie("sizer_refresh_token");
    let formBody: any = [];
    const logoutObject: ObjectType = {
        refresh_token: refreshToken,
        client_id: clientConfig?.ViteAppClientId,
    };
    Object.keys(logoutObject).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(logoutObject[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    await postForceLogout(formBody, headers, clientConfig);
    removeAllCookie();
    if (window.location.hostname !== "localhost") {
        window.location.replace(`https://${window.location.hostname}/`);
    } else {
        window.location.replace(
            `http://${window.location.hostname}:${Number(window.location.port)}/`
        );
    }
  }

  const fetchRefreshToken = async () => {
    const refreshToken: any = getCookie("sizer_refresh_token");
    let formBody: any = [];
    const loginObject: ObjectType = {
        client_id: clientConfig?.ViteAppClientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    };
    Object.keys(loginObject).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(loginObject[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    // const url = `${process.env.VITE_APP_REALMS}/token`;

    return postFetchRefreshToken(formBody, headers, clientConfig);
  };
    const enteredUrl = window.location.href;
    const parsedUrl = new URL(enteredUrl);
    const envs = ['dev', 'stage', 'qa', 'perf', 'uat'];
    const clientUrlParts: string[] = parsedUrl?.host?.split(".");
    const isLocalHost: boolean = parsedUrl?.host?.includes("localhost");
    const apiBaseUrl = isLocalHost ? ".dev" : (envs.includes(clientUrlParts[0]) ? `.${clientUrlParts[0]}` : ("www" === clientUrlParts[0] ? ".prod" : "" ));
    
    const url = `https://gcp-api${apiBaseUrl}.roemanu.io/api/v1`;
    console.log("useAxios", {clientUrlParts, apiBaseUrl, url})
    const templateAxiosClient = axios.create({
      baseURL: url ?? "",
      headers: {
          Accept: "application/json",
      },
    });

  // const exceptionUrls = ["/logout", "smartystreets.com"];
  const exceptionUrls = [
    {
        url: "logout",
    },
    {
        url: "smartystreets.com",
    },
    {
        url: "token"
    }
  ];

  templateAxiosClient.interceptors.request.use((config: any) => {
    let accessToken: string = getCookie("sizer_access_token") ?? "";
    if (!accessToken) {
        accessToken = getCookie("sizer_access_token") ?? "{}";
    }
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    const noCustomHeaders = exceptionUrls.some(({ url }) => config.url.includes(url));
    if (!noCustomHeaders) {
        const partyId = getCookie("partyId") || clientConfig?.partyId;
        const kcRealm = clientConfig?.ViteAppKcRealm;
        config.headers["kc-realm"] = kcRealm;
        config.headers["partyid"] = partyId;
    }
    return config;
  });

  templateAxiosClient.interceptors.response.use(
    (config: any) => {
        return config;
    },
    (err: any) => {
        const originalReq = err?.config;
        // const { errorConfig } = originalReq || {};
        // const { skipErrorHandling, skipForStatus } = errorConfig || {};
        const errorStatus = err?.response?.status;

        if (originalReq?.url && originalReq?.url?.includes("smartystreets.com")) {
            return err;
        }

        if (
            (errorStatus === 401 || errorStatus === 403) &&
            originalReq?.url &&
            originalReq?.url?.includes("smartystreets.com")
        ) {
            Promise.reject(err);
        }
        if (
            (errorStatus === 401 || errorStatus === 403) &&
            originalReq &&
            !originalReq?._retry
        ) {
            originalReq._retry = true;
            fetchRefreshToken()
                .then((res: any) => {
                    if (!res && !res.data) forceLogout();
                    setCookie("sizer_access_token", res?.data?.access_token);
                    setCookie("sizer_access_expiry", res?.data?.expires_in);
                    setCookie("sizer_refresh_token", res?.data?.refresh_token);
                    setCookie("sizer_refresh_expiry", res?.data?.refresh_expires_in);
                    const token: string = res?.access_token ?? "{}";
                    originalReq.headers.Authorization = `Bearer ${JSON.parse(token).access_token
                        }`;
                    return templateAxiosClient(originalReq);
                })
                .catch(() => {
                    const token: string = getCookie("sizer_access_token") ?? "{}";
                    originalReq.headers.Authorization = `Bearer ${token}`;
                    return templateAxiosClient(originalReq);
                });
        } else if (
            (errorStatus === 401 || errorStatus === 403) &&
            err.config?._retry
        ) {
            forceLogout();
        } else {
            Promise.reject(err);
        }
    }
  );
  return templateAxiosClient;
}