import React, { useMemo, useState } from "react";
import { styled } from "@mui/system";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { tablePaginationClasses as classes } from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { blue, grey } from "@mui/material/colors";

import { TableHeader } from "./TableHeader";
import { TableCustomRow } from "./TableRow";
import { ObjectType } from "../../../types/tableTypes";


const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    padding: 4px;
    border: none;
    background-color: transparent;
  }

  & .${classes.displayedRows} {
    display: none;
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: none;
    margin-left: 0px !important;
    padding: 2px;
    border-radius: 50px;
    text-align: center;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]
    };
    }
  }
  `
);

const CustomPaginationItem = styled(PaginationItem)(
  () => `
  &.MuiPaginationItem-rounded.Mui-selected {
    background-color: #362866 !important;
    color: #ffffff;
  }`
);

const PrevButton = () => {
  return (
    <Button startIcon={<NavigateBeforeIcon />} data-testid="previous-button">
      Previous
    </Button>
  );
};

const NextButton = () => {
  return (
    <Button endIcon={<NavigateNextIcon />} data-testid="next-button">
      Next
    </Button>
  );
};

export default function CustomTable(props: any) {
  const {
    columnsData,
    rowsData,
    classes,
    isCollapsed,
    filtersLoader,
    tableFilterConfig,
    tableSearchConfig,
    tableSortConfig,
    onSort,
    handleFilterToggle,
    handleCheckBoxChange,
    collapsColumnIndex,
    expandColumnIndex,
    // onCollapseColumn,
    showCollapseButton,
    overRideStyles,
    onSelectedColor,
    showCheckBox,
    onRowSelect,
    selectAllRows,
    allSelected,
    selectedLoans,
    fewRowsSelected,
    idKey = "toorakLoanId",
    handleDropdownSelection,
    onLinkClick,
    editClick,
    deleteClick,
    disableRowSelection,
    from,
    setCurrentPage,
    currentPage,
    setRowsPerPage,
    rowsPerPage,
    totalDataCount,
    getRowData,
    handleCheckboxOnchange,
    selectedLoanType,
    uncheckAll,
    checkboxRef,
    handleRateAsOfFilter,
    dateSelected,
    setDateSelected,
    filterData,
    showFooter = true
  } = props;

  const [lastSelectedRowSerialNumber, setlastSelectedRowSerialNumber] =
    useState([-1]);

  const [sequenceRows, setSequenceRows] = useState<number[]>([]);

  const gridTemplate = useMemo(() => {
    const defaultColWidth = showCheckBox ? "50px " : "";
    return columnsData.reduce((prev: string, next: any) => {
      return `${prev}${next?.width ? `${next.width}px ` : "1fr "}`;
    }, defaultColWidth);
  }, [columnsData, showCheckBox]);

  const updatedColumns = useMemo(() => {
    if (isCollapsed) {
      const cols: ObjectType[] = [];
      columnsData.forEach((col: ObjectType) => {
        const temp = { ...col };
        if (col.frozen && !col.alwaysFrozen) {
          temp.frozen = false;
          cols.push(temp);
        } else {
          cols.push(temp);
        }
      });
      return cols;
    }
    return columnsData;
  }, [isCollapsed, columnsData]);

  const updateLastSelectedRowSerialNumber = (val: any, operation: string) => {
    if (operation === "push") {
      setlastSelectedRowSerialNumber(val);
    }
    if (operation === "pop") {
      setlastSelectedRowSerialNumber([]);
    }
  };
  return (
    <TableContainer component={Paper} sx={!showFooter ? {overflowX: "hidden" } : {}}>
      <MuiTable component="div" className={classes?.table ?? ""}>
        <TableHead component="div" className={classes?.stickyTopZero} style={{ display: "table-header-group", color: "#424242", background: "#eef6ff" }}>
          <TableHeader
            classes={classes}
            columns={columnsData}
            gridTemplate={gridTemplate}
            showCheckBox={showCheckBox}
            selectAllRows={selectAllRows}
            allSelected={allSelected}
            fewRowsSelected={fewRowsSelected}
            isCollapsed={isCollapsed}
            showCollapseButton={showCollapseButton}
            collapsColumnIndex={collapsColumnIndex}
            expandColumnIndex={expandColumnIndex}
            handleCheckBoxChange={handleCheckBoxChange}
            handleFilterToggle={handleFilterToggle}
            tableSearchConfig={tableSearchConfig}
            tableFilterConfig={tableFilterConfig}
            filtersLoader={filtersLoader}
            overRideStyles={overRideStyles}
            tableSortConfig={tableSortConfig}
            onSort={onSort}
            // onCollapseColumn={onCollapseColumn}
            getRowData={getRowData}
            handleCheckboxOnchange={handleCheckboxOnchange}
            selectedLoanType={selectedLoanType}
            uncheckAll={uncheckAll}
            checkboxRef={checkboxRef}
            handleRateAsOfFilter={handleRateAsOfFilter}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
            filterData={filterData}
          />
        </TableHead>
        <TableBody component="div" className={classes?.tableBody ?? ""}>
          {rowsData?.map((row: any, k: number) => (
            <TableCustomRow
              key={k}
              gridTemplate={gridTemplate}
              classes={classes}
              row={row}
              columns={updatedColumns}
              isCollapsed={isCollapsed}
              showCollapseButton={showCollapseButton}
              collapsColumnIndex={collapsColumnIndex}
              expandColumnIndex={expandColumnIndex}
              idKey={idKey}
              onSelectedColor={onSelectedColor}
              showCheckBox={showCheckBox}
              onRowSelect={onRowSelect}
              handleDropdownSelection={handleDropdownSelection}
              onLinkClick={onLinkClick}
              editClick={editClick}
              deleteClick={deleteClick}
              selected={selectedLoans && selectedLoans.includes(row[idKey])}
              disableRowSelection={disableRowSelection}
              from={from}
              rowWithSerialNumber={{ serialNumber: k, row }}
              lastSelectedRowSerialNumber={lastSelectedRowSerialNumber}
              updateLastSelectedRowSerialNumber={
                updateLastSelectedRowSerialNumber
              }
              setSequenceRows={setSequenceRows}
            />
          ))}
        </TableBody>
        {showFooter ?
        <tfoot>
          <tr>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} sm={6}>
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalDataCount}
                  rowsPerPage={rowsPerPage}
                  colSpan={3}
                  page={currentPage}
                  onPageChange={(event: unknown, newPage: number) =>
                    setCurrentPage(newPage)
                  }
                  onRowsPerPageChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setCurrentPage(1);
                  }}
                  style={{ borderBottom: "none" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Pagination
                  count={Math.ceil(totalDataCount / rowsPerPage)}
                  page={currentPage}
                  onChange={(event: unknown, newPage: number) =>
                    setCurrentPage(newPage)
                  }
                  renderItem={(item) => (
                    <CustomPaginationItem
                      {...item}
                      shape="rounded"
                      slots={{ previous: PrevButton, next: NextButton }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </tr>
        </tfoot>
        : null}
      </MuiTable>
    </TableContainer>
  );
}
