import React from "react";

import AccordionUI from "../../components/common/AccordionUI";
import { ObjectType } from "../../types/tableTypes";

export default function PropertyDetails({
  isApplicationSubmitted,
  idx,
  loanType,
  theme,
  translation,
  loanId,
  propType,
  propConfig,
  label,
  loanContextData
}: {
  isApplicationSubmitted: boolean,
  idx: number,
  loanType: string | undefined,
  theme: any,
  translation: any,
  loanId: string | undefined,
  propType: string | undefined,
  propConfig: ObjectType,
  label: string,
  loanContextData: ObjectType,
}) {
  const { propertyDetails, expandLeverageAndTests } = loanContextData;
  return (
    <AccordionUI
      component={propConfig}
      idx={idx}
      label={label ?? "loanDetails"}
      data={propertyDetails}
      expandLeverageAndTests={expandLeverageAndTests}
      isApplicationSubmitted={isApplicationSubmitted}
      loanType={loanType}
      theme={theme}
      translation={translation}
      loanId={loanId}
      propType={propType}
      loanContextData={loanContextData}
    />
  );
}