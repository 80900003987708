/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import moment from "moment";
import { cleanedAdressString, currencyToNumber, formatValueByType, getLoanType, isEmptyValue } from "../../utils/Common";

export enum ToorakProductEnum {
    Rehab = "Bridge",
    Rental = "2+1",
    GroundUp = "Ground-Up"
  }

export interface KeyMetricFieldType {
  fieldId: string;
  fieldLabel: string;
  fieldType: string;
  getValue: any;
  popOverData?: any;
  redirectionType?: string;
}
export interface KeyMetricSectionType {
  sectionId: string;
  sectionLabel: string;
  fields: any;
}

const getAccumlatedPropertyValue = (data: any, key: string) => {
  const { propertyDetails } = data;
  let valueToReturn: any = null;
  if (propertyDetails) {
    propertyDetails?.forEach((element: any) => {
      const { propertyEconomics } = element;
      if (!isEmptyValue(propertyEconomics?.[key])) {
        const cleanedValue = currencyToNumber(propertyEconomics?.[key]);
        if (valueToReturn) {
          valueToReturn += Number(cleanedValue);
        } else {
          valueToReturn = Number(cleanedValue);
        }
      }
    });
  }
  return `${valueToReturn || "--"}`;
};

export const getKeyDealMetricsFieldMap = (
  loanType: string
): KeyMetricSectionType[] => {
  switch (loanType) {
    case getLoanType[0].displayValue:
      return bridgeLoanKeyMetricSection;
    case getLoanType[1].displayValue:
      return investorDSCRLoanKeyMetricSection;
    default:
      return [];
  }
};
/** --------------------------- BridgeLoan section start ---------------------------*/
export const bridgeLoanGeneralFields: KeyMetricFieldType[] = [
  {
    fieldId: "evaluationId",
    fieldLabel: "Evaluation ID",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.evaluationID || "--";
    }
  },
  {
    fieldId: "loanId",
    fieldLabel: "Loan ID",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.tcLoanId || "--";
    }
  },
  {
    fieldId: "originator",
    fieldLabel: "Originator",
    fieldType: "string",
    getValue: (data: any) => {
      const { originatorInfo } = data;
      return originatorInfo?.accountName || "--";
    }
  },
  {
    fieldId: "toorakProduct",
    fieldLabel: "Toorak Product",
    fieldType: "string",
    getValue: (data: any) => {
      return (
        data?.loanDetails?.loanInfo?.toorakProduct ?? ToorakProductEnum.Rehab
      );
    }
  },
  {
    fieldId: "address",
    fieldLabel: "Full Address",
    fieldType: "string",
    redirectionType: "googleMap",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyLocation } = propertyDetails[0] || {};
        const address = cleanedAdressString(propertyLocation)
        return address;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyAddressArray = propertyDetails.map((ele: any) => {
          const { propertyLocation } = ele;
          return cleanedAdressString(propertyLocation);
        });
        return propertyAddressArray;
      }
      return [];
    }
  },
  {
    fieldId: "toorakCharacterization",
    fieldLabel: "Toorak Characterization",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails, ruleResult } = data;
      return (loanDetails?.loanInformation?.loanPurpose || ruleResult?.loanCharacterisation?.loanPurpose) || "--";
    }
  }
];
export const bridgeLoanBorrowerInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "experience",
    fieldLabel: "Experience",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.borrowerInfo?.experience || "--";
    }
  },
  {
    fieldId: "creditScore",
    fieldLabel: "Credit Score",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
        return ruleResult?.borrowerInfo?.originalCreditScoreMedian || "--";
    }
  },
  {
    fieldId: "isForeignNational",
    fieldLabel: "Foreign National",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const fn = ruleResult?.borrowerInfo?.isForeignNational;
      if (isEmptyValue(fn)) return "--";
      const value = fn === "N" ? "No" : "Yes";
      return value;
    }
  }
];

export const bridgeLoanLoanInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "loanStructure",
    fieldLabel: "Loan Structure",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInformation?.loanStructure || "--";
    }
  },
  {
    fieldId: "loanPurpose",
    fieldLabel: "Loan Purpose",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails, ruleResult } = data;
      return (loanDetails?.loanInformation?.loanPurpose || ruleResult?.loanCharacterisation?.loanPurpose) || "--";
    }
  },
  {
    fieldId: "interestReserve",
    fieldLabel: "Interest Reserve",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return (loanDetails?.loanEconomics?.financedInterestReserve || loanDetails?.loanEconomics?.interestReserve) || "--";
    }
  },
  {
    fieldId: "interestRate",
    fieldLabel: "Interest Rate",
    fieldType: "percentageWithout100x",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return (loanDetails?.loanEconomics?.originalInterestRate || loanDetails?.loanEconomics?.interestRate) || "--";
    }
  },
  {
    fieldId: "cashOut",
    fieldLabel: "Cash Out",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInformation?.cashOutFlag || "--";
    }
  },
  {
    fieldId: "DSCRCalculation",
    fieldLabel: "DSCR Calculation",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.loanEconomics?.dscrCalculation || "--";
    }
  },
  {
    fieldId: "DSCR",
    fieldLabel: "DSCR",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.loanEconomics?.dscrRequirementTest || "--";
    }
  },
  {
    fieldId: "debtYield",
    fieldLabel: "Debt Yield",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data || {};
      return ruleResult?.multiFamilyMixedUse?.actualDebtYield || "--";
    }
  },
  {
    fieldId: "term",
    fieldLabel: "Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanFeatures?.originalTermToMaturity || "--";
    }
  },
  {
    fieldId: "profitability",
    fieldLabel: "Profitability",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.guidelineOutput?.profitabilityCalculation || "--";
    }
  },
  {
    fieldId: "totalRehabBudget",
    fieldLabel: "Total Rehab Budget",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.totalRehabBudget || "--";
    }
  }
];
export const bridgeLoanPropertyInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "propertyType",
    fieldLabel: "Property Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyInformation } = propertyDetails[0] || {};
        const propertyType =
        propertyInformation?.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyInformation?.propertyType;
        return propertyType || "--";
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyTypeArray = propertyDetails.map((ele: any) => {
          const { propertyinfo, propertyInformation } = ele;
          const data= propertyInformation || propertyinfo
          const propertyType =
          data?.propertyType === "SFR"
              ? "Single Family Detached"
              : data?.propertyType;
          return propertyType;
        });

        return propertyTypeArray;
      }
      return [];
    }
  },
  {
    fieldId: "units",
    fieldLabel: "Units",
    fieldType: "number",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      let numberOfUnits: any = null;
      if (propertyDetails) {
        propertyDetails?.forEach((element: any) => {
          const { propertyInformation } = element;
          if (!isEmptyValue(propertyInformation?.propertyUnits)) {
            if (numberOfUnits) {
              numberOfUnits += Number(propertyInformation?.propertyUnits);
            } else {
              numberOfUnits = Number(propertyInformation?.propertyUnits);
            }
          }
        });
      }
      return numberOfUnits || "--";
    }
  },
  {
    fieldId: "asIs",
    fieldLabel: "As-Is",
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "orgAppraisalValue")
  },
  {
    fieldId: "arv",
    fieldLabel: "ARV",
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "asRepairedValueARV")
  },
  {
    fieldId: "ARV/As-Isperunit",
    fieldLabel: "ARV/As-Is per unit",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult, loanStage, propertiesResults, propertyDetails } = data;
      return "--"
    },
    popOverData: (data: any) => {
      const { ruleResult } = data;
      const { propertiesResults } = ruleResult || {};
      if (!propertiesResults) return "--";
      const propertyIds = Object.keys(propertiesResults);
      if (propertyIds.length <= 1) {
        return [];
      }
      let valueToReturn = [];
      valueToReturn = propertyIds.map((propertyId: any) => {
          const { minimumArvPerUnit, minimumAsIsPerUnit } =
            propertiesResults[propertyId].guidelineOutput;
          let returnValue = "";
          if (!isEmptyValue(minimumArvPerUnit)) {
            returnValue = `ARV: ${formatValueByType(
              minimumArvPerUnit,
              "currency"
            )}`;
          } else {
            returnValue = "ARV: N/A";
          }
          if (!isEmptyValue(minimumAsIsPerUnit)) {
            returnValue += `; As-Is: ${formatValueByType(
              minimumAsIsPerUnit,
              "currency"
            )}`;
          } else {
            returnValue += "; As-Is: N/A";
          }
          return returnValue;
      });
      return valueToReturn;
    }
  },
  {
    fieldId: "percentOccupied",
    fieldLabel: "Occupancy %",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, propertiesResults } = data;
      if (!propertiesResults) return "--";
      let valueToReturn: any;
      const propertyKeys = Object.keys(propertiesResults);
      propertyKeys.forEach((key) => {
        const percentOccupied = Number(
          propertiesResults?.[key]?.loanEconomics?.percentOccupied
        );
        if (!isNaN(percentOccupied)) {
          if (!isEmptyValue(valueToReturn)) {
            valueToReturn += percentOccupied;
          } else {
            valueToReturn = percentOccupied;
          }
        }
      });
      return (valueToReturn
        ? valueToReturn / propertyKeys.length
        : valueToReturn) || "--";
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "purchasePrice")
  },
  {
    fieldId: "costBasis", 
    fieldLabel: "Cost Basis",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "costBasis")
  },
  {
    fieldId: "financedBudget",
    fieldLabel: "Financed Budget Amount",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanStage, loanDetails } = data;
        return loanDetails?.loanEconomics?.financedBudget || "--";
    }
  },
];
export const bridgeLoanZillowDataFields: KeyMetricFieldType[] = [
  {
    fieldId: "medianValue",
    fieldLabel: "Median Value",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult, propertiesResults } = data;
      if (!propertiesResults) return "--";
      const firstProperty = Object.keys(propertiesResults)[0];
      return (
        propertiesResults?.loanFeatures?.medianHomePriceValue ||
        propertiesResults?.loanFeatures?.medianValue
      ) || "--";
    }
  },
  {
    fieldId: "HPA",
    fieldLabel: "HPA",
    fieldType: "percentage",
    getValue: (data: any) => {
        const { ruleResult, propertiesResults } = data;
      if (!propertiesResults) return "--";
      const firstProperty = Object.keys(propertiesResults)[0];
      return (
        propertiesResults?.loanFeatures?.medianHomeHpa ||
        propertiesResults?.loanFeatures?.ltmHpa
      ) || "--";
    }
  },
  {
    fieldId: "DOM",
    fieldLabel: "DOM",
    fieldType: "number",
    getValue: (data: any) => {
        const { ruleResult, propertiesResults } = data;
      if (!propertiesResults) return "";
      const firstProperty = Object.keys(propertiesResults)[0];
      return propertiesResults?.loanFeatures?.daysOnMarket;
    }
  }
];
export const bridgeLoanRequestedRatiosFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanFeatures.originalAsIsLtv || "--";
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return (
        ruleResult?.loanFeatures?.originalAsIsRepairedLtv ||
        ruleResult?.loanFeatures?.originalAsRepairedLtv
      ) || "--"
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanFeatures.originalLtc || "--";
    }
  }
];
export const bridgeLoanMaxGuidelineLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return (
        ruleResult?.maxLeverage?.maxLTVAsIs ||
        ruleResult?.maxLeverageTest?.maxLTVAsIs
      ) || "--";
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return (
        ruleResult?.maxLeverage?.maxLTVARV ||
        ruleResult?.maxLeverageTest?.maxLTVARV
      ) || "--";
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return (
        ruleResult?.maxLeverage?.maxLTCOne ||
        ruleResult?.maxLeverageTest?.maxLTCOne
      ) || "--";
    }
  }
];
export const bridgeLoanAdjustedRatioFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.finalLeverage?.afterAdjustmentMaxLTVAsIs || "--";
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.finalLeverage?.afterAdjustmentMaxARV || "--";
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.finalLeverage?.afterAdjustmentMaxLTC || "--";
    }
  }
];
export const bridgeLoanKeyMetricSection: KeyMetricSectionType[] = [
  {
    sectionId: "general",
    sectionLabel: "General",
    fields: bridgeLoanGeneralFields
  },
  {
    sectionId: "borrowerInfo",
    sectionLabel: "Borrower Information",
    fields: bridgeLoanBorrowerInfoFields
  },
  {
    sectionId: "loanInfo",
    sectionLabel: "Loan Information",
    fields: bridgeLoanLoanInfoFields
  },
  {
    sectionId: "propertyInfo",
    sectionLabel: "Property Information",
    fields: bridgeLoanPropertyInfoFields
  },
  {
    sectionId: "zillowData",
    sectionLabel: "Zillow Data",
    fields: bridgeLoanZillowDataFields
  },
  {
    sectionId: "requestedRatios",
    sectionLabel: "Requested Ratios",
    fields: bridgeLoanRequestedRatiosFields
  },
  {
    sectionId: "maxGuidelineLeverage",
    sectionLabel: "Maximum Guideline Leverage",
    fields: bridgeLoanMaxGuidelineLeverageFields
  },
  {
    sectionId: "adjustedRatios",
    sectionLabel: "Adjusted Ratios",
    fields: bridgeLoanAdjustedRatioFields
  }
];
/** --------------------------- BridgeLoan section end --------------------------- */

/** --------------------------- Investor DSCR section start ---------------------------*/
export const investorDscrLoanInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "evaluationId",
    fieldLabel: "Evaluation ID",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.evaluationID || "--";
    }
  },
  {
    fieldId: "loanId",
    fieldLabel: "Loan ID",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.tcLoanId || "--";
    }
  },
  {
    fieldId: "originator",
    fieldLabel: "Originator",
    fieldType: "string",
    getValue: (data: any) => {
      const { originatorInfo } = data;
      return originatorInfo?.accountName || "--";
    }
  },
  {
    fieldId: "toorakProduct",
    fieldLabel: "Toorak Product",
    fieldType: "string",
    getValue: (data: any) => {
      return "30 Yr Rental";
    }
  },
  {
    fieldId: "toorakCharacterization",
    fieldLabel: "Toorak Characterization",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInformation?.loanPurpose || "--";
    }
  }
];
export const investorDscrBorrowerInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "creditScore",
    fieldLabel: "Credit Score",
    fieldType: "number",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.borrowerInformation?.creditScoreActual
    }
  }
];
export const investorDscrLoanEconomicsField: KeyMetricFieldType[] = [
  {
    fieldId: "originalLoanAmount",
    fieldLabel: "Original Loan Amount",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.borrowerRequested || "--";
    }
  },
  {
    fieldId: "interestRate",
    fieldLabel: "Interest Rate",
    fieldType: "percentageWithout100x",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.originalInterestRate || "--";
    }
  },
  {
    fieldId: "originalTermToMaturity",
    fieldLabel: "Original Term to Maturity(Months)",
    fieldType: "number",
    getValue: (data: any) => {
        const { loanDetails } = data;
        return loanDetails?.loanInformation?.loanTerm || "--";
    }
  },
  {
    fieldId: "ioPeriods",
    fieldLabel: "IO Period(Months)",
    fieldType: "number",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.ioPeriod || "0";
    }
  },
  {
    fieldId: "prepaymentPenaltyMonths",
    fieldLabel: "Prepayment Penalty(Months)",
    fieldType: "number",
    getValue: (data: any) => {
      const { loanDetails } = data;
        return loanDetails?.loanEconomics?.prepaymentPenaltyTypeMonths || "--";
    }
  },
  {
    fieldId: "cashOutAmount",
    fieldLabel: "Cash Out Amount",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.cashOutAmount || "--";
    }
  },
  {
    fieldId: "DSCR",
    fieldLabel: "DSCR",
    fieldType: "number",
    getValue: (data: any) => {
    const { ruleResult } = data;
      return ruleResult?.loanEconomics?.dscr || "--";
    }
  },
  {
    fieldId: "grossPotentialRent",
    fieldLabel: "Monthly Property Gross Rent",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.aggregateMonthlyPropertyGrossRent || "--";
    }
  },
  {
    fieldId: "monthlyInterest",
    fieldLabel: "Monthly Interest",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.monthlyInterest || "--";
    }
  },
  {
    fieldId: "monthlyPrincipalDuringAmortization",
    fieldLabel: "Monthly Principal During Amortization",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.monthlyPrincipalDuringAmortization || "--";
    }
  },
  {
    fieldId: "aggregateAnnualPropertyTaxes",
    fieldLabel: "Monthly Taxes",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.aggregateAnnualPropertyTaxes || "--";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualHazardInsurance",
    fieldLabel: "Monthly Insurance",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.aggregateMonthlyAnnualHazardInsurance || "--";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualFloodInsurance",
    fieldLabel: "Monthly Flood Insurance",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.aggregateMonthlyAnnualFloodInsurance || "--";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualHOA",
    fieldLabel: "Monthly HOA",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanEconomics?.aggregateMonthlyAnnualHOA || "--";
    }
  },
  {
    fieldId: "rateType",
    fieldLabel: "Rate Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.rateType || "--";
    }
  },
];

export const investorDscrPropertyInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "address",
    fieldLabel: "Address",
    fieldType: "string",
    redirectionType: "googleMap",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyLocation } = propertyDetails[0] || {};
        const address = cleanedAdressString(propertyLocation)
        return address;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyAddressArray = propertyDetails.map((ele: any) => {
          const { propertyLocation } = ele;
          const address = cleanedAdressString(propertyLocation)
        return address;
        });
        return propertyAddressArray;
      }
      return [];
    }
  },
  {
    fieldId: "propertyType",
    fieldLabel: "Property Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyInformation } = propertyDetails[0] || {};
        const propertyType =
        propertyInformation?.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyInformation?.propertyType;
        return propertyType;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        return propertyDetails.map((ele: any) => {
          const { propertyInformation } = ele;
          return propertyInformation?.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyInformation?.propertyType;
        });
      }
      return [];
    }
  },
  {
    fieldId: "units",
    fieldLabel: "Units",
    fieldType: "number",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      let numberOfUnits: any = null;
      if (propertyDetails) {
        propertyDetails.forEach((element: any) => {
          const { propertyInformation } = element;
          if (!isEmptyValue(propertyInformation?.propertyUnits)) {
            if (numberOfUnits) {
              numberOfUnits += Number(propertyInformation?.propertyUnits);
            } else {
              numberOfUnits = Number(propertyInformation?.propertyUnits);
            }
          }
        });
      }
      return numberOfUnits || "--";
    }
  },
  {
    fieldId: "asIs-original",
    fieldLabel: 'Original "As Is" Appraisal Value',
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "originalAsIsAppraisalValue")
  },
  {
    fieldId: "percentOccupied",
    fieldLabel: "Occupancy %",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { propertiesResults } = data;
      if (!propertiesResults) return "";
      return propertiesResults?.loanEconomics?.percentOccupied ? +propertiesResults?.loanEconomics?.percentOccupied : "--"
    }
  }
];
export const investorDscrPropertyEconomicsFields: KeyMetricFieldType[] = [
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "propertyAcquisitionPrice")
  },
  {
    fieldId: "costBasis",
    fieldLabel: "Cost Basis",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "costBasis")
  }
];
export const investorDSCRActualRatiosFields: KeyMetricFieldType[] = [
  {
    fieldId: "Original-As Is-LTV",
    fieldLabel: 'Original "As Is" LTV',
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanFeatures?.originalAsIsLtv;
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "Max LTC",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.loanFeatures?.originalLtc;
    }
  }
];

export const investorDscrMaxGuidelineLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "MaxAs-IsLTV",
    fieldLabel: "Max As-Is LTV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.maxLeverage?.maxAsIsLTV || "--";
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "Max LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      return ruleResult?.maxLeverage?.maxLTC || "--";
    }
  }
];
export const investorDscrNetLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "afterAdjustment-LTVAs-Is",
    fieldLabel: "Afer Adjustment: Max As-Is LTV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.toorakProceeds?.toorakAdjustedAsIsLtv || "--";
    }
  },
  {
    fieldId: "afterAdjustment-LTC",
    fieldLabel: "Max LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      return ruleResult?.toorakProceeds?.toorakAdjustedAsIsLtc || "--";
    }
  }
];

const getRateLockExpiryDate = (
  initialDate: string,
  finalExtensionDays: number
) => {
  if (isEmptyValue(initialDate)) return "NA";
  const expiry = moment
    .utc(initialDate)
    .subtract(parseInt(`${finalExtensionDays}`, 10), "days");
  const expiryDate = `${expiry.format("MMM DD, YYYY")}`;
  return `${expiryDate}`;
};

export const investorDSCRRateLockFields: KeyMetricFieldType[] = [
  {
    fieldId: "rate-sheet-date",
    fieldLabel: "Rate Sheet Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      const rateSheetDate =
        loanDetails?.loanDetailId?.loanConfigId?.rateLockedRateSheet
          ?.replace(/\D/g, "")
          .trim();
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockedRateSheet
        ? moment(rateSheetDate, "DD-MM-YY").format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "initial-lock-date",
    fieldLabel: "Initial Lock Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockedDate
        ? moment(
            loanDetails?.loanDetailId?.loanConfigId?.rateLockedDate
          ).format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "initial-lock-term",
    fieldLabel: "Initial Lock Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod
        ? `${loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod} Days`
        : "-";
    }
  },
  {
    fieldId: "initial-lock-term-cost",
    fieldLabel: "Initial Lock Term Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.rateLockAdjustment ?? "-";
    }
  },
  {
    fieldId: "initial-rate-lock-expiry-date",
    fieldLabel: "Initial Rate Lock Expiry Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount = 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
        return loanDetails?.rateLockExpiryDate
          ? getRateLockExpiryDate(loanDetails?.rateLockExpiryDate, finalCount)
          : "-";
      }
    }
  },
  {
    fieldId: "is-extension",
    fieldLabel: "Is Extension",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return Array.isArray(loanDetails.loanDetailId?.rateLockExtensions) &&
        loanDetails.loanDetailId?.rateLockExtensions?.length
        ? "Yes"
        : "No";
    }
  },
  {
    fieldId: "extension-days",
    fieldLabel: "Extension Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount = 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
      }
      return finalCount ? `${finalCount} Days` : "-";
    }
  },
  {
    fieldId: "extension-cost",
    fieldLabel: "Extension Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.rateLockExtensionAdjustment ?? "-";
    }
  },
  {
    fieldId: "final-lock-term",
    fieldLabel: "Final Lock Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount =
        loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod ?? 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
      }
      return finalCount ? `${finalCount} Days` : "-";
    }
  },
  {
    fieldId: "final-cost",
    fieldLabel: "Final Lock Term Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      const result =
        parseFloat(
          loanResult[loanId]?.loanPricing?.rateLockExtensionAdjustment ?? "0"
        ) +
        parseFloat(loanResult[loanId]?.loanPricing?.rateLockAdjustment ?? "0");
      return result ?? "-";
    }
  },
  {
    fieldId: "current-lock-expiry-date",
    fieldLabel: "Current Lock Expiry Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.rateLockExpiryDate
        ? moment(loanDetails?.rateLockExpiryDate).format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "final-price",
    fieldLabel: "Final Price",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.finalToorakPrice ?? "NA";
    }
  }
];

export const investorDSCRLoanKeyMetricSection: KeyMetricSectionType[] = [
  {
    sectionId: "loanInformation",
    sectionLabel: "Loan Information",
    fields: investorDscrLoanInfoFields
  },
  {
    sectionId: "borrowerInfo",
    sectionLabel: "Borrower Information",
    fields: investorDscrBorrowerInfoFields
  },
  {
    sectionId: "loanEconomics",
    sectionLabel: "Loan Economics",
    fields: investorDscrLoanEconomicsField
  },
  {
    sectionId: "propertyInfo",
    sectionLabel: "Property Information",
    fields: investorDscrPropertyInfoFields
  },
  {
    sectionId: "propertyEconomics",
    sectionLabel: "Property Economics",
    fields: investorDscrPropertyEconomicsFields
  },
  {
    sectionId: "actualRatios",
    sectionLabel: "Actual Ratios",
    fields: investorDSCRActualRatiosFields
  },
  {
    sectionId: "maxGuidelineLeverage",
    sectionLabel: "Max Leverages",
    fields: investorDscrMaxGuidelineLeverageFields
  },
  {
    sectionId: "netLeverage",
    sectionLabel: "Net Leverages",
    fields: investorDscrNetLeverageFields
  },
];
/** --------------------------- Investor DSCR section end ---------------------------*/
