import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";

import { ObjectType } from "../../types/tableTypes";
import {
  getCookie,
  getEligiblePartnersCols,
  getfinalPricingCols,
  getLoanPricingFields,
  getOriginatorLabel,
  getPricingAdjustmentsCols,
  getRowsData,
  labelFieldName30year,
} from "../../utils/Common";

import InfoIcon from "../../assets/InfoIcon.svg";
import CheckCirclePass from "../../assets/CheckCirclePass.svg";
import DownArrow from "../../assets/DownArrow.svg";
import CriticalTestIcon from "../../assets/CriticalFailed.svg";
import NonCriticalTestIcon from "../../assets/NonCriticalFailed.svg";
import CriticalTestIconWithBG from "../../assets/CriticalFailedWithBG.svg";
import NonCriticalTestIconWithBG from "../../assets/FailedTestIconBG.svg";
import LoaderSvg from "../../assets/Loader.svg";

import { getCriticalTests, loanDataBasedOnLoanId, patchSaveApplication } from "../../services/network";

import { RootContext } from "../../context/RootContext";
import { LoansContext } from "../../context/LoansContext";

import LoanResultTab from "../../components/common/Loans/LoanResultTab";
import CustomTable from "../../components/common/Table/CustomTable";
import CustomChip from "../../components/common/CustomChip";
import CustomCard from "../../components/common/BasicCustomCard";

import PartnerAndProgrammeSelection from "./PartnerAndProgrammeSelection";
import { getTestTabResultData } from "./Common";

type ParamType = {
  loanId: any;
  loanType: any;
  propType: any;
};

const EligiblePartnersTableRow = (props: any) => {
  const {
    gridTemplate,
    loanDetails,
    eligiblePartnersColumns,
    eligiblePartnerResults,
    isApplicationSubmitted,
    takeOutPartnerLists,
    changePartner,
    isLATUser,
    showPricingOnlyForEligible,
  } = props;

  let cumulativeWidth = 0;
  return (
    <TableRow
      component="div"
      style={{
        gridTemplateColumns: `${gridTemplate}`,
        display: "flex"
      }}
    >
      {eligiblePartnersColumns?.map((col: any, index: number) => {
        const isChecked = [
          loanDetails?.takeOutPartner,
        ].includes(col?.id) &&
          eligiblePartnerResults?.dailyCreditLimit[col?.id]?.isWithinLimit;
        if (col?.frozen) {
          if (index !== 0) {
            cumulativeWidth += eligiblePartnersColumns[index - 1].width;
          }
        }

        return (
          <TableCell
            component="div"
            key={col.id}
            className={`${col?.headClass ?? ""}`}
            style={{
              ...col?.headStyle,
              ...(col?.frozen && {
                left: cumulativeWidth,
                zIndex: 1,
              }),
              width: col.width || "auto",
              padding: col?.padding || "auto",
              backgroundColor: isChecked && col.criticalFailed < 0 ? "#eef6ff" : "#E4EAFF",
              border: isChecked && col.criticalFailed < 0 ? "1px solid #00B768" : "1px solid #dedede",
              borderBottom: "1px solid #dedede"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1rem", flexWrap: "wrap" }}>
              {col.title ? (
                <>
                  {(!showPricingOnlyForEligible || (showPricingOnlyForEligible && col.criticalFailed === 0)) &&
                    <Radio
                      checked={
                        [loanDetails?.takeOutPartner].includes(col?.id) &&
                        eligiblePartnerResults?.dailyCreditLimit[col?.id]?.isWithinLimit
                      }
                      disabled={!eligiblePartnerResults?.dailyCreditLimit[col?.id]?.isWithinLimit || isApplicationSubmitted}
                      onChange={(e) => {
                        changePartner(col)
                      }}
                    />
                  }
                  {isLATUser ? col.title : getOriginatorLabel(col?.title, takeOutPartnerLists)}
                  <div>
                    {col.criticalFailed ? <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={NonCriticalTestIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          <span style={{ fontSize: "12px" }}>{`Critical Fails - ${col.criticalFailed}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
                        </div>
                      }
                      variant="outlined"
                      className="critical-failed-chip"
                    /> : null}
                    {col.failedCount ? <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={CriticalTestIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          <span style={{ fontSize: "12px" }}>{`Non-critical Fails - ${col.failedCount}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
                        </div>
                      }
                      variant="outlined"
                      className="non-critical-chip-data"
                    /> : null}
                  </div>
                  {!col.failedCount && !col.criticalFailed ?
                    <img
                      src={CheckCirclePass}
                      alt="check-pass-circle"
                      className="mr-8"
                    /> : null}
                  {!eligiblePartnerResults?.dailyCreditLimit[col?.id]?.isWithinLimit && (
                    <Grid style={{ color: "red" }}>
                      {"  "}
                      *Daily credit limit reached
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <span>{col?.title}</span>
                  <span>{col?.subTitle}</span>
                </>
              )}
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function PartnerAndProgrammeSelectionWrapper(props: any) {
  const { setSnackBarMsg, setShowSnackBar, isExpanded, setIsExpanded } = props;

  const [tablesContents, setTableContents] = useState<any>([]);
  const [eligiblePartnersColumns, setEligiblePartnersColumns] = useState<any>([]);
  const [finalPricingCols, setFinalPricingCols] = useState<any>([]);
  const [pricingAdjustmentsCols, setPricingAdjustmentsCols] = useState<any>([]);
  const [finalPricingColsData, setFinalPricingColsData] = useState<any>([]);
  const [pricingAdjustmentsColsData, setPricingAdjustmentsColsData] = useState<any>([]);

  const [takeOutPartnerLists, setTakeOutPartnerLists] = useState<any>([]);
  const showPricingOnlyForEligible = true;
  const showRadioSelection = false;

  // const [loanDetails, setLoanDetails] = useState<any>({});
  // const [testTabData, setTestTabData] = useState<any>([]);
  // const [eligiblePartnerResults, setEligiblePartnerResults] = useState<any>({});
  // const [partnersResults, setPartnersResults] = useState<any>({});
  // const [assignedPartner, setAssignedPartner] = useState<any>({});
  // const [view, setView] = useState<string>("");
  // const [rulesResponse, setRulesResponse] = useState<any>({});
  // const [templateResponseData, setTemplateResponseData] = useState<any>({});
  // const [fetchData, setFetchData] = useState<any>({});
  // const [ratesAsOfDate, setRatesAsOfDate] = useState<any>({});
  // const [isApplicationSubmitted, setIsApplicationSubmitted] = useState<boolean>(false);
  // const [criticalTests, setCriticalTests] = useState<any>({});


  const loanContextData = useContext(LoansContext);
  const RootContextData = useContext(RootContext);

  const {
    eligiblePartnerResults,
    partnersResults,
    testTabData,
    loanDetails,
    autoCalculatedPropResults,
    fetchData,
    expandLeverageAndTests,
    ratesAsOfDate,
    setLoanDetails,
    setAssignedPartner,
    isApplicationSubmitted,
    rulesResponse,
    setTemplateResponseData,
    view,
    setView
  } = useContext(LoansContext);
  const {
    dynamicConfig,
    setDynamicLoader,
    dscrPartner,
    templateLabels,
    DSCROrgPartnerMapping
  } = useContext(RootContext);

  const { t } = useTranslation();
  const { loanId, loanType, propType } = useParams<ParamType>();

  const isLATUser = !getCookie("orgId");

  // const getDataForPartnerAndProgrammeSelection = async () => {
  //   try {
  //     const loanData: any = await loanDataBasedOnLoanId([loanId]);
  //     // const evaluationResults: any = await fetchGuidelineEvalResult(
  //     //   loanId,
  //     //   loanType,
  //     //   propType,
  //     //   // loanContextData,
  //     //   // RootContextData,
  //     //   templateResponseData,
  //     //   setTemplateResponseData,
  //     //   dynamicConfig,
  //     //   templateLabels
  //     // );
  //     // const { rulesData, asOfDate, tempCriticalTests } = evaluationResults;

  //     setLoanDetails(loanData?.data?.data?.["0"]?.data);
  //     setTestTabData(loanData?.data?.data["0"]?.data?.testTabData ?? []);
  //     setEligiblePartnerResults(loanData?.data?.data["0"]?.eligiblePartnerResults ?? []);
  //     setIsApplicationSubmitted(Boolean(loanData?.data?.data["0"]?.data?.isApplicationSubmitted));
  //     setFetchData(loanData?.data?.data["0"]?.data || {});
  //     // setRulesResponse(rulesData);
  //     // setRatesAsOfDate(asOfDate);
  //     // setPartnersResults(rulesData);
  //     // setCriticalTests(tempCriticalTests);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const changePartner = async (col: any) => {
    try {
      setDynamicLoader(true);
      const tempCurrentPartner = {
        partnerId: col?.id,
        assignedPartner: isLATUser
          ? col.title
          : getOriginatorLabel(col?.title, takeOutPartnerLists)
      }
      const loanData = {
        ...loanDetails,
        eligiblePartnerResults,
        partnersResults
      }
      loanData.assignedPartner = tempCurrentPartner?.assignedPartner;
      loanData.takeOutPartner = tempCurrentPartner?.partnerId;
      loanData.takeoutPartnerStatus = "OVERRIDDEN";
      await patchSaveApplication(loanId, loanData);
      setAssignedPartner(tempCurrentPartner?.partnerId);
      setLoanDetails(loanData);
      setShowSnackBar(true);
      setSnackBarMsg("programSwitchedSuccessfully");
      updateResult(tempCurrentPartner?.partnerId);
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  }

  const updateResult = async (tpId: string) => {
    let tempResultKeys: ObjectType = {};
    let tempLoanResult: ObjectType = {};
    let loanTypeParam: string = "";
    let tempPropertyResult: ObjectType = { ...autoCalculatedPropResults };

    const tempCriticalTests: ObjectType = {};
    const currentResponseData = rulesResponse[tpId];
    const tempLoanDetails = { ...loanDetails };
    const ruleVersion: string =
      currentResponseData?.loanResult?.[loanId]
        ?.ruleVersion?.RULE_VERSION || "";
    const publicAPI2Headers = {
      accept: "application/json",
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };

    if (loanType === "InvestorDSCR") {
      loanTypeParam = "30year";
    } else if (loanType === "BridgeLoan") {
      loanTypeParam = "bridge";
    }

    const loanTypeStr = loanTypeParam === "30year" ? "30Year" : "BRIDGE";
    for (const tpId of Object.keys(rulesResponse)) {
      const currentPartner: any = rulesResponse?.[tpId];
      const ruleVersion: string =
        currentPartner?.loanResult?.[loanId]
          ?.ruleVersion?.RULE_VERSION || "";

      const criticalResponse: any = await getCriticalTests(ruleVersion);
      tempCriticalTests[tpId] = [
        ...(criticalResponse?.data?.criticalTestLoanEligibility ?? [])
      ];
    }

    tempResultKeys = Object.keys(currentResponseData?.loanResult);
    tempLoanResult = currentResponseData?.loanResult[tempResultKeys[0]];
    tempPropertyResult = currentResponseData?.propertiesResults;
    getTestTabResultData(
      rulesResponse,
      tempLoanDetails,
      publicAPI2Headers,
      true,
      tempLoanResult,
      loanTypeStr,
      fetchData,
      loanContextData,
      RootContextData,
      {},
      setTemplateResponseData,
      dynamicConfig,
      templateLabels,
      tpId,
      tempCriticalTests
    );
  };

  const { partnersList, assignedPartnerDetails } = useMemo(() => {
    const eligiblePartners: any = [];
    const ineligiblePartners: any = [];
    if (eligiblePartnerResults && eligiblePartnerResults?.dailyCreditLimit) {

      const partners = Object.keys(eligiblePartnerResults?.dailyCreditLimit);

      let partnersList = dscrPartner?.filter((partner: any) => partners.includes(partner?.id));
      partnersList = partnersList?.map((partner: any) => {
        if (eligiblePartnerResults?.eligiblePartners?.includes(partner?.id)) {
          eligiblePartners.push(partner);
        } else {
          ineligiblePartners.push(partner);
        }
        const data = testTabData?.find((tab: any) => {
          return tab[DSCROrgPartnerMapping[partner?.id]]
        });

        const criticalTestCount = data?.[DSCROrgPartnerMapping[partner?.id]]?.filter((test: any) => test.isCriticalTest);
        const nonCriticalTestCount = data?.[DSCROrgPartnerMapping[partner?.id]]?.filter((test: any) => !test.isCriticalTest);
        return {
          ...partner,
          failedCount: nonCriticalTestCount?.length,
          criticalFailed: criticalTestCount?.length
        }
      });
      const assignedPartnerDetails = partnersList.find((partner: any) => partner?.id === eligiblePartnerResults?.assignedPartner);
      return { partnersList, assignedPartnerDetails };
    }
    return { partnersList: [], assignedPartnerDetails: {} };
  }, [DSCROrgPartnerMapping, dscrPartner, eligiblePartnerResults, testTabData]);


  const { filteredPartners, ineligiblePartners } = useMemo(() => {
    if (!showPricingOnlyForEligible) {
      return { filteredPartners: partnersList, ineligiblePartners: [] };
    }
    const ineligiblePartners = partnersList.filter((partnerData: ObjectType) => partnerData.criticalFailed > 0);
    const filteredPartners = partnersList.filter((partnerData: ObjectType) => partnerData.criticalFailed === 0);
    return { filteredPartners, ineligiblePartners };
  }, [showPricingOnlyForEligible, partnersList]);

  useEffect(() => {
    if (eligiblePartnerResults && eligiblePartnerResults?.dailyCreditLimit) {
      const finalPartnerResultWithData: any = [];
      const sortedPartnersListBasedOnCriticalTest: any = partnersList?.sort((partner1: any, partner2: any) => partner1?.criticalFailed - partner2?.criticalFailed);
      Object.keys(partnersResults).forEach((partnerId: any, idx: number) => {
        const data = {
          ...partnersResults[partnerId]?.loanResult?.[loanId]?.loanPricing,
          ...partnersResults[partnerId]?.loanResult?.[loanId]?.toorakProceeds,
        }
        const currentPartner = partnersList.find((partner: any) => partner?.id === partnerId);
        finalPartnerResultWithData[idx] = {
          ...currentPartner,
          data
        }
      });

      const eligiblePartnersColumns = getEligiblePartnersCols(sortedPartnersListBasedOnCriticalTest);
      const finalPricingCols = getfinalPricingCols(filteredPartners);
      const pricingAdjustmentsCols = getPricingAdjustmentsCols(filteredPartners);

      const tableContent = [
        {
          columns: finalPricingCols,
          rowData: getRowsData(getLoanPricingFields("PRICE"), finalPartnerResultWithData)
        },
        {
          columns: pricingAdjustmentsCols,
          rowData: getRowsData(labelFieldName30year("PRICE"), finalPartnerResultWithData)
        }];
      setEligiblePartnersColumns(eligiblePartnersColumns);
      setFinalPricingCols(finalPricingCols);
      setPricingAdjustmentsCols(pricingAdjustmentsCols);
      setFinalPricingColsData(getRowsData(getLoanPricingFields("PRICE"), finalPartnerResultWithData));
      setPricingAdjustmentsColsData(getRowsData(labelFieldName30year("PRICE"), finalPartnerResultWithData));
      setTableContents(tableContent);
      setTakeOutPartnerLists(partnersList);
    }
  }, [eligiblePartnerResults, dscrPartner, isLATUser, partnersResults, loanId, testTabData, loanDetails.takeOutPartner, partnersList, filteredPartners]);

  const tableWidth: string = useMemo(() => {
    if (filteredPartners.length === partnersList.length) {
      return "100%";
    }
    const ratio = filteredPartners.length / partnersList.length;
    return `calc( 100% * ${ratio} + 300px * ${1 - ratio})`;
  }, [filteredPartners, partnersList]);

  useEffect(() => {
    if (view === "") setView("pricing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridTemplate = useMemo(() => {
    return eligiblePartnersColumns?.reduce((prev: string, next: any) => {
      return `${prev}${next?.width ? `${next.width}px ` : "1fr "}`;
    }, "");
  }, [eligiblePartnersColumns]);

  const views: ObjectType[] = [
    { label: t("pricing"), key: "pricing" },
    { label: t("testResult"), key: "testResult" }
  ];

  function handleViewChange(view: string) {
    setView(view);
  }

  return (
    <Grid container>
      <PartnerAndProgrammeSelection
        header={null}
        showSwitchTab={true}
        buttonGroupClasses={null}
        handleViewChange={handleViewChange}
        view={view}
        views={views}
        tablesContents={tablesContents}
        tableHeadCss={{
          // border: "1px solid #DBE2FF",
          background: "#E4EAFF"
        }}
        EligiblePartnersTableRow={EligiblePartnersTableRow}
        CustomTable={CustomTable}
        TestResultComponent={LoanResultTab}
        pricingAdjustmentsColsData={pricingAdjustmentsColsData}
        pricingAdjustmentsCols={pricingAdjustmentsCols}
        finalPricingColsData={finalPricingColsData}
        finalPricingCols={finalPricingCols}
        TestResultData={{
          testTabData,
          isExpanded,
          expandLeverageAndTests: false,
          setIsExpanded,
          loanDetails,
          isDSCR: loanType === "InvestorDSCR",
          ratesAsOfDate: ratesAsOfDate,
          eligiblePartnerResults,
          changePartner,
          CustomCard,
          CustomChip,
          DSCROrgPartnerMapping,
          DownArrow,
          InfoIcon,
          viewType: "test-results",
          NonCriticalTestIcon: NonCriticalTestIconWithBG,
          CriticalTestIcon: CriticalTestIconWithBG,
          isApplicationSubmitted,
          CheckCirclePass,
          gridTemplate,
          eligiblePartnersColumns,
          takeOutPartnerLists,
          isLATUser,
          LoaderSvg,
          ineligiblePartners,
          filteredPartners,
          showPricingOnlyForEligible,
          tableWidth,
          showRadioSelection
        }}
        isSizer={true}
      />
    </Grid>
  )
}