import React, { useEffect } from "react";

import AccordionUI from "../../components/common/AccordionUI";

import { ObjectType } from "../../types/tableTypes";

export default function LoanDetails({
  isApplicationSubmitted,
  idx,
  loanType,
  theme,
  translation,
  loanId,
  propType,
  loanContextData,
}: {
  isApplicationSubmitted: boolean;
  idx: number;
  loanType: string | undefined;
  theme: any;
  translation: any;
  loanId: string | undefined;
  propType: string | undefined;
  loanContextData: ObjectType;
}) {
  const {
    loanDetailsConfig,
    loanDetails,
    expandLeverageAndTests,
    autoCalculatedLoanResults,
    setLoanDetailsConfig,
  } = loanContextData;

  useEffect(() => {
    if (
      autoCalculatedLoanResults?.loanEconomics?.totalBudget !==
      loanDetails?.loanEconomics?.financedBudget && propType === "GroundUp"
    ) {
      let tempLoanDetailsConfig: any = {};
      tempLoanDetailsConfig = {
        ...loanDetailsConfig,
        fields: loanDetailsConfig?.fields?.map((field: any) => {
          if (field?.id === "loanEconomics") {
            const fields = field?.fields?.map((subField: any) => {
              if (subField?.id === "financedBudget") {
                return {
                  ...subField,
                  isPopUpMessage:
                    +autoCalculatedLoanResults?.loanEconomics?.totalBudget !==
                    +loanDetails?.loanEconomics?.financedBudget,
                };
              }
              return subField;
            });
            return {
              ...field,
              fields,
            };
          }
          return field;
        }),
      };
      setLoanDetailsConfig(tempLoanDetailsConfig);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCalculatedLoanResults?.loanEconomics?.totalBudget,
    loanDetails?.loanEconomics?.financedBudget,
  ]);

  return (
    <AccordionUI
      component={loanDetailsConfig}
      idx={idx}
      label="loanDetails"
      data={loanDetails}
      expandLeverageAndTests={expandLeverageAndTests}
      isApplicationSubmitted={isApplicationSubmitted}
      loanType={loanType}
      theme={theme}
      translation={translation}
      loanId={loanId}
      propType={propType}
      loanContextData={loanContextData}
    />
  );
}
