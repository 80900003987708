import { Grid, Typography } from '@mui/material'

type KDMHeaderPropTypes = {
    label: string
    handleShowKDMBody: (status: boolean) => void
    icon: any
}

const KDMHeader = ({label, handleShowKDMBody, icon}: KDMHeaderPropTypes) => {
  return (
  
      <Grid container sx={{ height: "72px", padding: "20px", borderBottom: "1px solid #B4BBD8"}}>
        <Grid item xs={11}>
            <Typography sx={{fontSize: "24px", fontWeight: "bold"}}>{label}</Typography>

        </Grid>
        <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
            <img src={icon} style={{cursor: "pointer"}} onClick={() => handleShowKDMBody(false)}/>
        </Grid>

      </Grid>
  )
}

export default KDMHeader
