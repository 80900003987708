export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const templateKeys = {
  "templateKeys": [
      "adjustedToorakRate",
      "toorakLoanType",
      "toorakFICObucket",
      "toorakConnect",
      "toorakCondominimumQuestionnaire",
      "genericTemplateMessage"
  ]
}