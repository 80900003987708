import React from "react";
import { useTranslation } from "react-i18next";

import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ObjectType } from "../../types/tableTypes";

export default function CustomRadio({
  field,
  options,
  value,
  handleChange,
  loanContextData,
  component,
  childIdx,
  unitIdx,
  errorMsg,
  isApplicationSubmitted,
}: {
  field: any;
  options: any;
  value: any;
  handleChange: any;
  loanContextData: ObjectType;
  component?: ObjectType;
  childIdx?: number | undefined;
  unitIdx?: number;
  errorMsg?: string;
  isApplicationSubmitted?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <RadioGroup
      aria-label="options"
      name="options"
      value={value}
      row
      onChange={(e) => handleChange(e.target.value, field, "", null, loanContextData)}
    >
      {options.map((option: any, idx: number) => (
        <FormControlLabel
          key={idx}
          value={t(option.label)}
          control={<Radio size="small" />}
          label={t(option.label)}
          disabled={isApplicationSubmitted}
        />
      ))}
    </RadioGroup>
  );
}
