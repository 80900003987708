import React, { useContext } from "react";

import Backdrop from '@mui/material/Backdrop';

import { RootContext } from "../../context/RootContext";
import "../common/Loader.css";

export default function Loader(props: {isOpen?: boolean}) {
  const { dynamicLoader } = useContext(RootContext);
  const { isOpen } = props;
  return (
    <Backdrop open={dynamicLoader || isOpen} className="backDrop">
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    </Backdrop>
  );
}