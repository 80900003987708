import { Snackbar } from "@mui/material";
import { errorSnackBar, snackbarStyle } from "../../pages/Loans/style";
import { SnackbarProps } from "./snackbar.types";
import InfoErrorIcon from '../../assets/InfoErrorIcon.svg'
import CheckedCircle from '../../assets/CheckedCircle.svg'


const SnackBar = (props: SnackbarProps) => {
    const { message, handleClose} = props
    const style =
    message === "failed" ? errorSnackBar : snackbarStyle;


    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{ style }}
        message={
          <div className="flex-display">
            <img
              src={
                message === "failed"
                  ? InfoErrorIcon
                  : CheckedCircle
              }
              className="mr-12"
            />
            <span>
              {message ? message : "Failed to save data"}
            </span>
          </div>
        }
      />
    );
  };

  export default SnackBar