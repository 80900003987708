export const snackbarStyle = {
  borderRadius: "20px",
  background: "#40B4B4",
  boxShadow:
    "0px 2px 4px 0px rgba(0, 0, 0, 0.16), 0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
  color: "white"
};

export const errorSnackBar = {
  borderRadius: "20px",
  background: "#ff5733",
  boxShadow:
    "0px 2px 4px 0px rgba(0, 0, 0, 0.16), 0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
  color: "white"
};
