import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { ObjectType } from "../../types/tableTypes";

const styles = {
  datePicker: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
};

export default function CustomDatePicker({
  field,
  value,
  onChange,
  subComponentLabel,
  theme,
  component,
  translation,
  loanContextData,
  childIdx,
  unitIdx,
  errorMsg,
  isApplicationSubmitted,
}: {
  field: string;
  value: any;
  onChange: any;
  subComponentLabel: any;
  theme: any;
  component: ObjectType | undefined;
  translation: any;
  loanContextData: ObjectType;
  childIdx?: number | undefined;
  unitIdx?: number;
  errorMsg?: string;
  isApplicationSubmitted?: boolean;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={(styles.datePicker, { width: "80%" })}
        value={value ? dayjs(value) : null}
        onChange={(newValue) =>
          onChange(
            newValue,
            field,
            subComponentLabel,
            translation,
            loanContextData,
            component,
            childIdx,
            unitIdx
          )
        }
        slotProps={{
          textField: {
            size: "small",
            error: Boolean(errorMsg?.length),
            helperText: errorMsg,
          },
        }}
        format="MM/DD/YYYY"
        disabled={isApplicationSubmitted}
      />
    </LocalizationProvider>
  );
}
