/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { getExpandedViewFields } from "./LoanFields";
import { getTitleInfo, postGuidelineEvaluation } from "./Common";
import { rehabTypes } from "../../utils/Common";
import { LocationSuggestion } from "../../types/fieldTypes";
import CustomInput from "../../components/common/CustomInput";
import CustomButtonGroup from "../../components/common/CustomButtonGroup";
import CustomSelect from "../../components/common/CustomSelect";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import CustomAutoComplete from "../../components/common/CustomAutoComplete";
import CustomRadio from "../../components/common/CustomRadio";
import CheckBox from "../../components/common/CheckBox";

export default function ExpandedFieldsUI(props: any) {
  const { loanContextData, isApplicationSubmitted, theme, defaultSelectedDataView } = props;
  const {
    loanDetails,
    propertyDetails,
    setLoanDetails,
    fetchData,
    setDebouncedValue,
    addressOptions,
    zipCodeOptions,
    setPropertyDetails
  } = loanContextData;

  const { loanType, propType } = useParams();
  const [fieldData, setFieldData] = useState<any>({});
  const [fieldConfig, setFieldConfig] = useState<any>([]);
  const { t } = useTranslation();

  const getFieldsData = useCallback(() => {
    const tempConfigData: any = {};
    fieldConfig?.forEach((config: any) => {
      if (config.parentSection?.includes("property")) {
        tempConfigData[config.id] = propertyDetails[0]?.[config?.parentSection]?.[config.id] ?? "";
      } else {
        const currentObjValue = config?.autoCalculated ? loanDetails?.autoCalculatedLoanResults : loanDetails;
        tempConfigData[config.id] = currentObjValue?.[config?.parentSection]?.[config.id] ?? "";
      }
    });
    setFieldData(tempConfigData);
  }, [fieldConfig, loanDetails, propertyDetails]);

  const handleChange = useCallback((e: any, field: any) => {
    setFieldData((prevFieldData: any) => ({ ...prevFieldData, [field.id]: e }));
  }, []);

  const handleModify = useCallback(() => {
    postGuidelineEvaluation(setDebouncedValue, { loanDetails, propertyDetails });
  }, [loanDetails, propertyDetails, setDebouncedValue]);

  const getAddressOptionLabel = useCallback((option: LocationSuggestion) => {
    if (typeof option === "string") {
      return option;
    } else if (option.street_line) {
      return `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`;
    }
    return "";
  }, []);

  const getPostalOptionLabel = useCallback((option: any) => {
    if (typeof option === "string") {
      return option;
    } else if (option.city) {
      return `${option.city} ${option.state_abbreviation} ${option.zipCode}`;
    }
    return "";
  }, []);

  const postalOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any | null,
    childIdx: number | undefined,
    field: any,
    translation: any,
    loanContextData: any
  ) => {
    const {
      addressInputValue,
      propFormErrors,
      propertyDetails,
      setPropertyDetails,
      loanFormData,
      setLoanFormData,
      zipCodeOptions,
      setZipCodeOptions,
      setPropFormErrors,
      setDebouncedValue,
    } = loanContextData;
    setZipCodeOptions(newValue ? [newValue, ...zipCodeOptions] : zipCodeOptions);
    const tempPropFormErrors = [...propFormErrors];
    if (newValue) {
      const { city, state_abbreviation, zipCode } = newValue;
      const tempFormData = propertyDetails?.map(
        (propDetail: any, idx: number) => {
          if (idx === childIdx) {
            tempPropFormErrors[idx] = {
              ...tempPropFormErrors[idx],
              ["propertyLocation"]: {
                ...tempPropFormErrors[idx]?.["propertyLocation"],
                ["address"]:
                  translation(field?.getFieldErrors(addressInputValue)) ?? "",
                ["city"]: translation(field?.getFieldErrors(city)) ?? "",
                ["state"]:
                  translation(field?.getFieldErrors(state_abbreviation)) ?? "",
                ["postalCode"]: translation(field?.getFieldErrors(zipCode)) ?? "",
              },
            };
            const prevCity = propDetail?.propertyLocation?.city;
            const prevState = propDetail?.propertyLocation?.state;

            return {
              ...propDetail,
              propertyLocation: {
                ...propDetail.propertyLocation,
                ["address"]: `${addressInputValue
                  ?.replace(prevCity, city)
                  ?.replace(prevState, state_abbreviation)}`,
                ["city"]: city,
                ["state"]: state_abbreviation,
                ["postalCode"]: zipCode,
              },
            };
          }
          return propDetail;
        }
      );
      setFieldData({
        ...fieldData,
        [field.id]: `${zipCode}`,
        address: `${addressInputValue
          ?.replace(propertyDetails[0]?.propertyLocation?.city, city)
          ?.replace(propertyDetails[0]?.propertyLocation?.state, state_abbreviation)}`,
        city,
        state: state_abbreviation
      });
      // setPropFormErrors(tempPropFormErrors);
      // setPropertyDetails(tempFormData);
      // setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
    }
  };

  const postalCodeOnInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    translation: any,
    loanContextData: any,
    childIdx: number | undefined
  ) => {
    const {
      propertyDetails,
      setPropertyDetails,
      loanFormData,
      setLoanFormData,
      zipCodeInputValue,
      setZipCodeInputValue,
    } = loanContextData;
    if (value.length > 7) return;
    const tempFormData = propertyDetails?.map(
      (propDetail: any, idx: number) => {
        if (idx === childIdx) {
          return {
            ...propDetail,
            propertyLocation: {
              ...propDetail.propertyLocation,
              ["postalCode"]: value,
            },
          };
        }
        return propDetail;
      }
    );
    setFieldData({
      ...fieldData,
      postalCode: `${value}`,
    });
    // setPropertyDetails(tempFormData);
    // setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
    setZipCodeInputValue(value);
  };

  useEffect(() => {
    let loanTypeStr = "";
    switch (loanType) {
      case "InvestorDSCR":
        loanTypeStr = "DSCR";
        break;
      case "BridgeLoan":
        loanTypeStr = "Bridge";
        if (fetchData?.propertyType === "Multifamily/MixedUse" && rehabTypes.includes(propType as string)) {
          loanTypeStr += "MultiFamily";
        } else if (propType === "GroundUp") {
          loanTypeStr += "GroundUp";
        } else if (propType === "Fix&Flip") {
          loanTypeStr += "Fix&Flip";
        } else if (propType === "Stabilized") {
          loanTypeStr += "Stabilized";
        } else {
          loanTypeStr += "Rehab";
        }
        break;
    }
    const fieldsToRender = getExpandedViewFields(loanTypeStr);
    setFieldConfig(fieldsToRender);
  }, [loanType, propType, fetchData]);

  useEffect(() => {
    getFieldsData();
  }, [fieldConfig]);

  useEffect(() => {
    const newTempLoanDetails = { ...loanDetails };
    const newTempPropertyDetails = propertyDetails?.map((property: any) => ({ ...property })) || [];
    fieldConfig.forEach((config: any) => {
      if (config?.parentSection?.includes("property")) {
        newTempPropertyDetails?.forEach((property: any, idx: number) => {
          if (idx === 0) {
            property[config.parentSection] = {
              ...property[config?.parentSection],
              [config.id]: fieldData[config.id]
            };
          }
        });
      } else {
        const currentObjValue = config.isAutoCalculated() ? loanDetails?.autoCalculatedLoanResults : loanDetails;
        newTempLoanDetails[config?.parentSection] = {
          ...newTempLoanDetails[config?.parentSection],
          [config.id]: fieldData[config.id]
        };
      }
    });
    setLoanDetails(newTempLoanDetails);
    setPropertyDetails(newTempPropertyDetails);
  }, [fieldData, fieldConfig]);

  return (
    <div>
      <Grid container>
        {fieldConfig?.map((field: any) => {
          switch (field?.type(loanType, defaultSelectedDataView)) {
            case "buttonGroup":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, false, loanType, t, loanContextData)}
                  <div className="mt-4" style={{ width: "86%" }}>
                    <CustomButtonGroup
                      buttons={field.data}
                      selectedButton={fieldData[field.id]}
                      handleDataViewChange={handleChange}
                      className="button-grp-style"
                      buttonSize="large"
                      translation={t}
                      loanContextData={loanContextData}
                      field={field}
                      subComponentLabel={""}
                      theme={theme}
                      errorMsg={""}
                      isDataView={false}
                      component={undefined}
                      childIdx={undefined}
                      unitIdx={undefined}
                      isApplicationSubmitted={isApplicationSubmitted}
                      isExpandedView={true}
                    />
                  </div>
                </React.Fragment>
              );
            case "dropDown":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, false, loanType, t, loanContextData)}
                  <div className="mt-4" style={{ width: "86%" }}>
                    <CustomSelect
                      data={field?.data ?? []}
                      field={field}
                      onChange={handleChange}
                      value={fieldData[field.id] ?? ""}
                      subComponentLabel={""}
                      theme={theme}
                      component={undefined}
                      translation={t}
                      loanContextData={loanContextData}
                      childIdx={undefined}
                      unitIdx={undefined}
                      errorMsg={""}
                      checkEligibility={field?.checkEligibility}
                      defaultSelectedDataView={defaultSelectedDataView}
                      loanType={loanType}
                      isApplicationSubmitted={isApplicationSubmitted}
                      isExpandedView={true}
                    />
                  </div>
                </React.Fragment>
              );
            case "string":
            case "number":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, false, loanType, t, loanContextData)}
                  <div className="mt-4">
                    <CustomInput
                      type={field?.type(loanType, "minimumDataView")}
                      field={field}
                      value={fieldData[field.id]}
                      onChange={handleChange}
                      subComponentLabel={""}
                      theme={theme}
                      component={undefined}
                      translation={t}
                      loanContextData={loanContextData}
                      defaultSelectedDataView={defaultSelectedDataView}
                      loanType={loanType}
                      isApplicationSubmitted={isApplicationSubmitted}
                      isCurrency={field.isCurrency}
                      isPercentage={field.isPercentage}
                    />
                  </div>
                </React.Fragment>
              );
            case "date":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, false, loanType, t, loanContextData)}
                  <div className="mt-4">
                    <CustomDatePicker
                      field={field}
                      value={fieldData[field.id]}
                      onChange={handleChange}
                      subComponentLabel={""}
                      theme={theme}
                      component={undefined}
                      translation={t}
                      loanContextData={loanContextData}
                      childIdx={undefined}
                      unitIdx={undefined}
                      errorMsg={""}
                      isApplicationSubmitted={isApplicationSubmitted}
                    />
                  </div>
                </React.Fragment>
              );
            case "autoComplete":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, true, loanType, t, loanContextData)}
                  <div className="mt-4" style={{ width: "86%" }}>
                    <CustomAutoComplete
                      label={t(field?.label)}
                      addressOptions={addressOptions}
                      zipCodeOptions={zipCodeOptions}
                      selectedAddressValue={fieldData[field.id]}
                      selectedZipCodeValue={fieldData[field.id]}
                      getAddressOptionLabel={getAddressOptionLabel}
                      getPostalOptionLabel={getPostalOptionLabel}
                      addressOnChange={(e: any, value: any) => handleChange(value, field)}
                      postalOnChange={(e: any, value: any) => postalOnChange(e, value, 0, field, t, loanContextData)}
                      zipCodeInputValue={fieldData[field.id] || ''}
                      addressInputValue={fieldData[field.id] || ''}
                      postalCodeOnInputChange={(e: any, value: any) => postalCodeOnInputChange(e, value, t, loanContextData, 0)}
                      addressOnInputChange={(e: any, value: any) => handleChange(value, field)}
                      isAutoPopulated={field.isAutoPopulated}
                      isApplicationSubmitted={isApplicationSubmitted}
                      field={field}
                      loanContextData={loanContextData}
                      translation={t}
                      isExpandedView={true}
                    />
                  </div>
                </React.Fragment>
              );
            case "radio":
              return (
                <React.Fragment key={field.id}>
                  {getTitleInfo(field, true, loanType, t, loanContextData)}
                  <div className="mt-4">
                    <CustomRadio
                      field={field}
                      options={field?.data}
                      value={fieldData[field.id]}
                      handleChange={handleChange}
                      loanContextData={loanContextData}
                      component={undefined}
                      childIdx={undefined}
                      unitIdx={undefined}
                      errorMsg={""}
                      isApplicationSubmitted={isApplicationSubmitted}
                    />
                  </div>
                </React.Fragment>
              );
            case "checkBox":
              return (
                <div className="mt-4" key={field.id}>
                  <CheckBox
                    label={getTitleInfo(field, false, loanType, t, loanContextData)}
                    field={field}
                    value={fieldData[field.id]}
                    handleChange={handleChange}
                    subComponentLabel={""}
                    component={undefined}
                    translation={t}
                    loanContextData={loanContextData}
                    childIdx={undefined}
                    unitIdx={undefined}
                    errorMsg={""}
                    isApplicationSubmitted={isApplicationSubmitted}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </Grid>
      <Grid container>
        <Button
          className="mt-12"
          variant="contained"
          onClick={handleModify}
          data-testid="modify-button"
          disabled={isApplicationSubmitted}
        >
          {t("modify")}
        </Button>
      </Grid>
    </div>
  );
}
