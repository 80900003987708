import React from "react";
import { useTranslation } from "react-i18next";

import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { ObjectType } from "../../types/tableTypes";

import { isFalsyButNotZero } from "../../utils/Validations";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
};

export default function CustomSelect({
  data,
  field,
  onChange,
  value,
  subComponentLabel,
  theme,
  component,
  translation,
  loanContextData,
  childIdx,
  unitIdx,
  errorMsg,
  checkEligibility,
  defaultSelectedDataView,
  loanType,
  isApplicationSubmitted,
  isExpandedView
}: {
  data: any;
  field: any;
  onChange: any;
  value: any;
  subComponentLabel: any;
  theme: any;
  component: ObjectType | undefined;
  translation: any;
  loanContextData: ObjectType;
  childIdx?: number | undefined;
  unitIdx?: number;
  errorMsg?: string;
  checkEligibility?: boolean;
  defaultSelectedDataView?: string;
  loanType?: string;
  isApplicationSubmitted?: boolean;
  isExpandedView?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined" sx={isExpandedView ? { width: "100%" } : { width: "83%" }}>
      <Select
        className={`app-theme-data-points mr-2 truncate-words p-4 br-6 ${!!errorMsg && "app-theme-data-points-error"
          } `}
        displayEmpty
        input={<OutlinedInput />}
        size="small"
        value={isFalsyButNotZero(value) ? value : "none"}
        onChange={(ele) => {
          onChange(
            ele.target.value,
            field,
            subComponentLabel,
            translation,
            loanContextData,
            component,
            childIdx,
            unitIdx
          );
        }}
        sx={styles.select}
        style={{ height: "6vh" }}
        placeholder="Select"
        disabled={
          isApplicationSubmitted ||
          (field?.isAutoCalculated &&
            (field?.isAutoCalculated(loanType, defaultSelectedDataView) ||
              (field?.isDisabled ? true : false)))
        }
      >
        <MenuItem value="none" disabled sx={{ fontSize: "12px" }}>
          Select
        </MenuItem>
        {data?.map((ele: any, idx: number) => {
          return (
            <MenuItem key={idx} value={ele.value} sx={{ fontSize: "12px" }}>
              {t(ele.label)}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText className="break-word">{errorMsg}</FormHelperText>
    </FormControl>
  );
}
