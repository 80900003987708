export const contentHeader = {
  color: " #000",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.5px",
};

export const label = {
  color: "#000",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.5px",
};

export const inputAdornMentStyle = {
  borderRadius: "6px 0px 0px 6px",
  border: "1px solid #DBE2FF",
  background: "#DBE2FF",
};

export const renderMainComponent = {
  borderRadius: "8px 8px 0px 0px",
  background: "#433182",
  padding: "15px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};

export const textFieldStyle = {
  color: "#666",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.5px",
};
