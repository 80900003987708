import { axiosClient } from "../App";

export const getAutocompleteAddress = (apiKey: string, searchValue: string) =>
  axiosClient.get(
    `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${apiKey}&search=${searchValue}`
  );

export const getZipCodeList = (apiKey: string, searchValue: string) =>
  axiosClient.get(
    `https://us-zipcode.api.smartystreets.com/lookup?auth-id=${apiKey}&zipcode=${searchValue}`
  );

export const getToken = (body: any, headers: any, clientConfig: any) =>
  axiosClient.post(`${clientConfig?.ViteAppRealms}/token`, body, { headers });

export const logoutApp = (url: any, formBodyString: any, headers: any) =>
  axiosClient.post(url, formBodyString, { headers });

export const loanDataBasedOnLoanId = (body: any) =>
  axiosClient.post(
    `/online-sizer/fetch`,
    body
  );

export const patchSaveApplication = (loanId: any, tempData: any) =>
  axiosClient.patch(`/online-sizer/update`, [
    {
      loanId,
      data: tempData,
    },
  ]);

export const postCreateLoan = (createPayload: any, realmHeaders: any) =>
  axiosClient.post(
    `/public-api/aggregate/loans/create`,
    createPayload,
    {
      headers: realmHeaders,
    }
  );

export const postCalculateEligibility = (reqBody: any, realmHeaders: any) =>
  axiosClient.post(
    `/public-api/template/fetch?locale=NA&countryCode=NA&partialProcess=false`,
    reqBody,
    {
      headers: realmHeaders,
    }
  );

export const getClientResponseConfig = (url: any, body: any) =>
  axiosClient.get(url, body);

export const clickContinue = (reqBody: any) =>
  axiosClient.post(
    `/online-sizer/create`,
    reqBody
  );

export const postRowData = (body: any) =>
  axiosClient.post(
    `/online-sizer/fetch/query`,
    body
  );

export const customerData = (decodedEmail: any) =>
  axiosClient.get(`/public-api/customer/${decodedEmail}`);

export const orgBucketResponse = (bucketPartyId: any) =>
  axiosClient.get(
    `/public-api/aggregate/accountmap/originator/${bucketPartyId}`
  );

export const filterDataResponse = (filters: any) =>
  axiosClient.post(
    `/online-sizer/aggregate/query`,
    {
      propertyPath: "data.productType",
      conditions: [filters],
    }
  );

export const getTemplateResponseDSCR = (
  tpId: any,
  orgId: string,
  reqBody: any
) => {
  let url = `/public-api/template/fetch?locale=NA&countryCode=NA&partialProcess=true&tpId=${tpId}`;
  if (orgId) {
    url = `${url}&partyId=${orgId}`
  }
  return axiosClient.post(
    url,
    reqBody
  );
}


export const getTemplateResponse = (
  partyId: any,
  reqBody: any,
  publicAPI2Headers: any
) =>
  axiosClient.post(
    `/public-api/template/fetch?locale=NA&countryCode=NA&partialProcess=true&partyId=${partyId}`,
    reqBody,
    {
      headers: publicAPI2Headers,
    }
  );

export const getGuidlineEvaluationResult = (
  loanType: any,
  reqBody: any
) => {
  const urlEndPoint =
    loanType === "InvestorDSCR"
      ? "public-api/aggregate/online-sizer/evaluation/v1.0"
      : "public-api/ruleevaluation/v1.0";
  return axiosClient.post(`/${urlEndPoint}`, reqBody);
};

export const getEligibleResult = (
  eligiblePayload: any,
  publicAPI2Headers: any
) =>
  axiosClient.post(
    `/public-api/aggregate/takeout-partner/change-takeout-partners?skipSave=true`,
    eligiblePayload,
    {
      headers: publicAPI2Headers,
    }
  );

export const postUpdateLoanUrl = (updatePayload: any, realmHeaders: any) =>
  axiosClient.post(
    `/public-api/aggregate/loans`,
    updatePayload,
    {
      headers: realmHeaders,
    }
  );

export const postUpdatePropertyUrl = (
  createResultLoanId: any,
  propertyPayload: any,
  realmHeaders: any
) =>
  axiosClient.post(
    `/public-api/aggregate/loan_property/${createResultLoanId}/properties?stage=FES`,
    propertyPayload,
    {
      headers: realmHeaders,
    }
  );

export const postDownloadCondo = (requestObject: any) =>
  axiosClient.post(
    `/public-api/aggregate/loans/condo`,
    requestObject
  );

export const postForceLogout = (formBody: any, headers: any, clientConfig: any) =>
  axiosClient.post(
    `${clientConfig.ViteAppRealms}/logout`,
    formBody,
    { headers }
  );

export const postFetchRefreshToken = (formBody: any, headers: any, clientConfig: any) =>
  axiosClient.post(`${clientConfig.ViteAppRealms}/token`, formBody, {
    headers,
  });

export const fetchTemplateLabel = (templateKeys: any, clientId: string) => {
  let url = `/public-api/template/fetch?locale=NA&countryCode=NA&partialProcess=true`;
  if (clientId) {
    url += `&partyId=${clientId}`;
  }
  return axiosClient.post(url, templateKeys)
} 

export const fetchPartnerMap = () => {
  const url = `/public-api/credit-line/fetch?creditLineType=TAKEOUT_PARTNER`;
  return axiosClient.get(url)
}

export const getCriticalTests = (rVersion: string) => {
  const url = `/public-api/ruleevaluation/ruleattributes?ruleType=30year&ruleVersion=${rVersion}`;
  const body = ["criticalTestLoanEligibility"];
  return axiosClient.post(url, body);
};
