import React from "react";

import { ObjectType } from "../../types/tableTypes";

import AccordionUI from "../../components/common/AccordionUI";

export default function BorrowerInformation({
    isApplicationSubmitted,
    idx,
    loanType,
    theme,
    translation,
    loanId,
    propType,
    loanContextData
}: {
    isApplicationSubmitted: boolean,
    idx: number,
    loanType: string | undefined,
    theme: any,
    translation: any,
    loanId: string | undefined,
    propType: string | undefined,
    loanContextData: ObjectType
}) {
    const { borrowerInformationConfig, loanDetails, expandLeverageAndTests } = loanContextData;
    return <AccordionUI
        component={borrowerInformationConfig}
        idx={idx}
        label="borrowerDetails"
        data={loanDetails}
        expandLeverageAndTests={expandLeverageAndTests}
        isApplicationSubmitted={isApplicationSubmitted}
        loanType={loanType}
        theme={theme}
        translation={translation}
        loanId={loanId}
        propType={propType}
        loanContextData={loanContextData}
    />
}
