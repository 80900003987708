import React from "react";

import Grid from "@mui/material/Grid";

import { ObjectType } from "../../../types/tableTypes";
import Radio from "@mui/material/Radio";


export default function LoanResultTab({
  tabData,
  isExpanded,
  expandLeverageAndTests,
  setIsExpanded,
  loanDetails,
  eligiblePartnerResults,
  changePartner,
  isLoanDSCR,
  ratesAsOfDate,
  CustomCard,
  CustomChip,
  DSCROrgPartnerMapping,
  DownArrow,
  InfoIcon,
  isApplicationSubmitted
}: {
  tabData: any;
  isExpanded: any;
  expandLeverageAndTests: boolean;
  setIsExpanded: any;
  loanDetails: ObjectType;
  eligiblePartnerResults: any;
  changePartner: any;
  CustomCard: any;
  CustomChip: any;
  DSCROrgPartnerMapping: any;
  DownArrow: any;
  InfoIcon: any;
  isLoanDSCR?: boolean;
  ratesAsOfDate?: string;
  isApplicationSubmitted?: boolean;
}) {
  const contentDiv = {
    padding: "12px",
    borderRadius: "6px",
    background: "white",
  };

  const gridSize = Math.floor(12 / tabData?.length);
  return (
    <div>
      <Grid
        container
        spacing={expandLeverageAndTests ? 0 : 1}
        className="mt-12"
        style={
          expandLeverageAndTests
            ? { overflowX: "auto", flexWrap: "nowrap" }
            : {}
        }
      >
        {tabData?.map((test: ObjectType, idx: number) => {
          const currentTabKey = Object.keys(test)[0];
          const currentId = Object.keys(DSCROrgPartnerMapping).find((key: string) => DSCROrgPartnerMapping[key] === currentTabKey);
          const currentData: any = {
            title: currentTabKey,
            id: currentId
          };

          return !expandLeverageAndTests ? (
            <Grid item xs={gridSize} key={idx}>
              {isLoanDSCR ? (
                <>
                  <Grid sx={{
                    padding: "12px",
                    background: "#E4EAFFCC",
                    border: "1px solid #DBE2FF",
                    fontSize: "18px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Radio
                      checked={
                        [loanDetails?.takeOutPartner].includes(currentData?.id) &&
                        eligiblePartnerResults?.dailyCreditLimit[currentData?.id]?.isWithinLimit
                      }
                      disabled={!eligiblePartnerResults?.dailyCreditLimit[currentData?.id]?.isWithinLimit && isApplicationSubmitted}
                      onChange={(e) => changePartner(currentData)}
                    />
                    <span>{Object.keys(test)[0]}</span>&ensp;
                    <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={InfoIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          {`Failed - ${test[Object.keys(test)[0]]?.length}`}
                        </div>
                      }
                      variant="outlined"
                      className="error-chipData"
                    />
                  </Grid>
                  <Grid style={{ background: "white", padding: 16 }}>
                    {test[Object.keys(test)[0]]?.map(
                      (testEle: ObjectType, idx: number) => {
                        return (
                          <CustomCard
                            key={idx}
                            headingText={testEle.title}
                            subHeadingText={testEle.subText}
                            loanDetails={loanDetails}
                            fullView={true}
                            ratesAsOfDate={ratesAsOfDate}
                            gridSpace={testEle?.gridSpace ?? 4}
                            showFailed={true}
                          />

                        );
                      }
                    )}
                  </Grid>
                </>
              ) : (
                <CustomCard
                  key={idx}
                  headingText={test.title}
                  subHeadingText={test.subText}
                  loanDetails={loanDetails}
                  fullView={true}
                  ratesAsOfDate={ratesAsOfDate}
                  gridSpace={test?.gridSpace ?? 4}
                  showFailed={true}
                />
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={gridSize <= 4 ? 5 : gridSize}
              style={{
                borderRight: "1px solid #A9A9A9",
                minWidth: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px 0px 0px 0px",
                  border: "1px solid #DBE2FF",
                  background: "rgba(228, 234, 255, 0.80)",
                  padding: "0px 10px",
                }}
              >
                <h3>{isLoanDSCR ? currentTabKey : test.title}</h3>
                <CustomChip
                  size="small"
                  label={
                    <div className="div-space-between">
                      <img src={InfoIcon} alt="info-icon" className="mr-8" />{" "}
                      {isLoanDSCR
                        ? `Failed - ${test[currentTabKey]?.length}`
                        : "Failed"}
                    </div>
                  }
                  variant="outlined"
                  className="error-chipData"
                  data-testid="custom-chip"
                />
              </div>
              <div>
                {isLoanDSCR ? (
                  test[currentTabKey]?.map(
                    (testEle: ObjectType, idx: number) => {
                      return (
                        <div key={idx} style={contentDiv}>
                          <CustomCard
                            headingText={testEle.title}
                            subHeadingText={testEle.subText}
                            loanDetails={loanDetails}
                            fullView={true}
                            ratesAsOfDate={ratesAsOfDate}
                            gridSpace={testEle?.gridSpace ?? 4}
                            cardCss={true}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <CustomCard
                    key={idx}
                    headingText={test.title}
                    subHeadingText={test.subText}
                    loanDetails={loanDetails}
                    fullView={true}
                    ratesAsOfDate={ratesAsOfDate}
                    gridSpace={test?.gridSpace ?? 4}
                    showFailed={true}
                  />
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
