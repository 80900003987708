import React from "react";

import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export default function Footer({
  showBackBtn = false,
  showFooterText = false,
}: {
  showBackBtn?: boolean;
  showFooterText?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div>
      {showBackBtn ? (
        <Button className="footer-back-btn" data-testid="back-button">
          Back
        </Button>
      ) : null}
      {showFooterText ? (
        <div className="footer-text" data-testid="footer-text">
          <span>
            <b>{t("elNoteText")}</b> {t("emptyLoanNoteText")}
          </span>
        </div>
      ) : null}
    </div>
  );
}
