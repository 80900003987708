import React, { createContext, useState } from "react";
import { ObjectType } from "../types/tableTypes";

const RootContext = createContext<any>(null);

const RootProvider = ({ children, dynamicConfig, setUserLoggedIn, userLoggedIn }: any) => {
  const path = window.location.pathname;
  const [currentPath, setCurrentPath] = useState<string>(path)
  const [evaluateLoanForm, setEvaluateLoanForm] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [decodedTokenData, setDecodedTokenData] = useState<any>({});
  const [partyDetails, setPartyDetails] = useState<string>("");
  const [userPermissions, setUserPermissions] = useState<ObjectType[]>([]);
  const [dynamicLoader, setDynamicLoader] = useState<boolean>(false);
  const [templateLabels, setTemplateLabels] = useState<ObjectType>({});
  const [dscrPartner, setDscrPartner] = useState<ObjectType>([]);
  const [DSCROrgPartnerMapping, setDSCROrgPartnerMapping] = useState<any>({});
  const [termSheetData, setTermSheetData] = useState<any>({});
  const [termSheetEmailData, setTermSheetEmailData] = useState<any>({});
  const [termSheetLoanData, setTermSheetLoanData] = useState<any>({});
  const [termSheetSuggestedLoanData, setTermSheetSuggestedLoanData] = useState<any>({});
  const [termSheetFeeData, setTermSheetFeeData] = useState<any>({});
  const [termSheetRequirementsData, setTermSheetRequirementsData] = useState<any>({});
  const [termSheetConfig, setTermSheetConfig] = useState<any>({});
  const [termSheetVersions, setTermSheetVersions] = useState<any>([]);
  const [termSheetAction, setTermSheetAction] = useState("");
  const [currentTermSheetVersion, setCurrentTermSheetVersion] = useState<any>(null);
  const [isOpenTermSheet, setIsOpenTermSheet] = useState(false);
  const [showTermSheet, setShowTermSheet] = useState<boolean | undefined>(false);
  const [showKeyDealMetrics, setShowKeyDealMetrics] = useState(false);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [orgData, setOrgData] = useState<any>({});

  return (
    <RootContext.Provider
      value={{
        evaluateLoanForm,
        isLoading,
        decodedTokenData,
        partyDetails,
        userPermissions,
        dynamicLoader,
        dynamicConfig,
        templateLabels,
        dscrPartner,
        DSCROrgPartnerMapping,
        termSheetData,
        termSheetEmailData,
        termSheetLoanData,
        termSheetSuggestedLoanData,
        termSheetFeeData,
        termSheetRequirementsData,
        termSheetConfig,
        termSheetVersions,
        termSheetAction,
        currentTermSheetVersion,
        isOpenTermSheet,
        showTermSheet,
        showKeyDealMetrics,
        loanTypes,
        orgData,
        currentPath,
        userLoggedIn,
        // setState functions
        setTemplateLabels,
        setEvaluateLoanForm,
        setIsLoading,
        setDecodedTokenData,
        setPartyDetails,
        setUserPermissions,
        setDynamicLoader,
        setUserLoggedIn,
        setDscrPartner,
        setDSCROrgPartnerMapping,
        setTermSheetData,
        setTermSheetEmailData,
        setTermSheetLoanData,
        setTermSheetSuggestedLoanData,
        setTermSheetFeeData,
        setTermSheetRequirementsData,
        setTermSheetConfig,
        setTermSheetVersions,
        setTermSheetAction,
        setCurrentTermSheetVersion,
        setIsOpenTermSheet,
        setShowTermSheet,
        setShowKeyDealMetrics,
        setLoanTypes,
        setOrgData,
        setCurrentPath
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export { RootContext, RootProvider };
