import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import CloseIcon from "../../assets/CloseIcon.svg";

import "./disclaimer.css";

export default function Disclaimer({
  boldText,
  children,
  customClass,
  showClose,
  isRatesAsOff,
}: {
  boldText: string;
  children: any;
  customClass: string;
  showClose: boolean;
  isRatesAsOff?: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <Box
      className={isOpen ? customClass : `${customClass} hide-display`}
      style={isRatesAsOff ? { margin: "0px" } : {}}
    >
      <div>
        {showClose ? (
          <IconButton
            data-testid="close"
            aria-label="close"
            size="small"
            sx={{
              "&:hover": { backgroundColor: "#40b4b4" },
              position: "absolute",
              top: 0,
              right: 0,
              background: "#40B4B4",
            }}
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseIcon} alt="close-icon" />
          </IconButton>
        ) : null}
      </div>

      <div data-testid="desclaimer-box">
        <span className="text">
          <b>{boldText}</b>
          {children}
        </span>
      </div>
    </Box>
  );
}
