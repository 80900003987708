export const TableColumns = [
  {
    title: "Evaluation Id",
    id: "evaluationId",
    frozen: true,
    hidden: false,
  },
  {
    title: "Application Name",
    id: "applicationName",
    frozen: true,
    hidden: false,
  },
  {
    title: "Loan Type",
    id: "loanType",
    frozen: true,
    hidden: false,
    showDropDownFilter: true,
  },
  {
    title: "Loan Amount",
    id: "loanAmount",
    frozen: true,
    hidden: false,
  },
  {
    title: "Interest Rate",
    id: "interestRate",
    frozen: true,
    hidden: false,
  },
  {
    title: "Created On",
    id: "createdOn",
    frozen: true,
    hidden: false,
  },
  {
    title: "Rate as of",
    id: "rateAsOf",
    frozen: true,
    hidden: false,
    showDropDownFilter: true,
  },
  {
    title: "Term Sheet",
    id: "termSheet",
    frozen: true,
    hidden: false,
  },
  {
    title: "Street Address",
    id: "streetAddress",
    frozen: true,
    hidden: false,
  },
  {
    title: "City",
    id: "city",
    frozen: true,
    hidden: false,
  },
  {
    title: "Postal",
    id: "postal",
    frozen: true,
    hidden: false,
  },
];
