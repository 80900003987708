import { Button, Grid } from '@mui/material'

type KDMFooterPropTypes ={
  reactToPrintFn?: any
  componentRef?: any
  loanId?: any
}

const KDMFooter = (props: KDMFooterPropTypes) => {
  const {  componentRef, loanId, reactToPrintFn } = props
  return (
    <div>
      <Grid container sx={{ height: "60px", padding: "12px 20px", borderTop: "1px solid #B4BBD8"}}>
        <Grid item sx={{marginLeft: "12px"}}>
            <Button
                size='large'
                variant='outlined'
                onClick={reactToPrintFn}
            >
                Print
            </Button>
            
        </Grid>
      </Grid>
    </div>
  )
}

export default KDMFooter
