import moment from "moment";
import {
  borrowerTypeDropDownValues,
  condoEligibilityDropDownValues,
  condoEligibilityV2DropDownValues,
  immigrationStatusDSCRDropDownValues,
  loanPurposeDropDownValues,
  prepaymentPenaltyTypeDropDownValues,
  rateTypeDropDownValues,
  propertyTypeDropDownValues,
  thirdPartyValuationStatusDropDownValues,
  loanStructureDropDown,
  bridgeRehabLoanStructureDropDown,
  bridgeGroundUpExitStrategyDropDownValues,
  bridgeGenExitStrategyDropDownValues,
  closingCostDropDownValues,
  irFundingSources,
  rentalCharacterizationDropDownValues,
  currentUsageDropDownValues,
  recourseDropDownValue,
  bridgeLoanPuposeDropDownValues,
  prepaymentPenaltyMonths,
  predominantPropType,
  bridgeGroundUpValutionSource,
  generalValuationSource,
} from "./LoanFieldsData";

import TrashIcon from "../../assets/TrashIcon.svg";
import PlusIcon from "../../assets/PlusIcon.svg";
import DollarIcon from "../../assets/Dollar.svg";
import TestsIcon from "../../assets/TestsIcon.svg";
import CheckCircle from "../../assets/CheckCircle.svg";

import { isFalsyButNotZero, isLetters } from "../../utils/Validations";
import { rehabTypes } from "../../utils/Common";

const latestVersioningDate = "12-11-2024";

// Individual Fields
const propertiesCompletedWithinLast36Months = {
  id: "propertiesCompletedWithinLast36Months",
  label: "propertiesCompletedWithinLast36Months",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const BorrowerGuarantor = {
  id: "borrowerGuarantor",
  label: "borrowerGuarantor",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "No" },
    { label: "no", value: "Yes" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const ImmigrationStatus = {
  id: "immigrationStatus",
  label: "immigrationStatus",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: immigrationStatusDSCRDropDownValues,
  columnSplit: 6,
  minimumDataView: false,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const InState = {
  id: "inState",
  label: "inState",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ],
  columnSplit: 6,
  minimumDataView: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  info: "inStateInfo"
};

const BorrowerType = {
  id: "borrowerType",
  label: "borrowerType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: borrowerTypeDropDownValues,
  columnSplit: 6,
  minimumDataView: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const QualifyingCreditScore = {
  id: "qualifyingCreditScore",
  label: "qualifyingCreditScore",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 6,
  minimumDataView: true,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const creditScoreActual = {
  id: "creditScoreActual",
  label: "creditScoreActual",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 6,
  minimumDataView: true,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const creditScore = {
  id: "creditScoreActual",
  label: "creditScore",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 6,
  minimumDataView: true,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const OriginatorLoanID = {
  id: "originatorLoanID",
  label: "originatorLoanID",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const LoanStructure = {
  id: "loanStructure",
  label: "loanStructure",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: loanStructureDropDown,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const LoanPurpose = {
  id: "loanPurpose",
  label: "loanPurpose",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: loanPurposeDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false
};

const CashOutFlag = {
  id: "cashOutFlag",
  label: "cashOutFlag",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "cashOut", value: "Cash Out" },
    { label: "noCashOut", value: "No Cash Out" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const CashOutAmount = {
  id: "cashOutAmount",
  label: "cashOutAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const ExitStrategy = {
  id: "exitStrategy",
  label: "exitStrategy",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const pledgeOfEquity = {
  id: "pledgeOfEquity",
  label: "pledgeOfEquity",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const ExpectedDateOfClosing = {
  id: "expectedClosingDate",
  label: "expectedClosingDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const OriginalDateOfAcquisition = {
  id: "originalDateOfAcquisition",
  label: "originalDateOfAcquisition",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const PropertyWithDebt = {
  id: "propertyWithDebt",
  label: "propertyWithDebt",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const monthlyPropertyGrossRent = {
  id: "monthlyPropertyGrossRent",
  label: "grossRent",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const NoOfProperties = {
  id: "numberOfProperties",
  label: "numberOfProperties",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const LoanTerm = {
  id: "loanTerm",
  label: "loanTerm",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const CondoEligiBility = {
  id: "condoEligibility",
  label: "condoEligibility",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: condoEligibilityDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  checkEligibility: true,
};

const CondoEligiBilityV2 = {
  id: "condoEligibility",
  label: "condoEligibility",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: condoEligibilityV2DropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  checkEligibility: true,
};

const CrossCollaterlization = {
  id: "crossCollaterlization",
  label: "crossCollaterlization",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const CreditEvent = {
  id: "creditEvent",
  label: "creditEvent",
  required: false,
  title: 'creditEventTitle',
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const includeOutOfPocketBudgetARLTV = {
  id: "includeOutOfPocketBudgetARLTV",
  label: "includeOutOfPocketBudgetARLTV",
  required: true,
  type: (loanType: string, dataView: string) => "checkBox",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  disableField: true,
};

const PrimaryLoanId = {
  id: "primaryLoanID",
  label: "primaryLoanID",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const TotalRehabBudget = {
  id: "totalRehabBudget",
  label: "totalRehabBudget",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  popUpMessage: "RehabNotAllowed"
};

const InitialLoanAmount = {
  id: "initialLoanAmount",
  label: "initialLoanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const RehabLoanAmount = {
  id: "rehabLoanAmount",
  label: "rehabLoanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const TotalOrgAndDiscountPoints = {
  id: "totalOrgAndDiscountPoints",
  label: "totalOrgAndDiscountPoints",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const OrgLoanAmount = {
  id: "originalLoanAmount",
  label: "originalLoanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const OrgInterestRate = {
  id: "originalInterestRate",
  label: "originalInterestRate",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isExpandedView: false,
};

const DSCR = {
  id: "dscr",
  label: "dscr",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) =>
    dataView === "fullDataView",
};

const expandedInterestRate = {
  id: "originalInterestRate",
  label: "interestRate",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isExpandedView: false,
};

const expandedDSCR = {
  id: "dscr",
  label: "dscr",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const PrePaymentPenaltyTypeMonths = {
  id: "prepaymentPenaltyTypeMonths",
  label: "prepaymentPenaltyTypeMonths",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: prepaymentPenaltyMonths,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const PrePaymentPenaltyType = {
  id: "prepayPenaltyType",
  label: "prepayPenaltyType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: prepaymentPenaltyTypeDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const InterestOnlyFlag = {
  // TODO need to check with Moukthik on field mapping
  id: "interestOnlyFlag",
  label: "interestOnlyFlag",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const RequestedAsIsLTV = {
  id: "asIsLTV",
  label: "requestedAsIsLTV",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  autoCalculated: true
};

const borrowerGUCExperience = {
  id: "borrowerGUCExperience",
  label: "borrowerGUCExperience",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value) || value < 0
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const originalCreditScore = {
  // check with Anil on the key field for this field, in TC - originalCreditScoreMedian
  id: "originalCreditScore",
  label: "originalCreditScore",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
  isMedian: true,
};

const borrowerCreditScore = {
  // check with Anil on the key field for this field, in TC - originalCreditScoreMedian
  id: "originalCreditScore",
  label: "borrowerCreditScore",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
  isMedian: true,
};

const heavyRehabExperience = {
  id: "heavyRehabExperience",
  label: "heavyRehabExperience",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const rateType = {
  id: "rateType",
  label: "rateType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: rateTypeDropDownValues,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const propertyType = {
  id: "propertyType",
  label: "propertyType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  data: [...predominantPropType, ...propertyTypeDropDownValues],
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const address = {
  id: "address",
  label: "address",
  required: true,
  type: (loanType: string, dataView: string) => "autoComplete",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validAddress" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const postalCode = {
  id: "postalCode",
  label: "postalCode",
  required: true,
  type: (loanType: string, dataView: string) => "autoComplete",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const city = {
  id: "city",
  label: "city",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isDisabled: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const state = {
  id: "state",
  label: "state",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isDisabled: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const noOfUnits = {
  id: "numberOfUnits",
  label: "numberOfUnits",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  popUpMessage: "24UnitMessage",
};

const currentLeaseTermsInMonths = {
  id: "currentLeaseTermsInMonths",
  label: "currentLeaseTermsInMonths",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const currentUsage = {
  id: "currentUnitUsage",
  label: "currentUnitUsage",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: currentUsageDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const rentalCharacterization = {
  id: "rentalCharacterization",
  label: "rentalCharacterization",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: rentalCharacterizationDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const monthlyMarketRent = {
  id: "monthlyMarketRent",
  label: "monthlyMarketRent",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const monthlyUnitRent = {
  id: "monthlyUnitRent",
  label: "monthlyUnitRent",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isCurrency: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const tenantOccupancy = {
  id: "tenantOccupancy",
  label: "tenantOccupancy",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const operatingHistory = {
  id: "operatingHistory",
  label: "operatingHistory",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const inPlaceLeaseRentMonthly = {
  id: "inPlaceLeaseRentMonthly",
  label: "inPlaceLeaseRentMonthly",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const percentUnitOccupied = {
  id: "percentUnitOccupied",
  label: "percentUnitOccupied",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) =>
    dataView === "fullDataView",
};

const PercentOccupied = {
  id: "percentOccupied",
  label: "percentOccupied",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const ExpansionOrChangeOfUseCase = {
  id: "expansionOrChangeOfUseCase",
  label: "expansionOrChangeOfUseCase",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const ValuationSource = {
  id: "valuationSource",
  label: "valuationSource",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: generalValuationSource,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const propertySqftFootage = {
  id: "propertySqftFootage",
  label: "propertySqftFootage",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const recentPropValuationDate = {
  id: "recentPropValuationDate",
  label: "recentPropValuationDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const precentOccupied = {
  id: "precentOccupied",
  label: "precentOccupied",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) =>
    dataView === "fullDataView",
};

const orgAppraisalValue = {
  id: "orgAppraisalValue",
  label: "orgAppraisalValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const DecliningMarkets = {
  id: "decliningMarkets",
  label: "decliningMarkets",
  required: false,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const purchasePrice = {
  id: "purchasePrice",
  label: "purchasePrice",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const borrowerPaidCostToDate = {
  id: "borrowerPaidCostToDate",
  label: "borrowerPaidCostToDate",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const ClosingCost = {
  id: "closingCosts",
  label: "closingCosts",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: closingCostDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const afterRepairValue = {
  id: "afterRepairValue",
  label: "afterRepairValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const AsRepairedValueARV = {
  id: "asRepairedValueARV",
  label: "asRepairedValueARV",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const EstimatedRentPerYearUponCompletion = {
  id: "estimatedRentPerYearUponCompletion",
  label: "estimatedRentPerYearUponCompletion",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const AssignmentFee = {
  id: "assignmentFee",
  label: "assignmentFee",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const asIsValue = {
  id: "asIsValue",
  label: "asIsValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const thirdPartyValuationStatus = {
  id: "thirdPartyValuationStatus",
  label: "thirdPartyValuationStatus",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: thirdPartyValuationStatusDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const thirdPartyPopertyValue = {
  id: "thirdPartyPopertyValue",
  label: "thirdPartyPopertyValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const propertyAcquisitionPrice = {
  id: "propertyAcquisitionPrice",
  label: "propertyAcquisitionPrice",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const annualPropertyTaxes = {
  id: "annualPropertyTaxes",
  label: "annualPropertyTaxes",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const annualTaxes = {
  id: "annualPropertyTaxes",
  label: "annualTaxes",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const annualHazardInsurance = {
  id: "annualHazardInsurance",
  label: "annualHazardInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const annualFloodInsurance = {
  id: "annualFloodInsurance",
  label: "annualFloodInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const annualHOAFee = {
  id: "annualHOAFee",
  label: "annualHOAFee",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const requestedLTPP = {
  // TODO need to check with Moukthik on field mapping
  id: "requestedLTPP",
  label: "requestedLTPP",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isPercentage: true,
  isAutoCalculated: (loanType: string, dataView?: string) =>
    dataView === "fullDataView",
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const experiencedNew: any = {
  id: "experiencedNew",
  label: "experiencedNew",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: false,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const orgBrokerFee: any = {
  id: "orgBrokerFee",
  label: "orgBrokerFee",
  type: (loanType: string, dataView: string) => "dropDown",
  required: true,
  data: closingCostDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const financedBudget: any = {
  id: "financedBudget",
  label: "financedBudget",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  isExpandedView: false,
  popUpMessage: "budgetMismatch"
};

const totalBudget: any = {
  id: "totalBudget",
  label: "totalBudget",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  isExpandedView: false,
};

const grossOriginationRate: any = {
  id: "grossOriginationRate",
  label: "grossOriginationRate",
  type: (loanType: string, dataView: string) => "string",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const propertyPurchasedWithinLast2Years: any = {
  id: "propertyPurchasedWithinLast2Years",
  label: "propertyPurchasedWithinLast2Years",
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const grossPotentialRentMonthly: any = {
  id: "grossPotentialRentMonthly",
  label: "grossPotentialRentMonthly",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const marketRentPerMonth: any = {
  id: "marketRentPerMonth",
  label: "marketRentPerMonth",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  ifApplicable: true,
};

const irFundingSource: any = {
  id: "irFundingSource",
  label: "irFundingSource",
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: irFundingSources,
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const entitledAsOfRightDevelopment: any = {
  id: "entitledAsOfRightDevelopment",
  label: "entitledAsOfRightDevelopment",
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const permitApprovalsAndFullPlans: any = {
  id: "permitApprovalsAndFullPlans",
  label: "permitApprovalsAndFullPlans",
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: true,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const exitDebt = {
  id: "exitDebt",
  label: "exitDebt",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const hardCosts: any = {
  id: "hardCosts",
  label: "hardCosts",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const softCosts: any = {
  id: "softCosts",
  label: "softCosts",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const interestRate = {
  id: "interestRate",
  label: "interestRate",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const brokerFee = {
  id: "brokerFee",
  label: "brokerFee",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const ioPeriod = {
  id: "ioPeriod",
  label: "ioPeriod",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const recourse = {
  id: "recourse",
  label: "recourse",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: recourseDropDownValue,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const costBasis = {
  id: "costBasis",
  label: "costBasis",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isCurrency: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const softCostsCap15Rehab = {
  id: "softCostsCap15Rehab",
  label: "softCostsCap15Rehab",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const toorakLoanType = {
  id: "toorakLoanType",
  label: "toorakLoanType",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const requiredInterestReserve = {
  id: "requiredInterestReserve",
  label: "requiredInterestReserve",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isCurrencey: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const RequiredInterestReserveMonths = {
  id: "requiredInterestReserveMonths",
  label: "requiredInterestReserveMonths",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const InterestReserve = {
  id: "interestReserve",
  label: "interestReserve",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const rehabBudget = {
  id: "rehabBudget",
  label: "rehabBudget",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const rehabType = {
  id: "rehabType",
  label: "rehabType",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const cbsa = {
  id: "cbsa",
  label: "cbsa",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const purchaseDate = {
  id: "purchaseDate",
  label: "purchaseDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const appraisalStabilizedNOI = {
  id: "appraisalStabilizedNOI",
  label: "appraisalStabilizedNOI",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const interestOnlyPeriod = {
  id: "interestOnlyPeriod",
  label: "interestOnlyPeriod",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: false,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const grossCondoSelloutValue = {
  id: "grossCondoSelloutValue",
  label: "grossCondoSelloutValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const borrowerPaidClosingCosts = {
  id: "borrowerPaidClosingCosts",
  label: "borrowerPaidClosingCosts",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const annualInsurance = {
  id: "annualInsurance",
  label: "annualInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const BorrowerProceeds: any = {
  id: "borrowerProceeds",
  label: "borrowerProceeds",
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: true,
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const PredominantState: any = {
  id: "predominantState",
  label: "predominantState",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: true,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const PredominantPropertyType: any = {
  id: "predominantPropertyType",
  label: "predominantPropertyType",
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: predominantPropType,
  required: false,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const LoanLevelOccupancyPercentage: any = {
  id: "loanLevelOccupancyPercentage",
  label: "loanLevelOccupancyPercentage",
  type: (loanType: string, dataView: string) => "string",
  required: false,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isMx100: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const expandedOccupancy: any = {
  id: "loanLevelOccupancyPercentage",
  label: "occupancy",
  type: (loanType: string, dataView: string) => "string",
  required: false,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isMx100: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  autoCalculated: true
};

const OriginalAsIsLtv = {
  id: "originalAsIsLtv",
  label: "originalAsIsLtv",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const originalAsRepairedLTV = {
  id: "originalAsRepairedLTV",
  label: "originalAsRepairedLTV",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const OriginalLtc = {
  id: "originalLtc",
  label: "originalLtc",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const OriginalLtcLtp = {
  id: "originalLtcLtp",
  label: "originalLtcLtp",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const ToorakFICObucket = {
  id: "toorakFICObucket",
  label: "toorakFICObucket",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const FinalLoanCharacterization = {
  id: "finalLoanCharacterisation",
  label: "finalLoanCharacterisation",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const loanCharacterization = {
  id: "loanCharacterisation",
  label: "loanCharacterisation",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const ESRIPopulationDensity = {
  id: "eSRIPopulationDensity",
  label: "eSRIPopulationDensity",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const ZillowCBSA = {
  id: "zillowCBSA",
  label: "zillowCBSA",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const AdjustedAsIsValue = {
  id: "adjustedAsIsValue",
  label: "adjustedAsIsValue",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const StabilizedFlag = {
  id: "stabilizedFlag",
  label: "stabilizedFlag",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const localCharacterization = {
  id: "localCharacterization",
  label: "localCharacterization",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const MedianValue = {
  id: "medianValue",
  label: "medianValue",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isCurrency: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const LtmHpa = {
  id: "ltmHpa",
  label: "ltmHpa",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const DaysOnMarket = {
  id: "daysOnMarket",
  label: "daysOnMarket",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const BorrowerRequested = {
  id: "borrowerRequested",
  label: "borrowerRequested",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const CustomerTypeExperiencedNew = {
  id: "customerTypeExperiencedNew",
  label: "customerTypeExperiencedNew",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const PurchaseOrRefinance = {
  id: "purchaseOrRefinance",
  label: "purchaseOrRefinance",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const Tier = {
  id: "tier",
  label: "tier",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const AsIsLTV = {
  id: "asIsLTV",
  label: "asIsLTV",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  isExpandedView: false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const LtcAsIs = {
  id: "ltcAsIs",
  label: "ltcAsIs",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const ARVLTVAsCompleted = {
  id: "aRVLTVAsCompleted",
  label: "aRVLTVAsCompleted",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const BlendedLTCAsCompleted = {
  id: "blendedLTCAsCompleted",
  label: "blendedLTCAsCompleted",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const BlendedLTCwithFundedIRAsCompleted = {
  id: "blendedLTCwithFundedIRAsCompleted",
  label: "blendedLTCwithFundedIRAsCompleted",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const MarketName = {
  id: "marketName",
  label: "marketName",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const SubMarketName = {
  id: "subMarketName",
  label: "subMarketName",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const CapRate = {
  id: "capRate",
  label: "capRate",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const TwelveMonthRentalGrowth = {
  id: "twelveMonthRentalGrowth",
  label: "twelveMonthRentalGrowth",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const RentPerSqFt = {
  id: "rentPerSqFt",
  label: "rentPerSqFt",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const RentPerUnit = {
  id: "rentPerUnit",
  label: "rentPerUnit",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const SalePriceGrowth = {
  id: "salePriceGrowth",
  label: "salePriceGrowth",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const SalePricePerUnit = {
  id: "salePricePerUnit",
  label: "salePricePerUnit",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const Population = {
  id: "population",
  label: "population",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const VacancyRate = {
  id: "vacancyRate",
  label: "vacancyRate",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
};

const borrowerName = {
  id: "borrowerName",
  label: "borrowerName",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 4,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isDisabled: true,
};

const condoProjectName = {
  id: "condoProjectName",
  label: "condoProjectName",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 4,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const condoStreetAddress = {
  id: "condoStreetAddress",
  label: "condoStreetAddress",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 4,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const unit = {
  id: "unit",
  label: "unit",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 4,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const zip = {
  id: "zip",
  label: "zip",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 4,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const monthlyHOAFee = {
  id: "monthlyHOAFee",
  label: "monthlyHOAFee",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
};

const hoaDuesAboveFifteen = {
  id: "hoaDuesAboveFifteen",
  label: "hoaDuesAboveFifteen",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "No" },
    { label: "no", value: "Yes" },
  ],
};

const hoaDuesAboveTwenty = {
  id: "hoaDuesAboveTwenty",
  label: "hoaDuesAboveTwenty",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const reserveFundsForOneYear = {
  id: "reserveFundsForOneYear",
  label: "reserveFundsForOneYear",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const reserveFundsForTwoYears = {
  id: "reserveFundsForTwoYears",
  label: "reserveFundsForTwoYears",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const isProjectCompleted = {
  id: "isProjectCompleted",
  label: "isProjectCompleted",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const subjectToAdditionalPhasing = {
  id: "subjectToAdditionalPhasing",
  label: "subjectToAdditionalPhasing",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const soldPercentage = {
  id: "soldPercentage",
  label: "soldPercentage",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const hoaUnderOwnerControl = {
  id: "hoaUnderOwnerControl",
  label: "hoaUnderOwnerControl",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const simpleEstateOwnership = {
  id: "simpleEstateOwnership",
  label: "simpleEstateOwnership",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const ownerOccupiedUnits = {
  id: "ownerOccupiedUnits",
  label: "ownerOccupiedUnits",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const renterOccupiedUnits = {
  id: "renterOccupiedUnits",
  label: "renterOccupiedUnits",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const renterOccupiedPercentage = {
  id: "renterOccupiedPercentage",
  label: "renterOccupiedPercentage",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
};

const individualOwnershipAboveTwentyFive = {
  id: "individualOwnershipAboveTwentyFive",
  label: "individualOwnershipAboveTwentyFive",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const masterAssurancePolicy = {
  id: "masterAssurancePolicy",
  label: "masterAssurancePolicy",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const projectHasMobileHomes = {
  id: "projectHasMobileHomes",
  label: "projectHasMobileHomes",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const otherSourcesIncomeMoreThanTwenty = {
  id: "otherSourcesIncomeMoreThanTwenty",
  label: "otherSourcesIncomeMoreThanTwenty",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const commercialPurposesAreaMoreThanForty = {
  id: "commercialPurposesAreaMoreThanForty",
  label: "commercialPurposesAreaMoreThanForty",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const hoawithLitOrBankruptcy = {
  id: "hoawithLitOrBankruptcy",
  label: "hoawithLitOrBankruptcy",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const documentsWithSEC = {
  id: "documentsWithSEC",
  label: "documentsWithSEC",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const restrictOwnerToRent = {
  id: "restrictOwnerToRent",
  label: "restrictOwnerToRent",
  required: true,
  type: (loanType: string, dataView: string) => "radio",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
};

const ownerControlStartDate = {
  id: "ownerControlStartDate",
  label: "ownerControlStartDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const indivdualOwnedUnits = {
  id: "indivdualOwnedUnits",
  label: "indivdualOwnedUnits",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value) ? "enterValidNumber" : value < 0 ? "cannotBeNegative" : "",
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const hoawithLitOrBankruptcyDesc = {
  id: "hoawithLitOrBankruptcyDesc",
  label: "hoawithLitOrBankruptcyDesc",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const restrictOwnerToRentOutDesc = {
  id: "restrictOwnerToRentOutDesc",
  label: "restrictOwnerToRentOutDesc",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const mostRecentLeaseRentMonthly = {
  id: "mostRecentLeaseRentMonthly",
  label: "mostRecentLeaseRentMonthly",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isCurrency: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const rehabMarketData = [
  { ...MarketName, minimumDataView: true },
  { ...CapRate, minimumDataView: true },
  { ...TwelveMonthRentalGrowth, minimumDataView: true },
  { ...RentPerSqFt, minimumDataView: true },
  { ...RentPerUnit, minimumDataView: true },
  { ...SalePriceGrowth, minimumDataView: true },
  { ...SalePricePerUnit, minimumDataView: true },
  { ...Population, minimumDataView: true },
  { ...VacancyRate, minimumDataView: true },
];

const rehabSubMarketData = [
  { ...SubMarketName, minimumDataView: true },
  { ...CapRate, minimumDataView: true },
  { ...TwelveMonthRentalGrowth, minimumDataView: true },
  { ...RentPerSqFt, minimumDataView: true },
  { ...RentPerUnit, minimumDataView: true },
  { ...SalePriceGrowth, minimumDataView: true },
  { ...SalePricePerUnit, minimumDataView: true },
  { ...VacancyRate, minimumDataView: true },
];

const rehabLoanFeatures = [
  { ...OriginalAsIsLtv, minimumDataView: true },
  { ...originalAsRepairedLTV, minimumDataView: true },
  { ...OriginalLtc, minimumDataView: true },
];

const rehabLoanCharacerisation = [
  { ...CustomerTypeExperiencedNew, minimumDataView: true },
  { ...PurchaseOrRefinance, minimumDataView: true },
  { ...rehabType, minimumDataView: true },
  { ...StabilizedFlag, minimumDataView: true },
  { ...loanCharacterization, minimumDataView: true },
];

const groundUpLoanFeatures = [
  { ...AsIsLTV, minimumDataView: true },
  { ...LtcAsIs, minimumDataView: true },
  { ...ARVLTVAsCompleted, minimumDataView: true },
  { ...BlendedLTCAsCompleted, minimumDataView: true },
  { ...BlendedLTCwithFundedIRAsCompleted, minimumDataView: true },
];

const groundUpLoanCharacterisation = [
  { ...loanCharacterization, minimumDataView: true },
  { ...Tier, minimumDataView: true },
  {
    ...LoanPurpose,
    minimumDataView: true,
    required: false,
    type: (loanType: string, dataView: string) => "string",
    isAutoCalculated: (loanType: string, dataView?: string) => true,
  },
];

// Sub Components
const BorrowerInformation: any = {
  id: "borrowerInformation",
  label: "borrowerInformation",
  fields: [],
};

const LoanInformation: any = {
  id: "loanInformation",
  label: "loanInformation",
  fields: [],
};

const LoanEconomics: any = {
  id: "loanEconomics",
  label: "loanEconomics",
  fields: [],
};

const LoanFeatures: any = {
  id: "loanFeatures",
  label: "loanFeatures",
  fields: [],
};

const MarketData: any = {
  id: "marketData",
  label: "marketData",
  fields: [],
};

const SubMarketData: any = {
  id: "subMarketData",
  label: "subMarketData",
  fields: [],
};

const LoanCharacterisation: any = {
  id: "loanCharacterisation",
  label: "loanCharacterisation",
  fields: [],
};

const PropertyLocation: any = {
  id: "propertyLocation",
  label: "propertyLocation",
  fields: [],
};

const PropertyInformation: any = {
  id: "propertyInformation",
  label: "propertyInformation",
  fields: [],
};

const UnitInformation: any = {
  id: "unitInformation",
  label: "unitInformation",
  fields: [],
  buttons: [
    { label: "delete", icon: TrashIcon },
    { label: "addMore", icon: PlusIcon },
  ],
};

const PropertyEconomics: any = {
  id: "propertyEconomics",
  label: "propertyEconomics",
  fields: [],
};

// Main Component
const BorrowerDetails: any = {
  id: "borrowerDetails",
  label: "borrowerDetails",
  isExpanded: true,
  fields: [],
  showActionButtons: false,
  buttons: [],
};

const AutoCalculatedFieds: any = {
  id: "autoCalculatedFieds",
  label: "",
  isExpanded: true,
  fields: [],
  showActionButtons: false,
  buttons: [],
};

const LoanDetails: any = {
  id: "loanDetails",
  label: "loanDetails",
  isExpanded: true,
  fields: [],
  showActionButtons: false,
  buttons: [],
};

const PropertyDetails: any = {
  id: "propertyDetails",
  label: "propertyDetails",
  isExpanded: true,
  fields: [],
  showActionButtons: true,
  buttons: [
    { label: "deleteProperty", icon: TrashIcon },
    { label: "addProperty", icon: PlusIcon },
  ],
};

const loanConstants: any = {
  dataView: [
    {
      label: "minimumDataView",
      id: "minimumDataView",
      value: "minimumDataView",
      type: "button",
      isVisible: (loanType: string) => loanType === "InvestorDSCR",
    },
    {
      label: "fullDataView",
      id: "fullDataView",
      value: "fullDataView",
      type: "button",
      isVisible: (loanType: string) => loanType === "InvestorDSCR",
    },
  ],
  components: [],
};

const condoEligibilityQuestionnaire = {
  id: "toorakCondominimumQuestionnaire",
  label: "toorakCondominimumQuestionnaire",
  fields: [],
};

const RequestedLoanAmount = {
  id: "borrowerRequested",
  label: "requestedLoanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
};

const borrowerExperience: any = {
  id: "experiencedNew",
  label: "borrowerExperience",
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: false,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

const foreignNational = {
  id: "borrowerGuarantor",
  label: "foreignNational",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "No" },
    { label: "no", value: "Yes" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
};

export const TabLists = [
  { label: "leverageAndPricing", icon: DollarIcon, isVisible: () => true },
  { label: "tests", icon: TestsIcon, isVisible: (props: any) => props?.loanType !== "InvestorDSCR" || props?.fundingType === "table_funding" || !props?.allowPartnerSelection },
  { label: "availablePrograms", icon: CheckCircle, isVisible: (props: any) => (props?.loanType === "InvestorDSCR" && props?.fundingType !== "table_funding") && props?.allowPartnerSelection}
];

export const LeverageTableHeader = ["", "ltvAsIs"];

export const getExpandedViewFields = (loanType: string) => {
  switch (loanType) {
    case "DSCR":
      return [
        { ...RequestedLoanAmount, parentSection: "loanEconomics" },
        { ...RequestedAsIsLTV, parentSection: "loanFeatures" },
        { ...expandedInterestRate, parentSection: "loanEconomics" },
        { ...creditScore, parentSection: "borrowerInformation" },
        { ...expandedDSCR, parentSection: "loanEconomics" },
        { ...PrePaymentPenaltyTypeMonths, parentSection: "loanEconomics" },
        { ...PrePaymentPenaltyType, parentSection: "loanEconomics" },
        { ...ioPeriod, parentSection: "loanEconomics" },
        { ...annualTaxes, parentSection: "propertyEconomics" },
        { ...expandedOccupancy, parentSection: "loanEconomics" },
        { ...monthlyPropertyGrossRent, parentSection: "propertyInformation" },
        { ...NoOfProperties, parentSection: "loanInformation" },
        { ...noOfUnits, parentSection: "propertyInformation" }
      ];
    case "BridgeFix&Flip":
    case "BridgeStabilized":
      return [
        { ...borrowerExperience, parentSection: "borrowerInformation" },
        { ...foreignNational, parentSection: "borrowerInformation" },
        { ...originalCreditScore, parentSection: "borrowerInformation" },
        { ...ExitStrategy, data: bridgeGenExitStrategyDropDownValues, parentSection: "loanInformation" },
        { ...pledgeOfEquity, parentSection: "loanInformation" },
        { ...InitialLoanAmount, parentSection: "loanEconomics" },
        { ...TotalRehabBudget, parentSection: "loanEconomics" },
        { ...TotalOrgAndDiscountPoints, parentSection: "loanEconomics" },
        { ...city, isDisabled: false, parentSection: "propertyLocation" },
        { ...state, isDisabled: false, parentSection: "propertyLocation" },
        { ...postalCode, parentSection: "propertyLocation" },
        { ...noOfUnits, parentSection: "propertyInformation" },
        { ...ExpansionOrChangeOfUseCase, parentSection: "propertyInformation" },
        { ...ClosingCost, parentSection: "propertyEconomics" },
        { ...AsRepairedValueARV, parentSection: "propertyEconomics" },
        { ...grossPotentialRentMonthly, parentSection: "propertyEconomics" },
        { ...AssignmentFee, parentSection: "propertyEconomics" },
        // NoOfProperties, // not present for bridge loans
        // CrossCollaterlization // not present for bridge loans
      ];
    case "BridgeMultiFamily":
      return [
        { ...borrowerExperience, parentSection: "borrowerInformation" },
        { ...foreignNational, parentSection: "borrowerInformation" },
        { ...originalCreditScore, parentSection: "borrowerInformation" },
        { ...ExitStrategy, data: bridgeGenExitStrategyDropDownValues, parentSection: "loanInformation" },
        { ...LoanTerm, parentSection: "loanInformation" },
        { ...InitialLoanAmount, parentSection: "loanEconomics" },
        { ...hardCosts, parentSection: "loanEconomics" },
        { ...softCostsCap15Rehab, parentSection: "loanEconomics" },
        { ...exitDebt, parentSection: "loanEconomics" },
        { ...TotalOrgAndDiscountPoints, parentSection: "loanEconomics" },
        { ...interestRate, parentSection: "loanEconomics" },
        { ...city, isDisabled: false, parentSection: "propertyLocation" },
        { ...state, isDisabled: false, parentSection: "propertyLocation" },
        { ...postalCode, parentSection: "propertyLocation" },
        { ...noOfUnits, parentSection: "propertyInformation" },
        { ...ExpansionOrChangeOfUseCase, parentSection: "propertyInformation" },
        { ...AsRepairedValueARV, parentSection: "propertyEconomics" },
        { ...grossPotentialRentMonthly, parentSection: "propertyEconomics" },
        { ...appraisalStabilizedNOI, parentSection: "propertyEconomics" },
        { ...annualPropertyTaxes, parentSection: "propertyEconomics" },
        { ...annualInsurance, parentSection: "propertyEconomics" },
        // NoOfProperties, // not present for bridge loans
        // CrossCollaterlization, // not present for bridge loans
      ];
    case "BridgeGroundUp":
      return [
        { ...borrowerGUCExperience, parentSection: "borrowerInformation" },
        { ...foreignNational, parentSection: "borrowerInformation" },
        { ...borrowerCreditScore, parentSection: "borrowerInformation" },
        { ...heavyRehabExperience, parentSection: "borrowerInformation" },
        { ...ExitStrategy, data: bridgeGroundUpExitStrategyDropDownValues, parentSection: "loanInformation" },
        { ...LoanTerm, parentSection: "loanInformation" },
        { ...permitApprovalsAndFullPlans, parentSection: "loanInformation" },
        { ...InitialLoanAmount, parentSection: "loanInformation" },
        { ...hardCosts, parentSection: "loanEconomics" },
        { ...softCosts, parentSection: "loanEconomics" },
        { ...InterestReserve, parentSection: "loanEconomics" },
        { ...orgBrokerFee, parentSection: "loanEconomics" },
        { ...interestRate, parentSection: "loanEconomics" },
        { ...city, isDisabled: false, parentSection: "propertyLocation" },
        { ...state, isDisabled: false, parentSection: "propertyLocation" },
        { ...postalCode, parentSection: "propertyLocation" },
        { ...noOfUnits, parentSection: "propertyInformation" },
        { ...propertyPurchasedWithinLast2Years, parentSection: "propertyInformation" },
        { ...entitledAsOfRightDevelopment, parentSection: "propertyInformation" },
        { ...ClosingCost, parentSection: "propertyEconomics" },
        { ...afterRepairValue, parentSection: "propertyEconomics" },
        { ...marketRentPerMonth, parentSection: "propertyEconomics" },
        { ...AssignmentFee, parentSection: "propertyEconomics" },
        // NoOfProperties, // not present for bridge loans
        // CrossCollaterlization // not present for bridge loans
      ];
    default:
      return [];
  }
};

export const getConfig = (
  loanType: string,
  defaultSelectedDataView: string,
  templateLabels: any,
  createdOn: any,
  propType?: string | undefined
) => {
  let propAutoCalculateFields = [];
  let loanFeatures = {};
  const AutoCalculatedFields = {};

  switch (loanType) {
    case "DSCR":
      BorrowerInformation.fields = [
        BorrowerGuarantor,
        { ...ImmigrationStatus },
        BorrowerType,
        creditScoreActual,
      ];
      LoanInformation.fields = [
        OriginatorLoanID,
        ExpectedDateOfClosing,
        { ...BorrowerProceeds, label: "cashOutRefinance" },
        NoOfProperties,
        PredominantState,
        PredominantPropertyType,
        moment(latestVersioningDate, "DD-MM-YYYY").isSameOrBefore(moment(createdOn)) ? CondoEligiBilityV2 : CondoEligiBility,
        { ...LoanTerm, minimumDataView: false },
        {
          ...LoanPurpose,
          minimumDataView: true,
          required: true,
          isVisible: true,
          type: (loanType: string, dataView: string) => dataView === "fullDataView" ? "string" : "dropDown",
          isAutoCalculated: (loanType: string, dataView?: string) => dataView === "fullDataView",
        },
        // PrimaryLoanId,
        CrossCollaterlization,
        // OriginalDateOfAcquisition,
        CreditEvent,
      ];

      LoanEconomics.fields = [
        { ...BorrowerRequested, fieldLabel: 'loanAmount' },
        { ...OrgInterestRate, fieldLabel: "dscrInterestRate" },
        PrePaymentPenaltyTypeMonths,
        PrePaymentPenaltyType,
        rateType,
        InterestOnlyFlag,
        // interestOnlyPeriod,
        LoanLevelOccupancyPercentage,
        // OrgLoanAmount,
        DSCR,
        // RequestedAsIsLTV,
        ioPeriod,
        // requestedLTPP,
      ];
      LoanFeatures.fields = [
        { ...OriginalAsIsLtv, minimumDataView: true },
        OriginalLtcLtp,
      ];
      LoanCharacterisation.fields = [
        { ...ToorakFICObucket, label: templateLabels.toorakFICObucket },
        { ...toorakLoanType, label: templateLabels?.toorakLoanType },
        FinalLoanCharacterization,
      ];
      PropertyLocation.fields = [address, postalCode, city, state];
      PropertyInformation.fields = [
        propertySqftFootage,
        { ...propertyType, data: predominantPropType, required: false },
        OriginalDateOfAcquisition,
        recentPropValuationDate,
        PropertyWithDebt,
        noOfUnits,
        precentOccupied,
        monthlyPropertyGrossRent,
        { ...rentalCharacterization, minimumDataView: true, isVisible: true },
      ];
      UnitInformation.fields = [
        currentLeaseTermsInMonths,
        rentalCharacterization,
        monthlyMarketRent,
        currentUsage,
        tenantOccupancy,
        operatingHistory,
        // monthlyUnitRent,
        inPlaceLeaseRentMonthly,
        percentUnitOccupied,
        // mostRecentLeaseRentMonthly,
      ];
      PropertyEconomics.fields = [
        asIsValue,
        thirdPartyValuationStatus,
        thirdPartyPopertyValue,
        propertyAcquisitionPrice,
        { ...annualPropertyTaxes, minimumDataView: false },
        annualHazardInsurance,
        annualFloodInsurance,
        annualHOAFee,
        DecliningMarkets,
      ];

      BorrowerDetails.fields = [BorrowerInformation];
      LoanDetails.fields = [
        LoanInformation,
        LoanEconomics,
      ];
      PropertyDetails.fields = [
        PropertyLocation,
        PropertyInformation,
        [UnitInformation],
        PropertyEconomics,
      ];

      loanFeatures = {
        ...LoanFeatures,
        fields: [ESRIPopulationDensity, ZillowCBSA, AdjustedAsIsValue],
      }
      propAutoCalculateFields = [{ loanFeatures }];

      AutoCalculatedFieds.fields = [LoanFeatures, LoanCharacterisation, propAutoCalculateFields]

      loanConstants.components = [
        BorrowerDetails,
        PropertyDetails,
        LoanDetails,
        AutoCalculatedFieds
      ];
      return loanConstants;
    case "BridgeRehab":
    case "BridgeFix&Flip":
    case "BridgeStabilized":
      BorrowerInformation.fields = [
        propertiesCompletedWithinLast36Months,
        BorrowerGuarantor,
        { ...ImmigrationStatus },
        originalCreditScore,
        experiencedNew,
        InState
      ];
      LoanInformation.fields = [
        OriginatorLoanID,
        { ...LoanStructure, data: bridgeRehabLoanStructureDropDown },
        { ...LoanPurpose, data: bridgeLoanPuposeDropDownValues },
        CashOutFlag,
        { ...ExitStrategy, data: bridgeGenExitStrategyDropDownValues },
        pledgeOfEquity,
      ];
      LoanEconomics.fields = [
        InitialLoanAmount,
        OrgInterestRate,
        TotalRehabBudget,
        // orgBrokerFee, // confirmed from Ravi
        financedBudget,
        // totalBudget,
        includeOutOfPocketBudgetARLTV,
        TotalOrgAndDiscountPoints,
        // brokerFee
      ];
      LoanFeatures.fields = rehabLoanFeatures;
      LoanCharacterisation.fields = [
        { ...CustomerTypeExperiencedNew, minimumDataView: true },
        { ...PurchaseOrRefinance, minimumDataView: true },
        { ...rehabType, minimumDataView: true },
        { ...StabilizedFlag, minimumDataView: true },
        { ...loanCharacterization, minimumDataView: true },
      ];
      PropertyLocation.fields = [
        // propertyType,
        address,
        postalCode,
        city,
        state,
        // noOfUnits,
      ];
      PropertyInformation.fields = [
        { ...propertyType, data: predominantPropType },
        noOfUnits,
        ExpansionOrChangeOfUseCase,
        ValuationSource,
        PercentOccupied,
      ];
      UnitInformation.fields = [];
      PropertyEconomics.fields = [
        orgAppraisalValue,
        purchasePrice,
        ClosingCost,
        AsRepairedValueARV,
        { ...EstimatedRentPerYearUponCompletion, isVisible: true },
        costBasis,
        { ...AssignmentFee, ifApplicable: true },
        annualPropertyTaxes,
        annualInsurance,
        annualHOAFee,
      ];

      BorrowerDetails.fields = [BorrowerInformation];
      LoanDetails.fields = [
        LoanInformation,
        LoanEconomics,
      ];
      PropertyDetails.fields = [
        PropertyLocation,
        PropertyInformation,
        UnitInformation,
        PropertyEconomics,
      ];
      loanFeatures = {
        ...LoanFeatures,
        fields: [
          { ...cbsa, minimumDataView: true },
          { ...MedianValue, minimumDataView: true },
          { ...LtmHpa, minimumDataView: true },
          { ...DaysOnMarket, minimumDataView: true },
          { ...ESRIPopulationDensity, minimumDataView: true, displayLabel: "zipCodeDensity" },
        ],
      }
      propAutoCalculateFields = [{ loanFeatures }];

      AutoCalculatedFieds.fields = [LoanFeatures, LoanCharacterisation, propAutoCalculateFields]
      loanConstants.components = [BorrowerDetails, PropertyDetails, LoanDetails, AutoCalculatedFieds];
      return loanConstants;
    case "BridgeGroundUp":
      BorrowerInformation.fields = [
        borrowerGUCExperience,
        heavyRehabExperience,
        BorrowerGuarantor,
        { ...ImmigrationStatus },
        originalCreditScore,
      ];
      LoanInformation.fields = [
        OriginatorLoanID,
        LoanStructure,
        CashOutFlag,
        { ...ExitStrategy, data: bridgeGroundUpExitStrategyDropDownValues },
        LoanTerm,
        irFundingSource,
        permitApprovalsAndFullPlans,
        // propertyPurchasedWithinLast2Years,
        // marketRentPerMonth,
        // LoanPurpose,
        // pledgeOfEquity,
      ];
      LoanEconomics.fields = [
        hardCosts,
        softCosts,
        InitialLoanAmount,
        financedBudget,
        // RehabLoanAmount,
        InterestReserve,
        orgBrokerFee,
        interestRate,
        {
          ...totalBudget,
          minimumDataView: true,
          isAutoCalculated: (loanType: string, dataView?: string) => true,
        },
        // brokerFee
        // includeOutOfPocketBudgetARLTV,
      ];
      LoanFeatures.fields = groundUpLoanFeatures;
      LoanCharacterisation.fields = groundUpLoanCharacterisation;
      PropertyLocation.fields = [address, postalCode, city, state];
      PropertyInformation.fields = [
        propertyType,
        noOfUnits,
        ValuationSource,
        propertyPurchasedWithinLast2Years,
        entitledAsOfRightDevelopment,
      ];
      PropertyEconomics.fields = [
        { ...asIsValue, minimumDataView: true },
        purchasePrice,
        ClosingCost,
        { ...afterRepairValue, minimumDataView: true },
        AssignmentFee,
        grossPotentialRentMonthly,
      ];
      UnitInformation.fields = [];

      BorrowerDetails.fields = [BorrowerInformation];
      LoanDetails.fields = [
        LoanInformation,
        LoanEconomics,
      ];
      PropertyDetails.fields = [
        PropertyLocation,
        PropertyInformation,
        UnitInformation,
        PropertyEconomics,
      ];
      loanFeatures = {
        ...LoanFeatures,
        fields: [
          { ...cbsa, minimumDataView: true },
          { ...MedianValue, minimumDataView: true },
          { ...LtmHpa, minimumDataView: true },
          { ...DaysOnMarket, minimumDataView: true },
        ],
      }
      propAutoCalculateFields = [{ loanFeatures }];

      AutoCalculatedFieds.fields = [LoanFeatures, LoanCharacterisation, propAutoCalculateFields]
      loanConstants.components = [BorrowerDetails, PropertyDetails, LoanDetails, AutoCalculatedFieds];
      return loanConstants;
    case "BridgeMultiFamily":
      BorrowerInformation.fields = [
        propertiesCompletedWithinLast36Months,
        BorrowerGuarantor,
        { ...ImmigrationStatus },
        originalCreditScore,
      ];
      LoanInformation.fields = [
        OriginatorLoanID,
        { ...LoanStructure, data: bridgeRehabLoanStructureDropDown },
        {
          ...LoanPurpose,
          data: bridgeLoanPuposeDropDownValues,
          minimumDataView: true,
        },
        CashOutFlag,
        recourse,
        { ...ExitStrategy, data: bridgeGenExitStrategyDropDownValues },
        LoanTerm,
        requiredInterestReserve,
        RequiredInterestReserveMonths,
      ];
      LoanEconomics.fields = [
        exitDebt,
        hardCosts,
        softCostsCap15Rehab,
        {
          ...TotalRehabBudget,
          minimumDataView: true,
          isAutoCalculated: (loanType: string, dataView?: string) => true,
        },
        InitialLoanAmount,
        { ...TotalOrgAndDiscountPoints, minimumDataView: true },
        interestRate,
        // orgBrokerFee,
        // totalBudget,
        financedBudget,
        includeOutOfPocketBudgetARLTV,
        // brokerFee
      ];
      LoanFeatures.fields = rehabTypes.includes(
        propType as string
      )
        ? rehabLoanFeatures
        : groundUpLoanFeatures;
      LoanCharacterisation.fields = rehabTypes.includes(
        propType as string
      )
        ? rehabLoanCharacerisation
        : groundUpLoanCharacterisation;
      PropertyLocation.fields = [address, postalCode, city, state];
      PropertyInformation.fields = [
        { ...propertyType, data: propertyTypeDropDownValues },
        noOfUnits,
        ExpansionOrChangeOfUseCase,
        { ...ValuationSource, data: bridgeGroundUpValutionSource },
        PercentOccupied,
      ];
      PropertyEconomics.fields = [
        orgAppraisalValue,
        purchasePrice,
        { ...borrowerPaidCostToDate, minimumDataView: true },
        { ...AsRepairedValueARV, required: false },
        { ...EstimatedRentPerYearUponCompletion, isVisible: true },
        appraisalStabilizedNOI,
        annualPropertyTaxes,
        costBasis,
        grossCondoSelloutValue,
        { ...AssignmentFee, minimumDataView: true },
        purchaseDate,
        annualInsurance,
        annualHOAFee,
      ];
      UnitInformation.fields = [];
      MarketData.fields = rehabTypes.includes(
        propType as string
      )
        ? rehabMarketData
        : [];
      SubMarketData.fields = rehabTypes.includes(
        propType as string
      )
        ? rehabSubMarketData
        : [];

      BorrowerDetails.fields = [BorrowerInformation];
      LoanDetails.fields = [
        LoanInformation,
        LoanEconomics,
      ];
      PropertyDetails.fields = [
        PropertyLocation,
        PropertyInformation,
        UnitInformation,
        PropertyEconomics,
      ];
      loanFeatures = {
        ...LoanFeatures,
        fields: [
          { ...cbsa, minimumDataView: true },
          { ...MedianValue, minimumDataView: true },
          { ...LtmHpa, minimumDataView: true },
          { ...DaysOnMarket, minimumDataView: true },
        ]
      }
      propAutoCalculateFields = [
        {
          loanFeatures,
          MarketData,
          SubMarketData,
        }
      ];

      AutoCalculatedFieds.fields = [LoanFeatures, LoanCharacterisation, propAutoCalculateFields]
      loanConstants.components = [BorrowerDetails, PropertyDetails, LoanDetails, AutoCalculatedFieds];
      return loanConstants;
  }
};

export const getCondoEligibilityFields = () => {
  return {
    fields: {
      ["borrowerDetails"]: [
        borrowerName,
        condoProjectName,
        condoStreetAddress,
        unit,
        { ...city, columnSplit: 4, required: false, isDisabled: false },
        { ...state, columnSplit: 4, required: false, isDisabled: false },
        zip,
      ],
      ["fee"]: [
        monthlyHOAFee,
        hoaDuesAboveFifteen,
        hoaDuesAboveTwenty,
        reserveFundsForOneYear,
        reserveFundsForTwoYears,
        isProjectCompleted,
        subjectToAdditionalPhasing,
        soldPercentage,
        hoaUnderOwnerControl,
        simpleEstateOwnership,
        ownerControlStartDate,
        ownerOccupiedUnits,
        renterOccupiedUnits,
        renterOccupiedPercentage,
        individualOwnershipAboveTwentyFive,
        masterAssurancePolicy,
        indivdualOwnedUnits,
        projectHasMobileHomes,
        otherSourcesIncomeMoreThanTwenty,
        commercialPurposesAreaMoreThanForty,
        hoawithLitOrBankruptcy,
        documentsWithSEC,
        hoawithLitOrBankruptcyDesc,
        restrictOwnerToRent,
        restrictOwnerToRentOutDesc,
      ],
    },
    buttons: [
      { label: "calculateEligibilitySave", type: "contained", gridSize: 3 },
      { label: "download", type: "outlined", gridSize: 2 },
      { label: "cancel", type: "outlined", gridSize: 2 },
    ],
  };
};
