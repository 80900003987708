import React from "react";

import Chip from "@mui/material/Chip";

import { ChipType } from "../../types/componentTypes";

export default function CustomChip(props: ChipType) {
  const { size, label, variant, className } = props;
  return (
    <Chip size={size} label={label} variant={variant} className={className} />
  );
}
