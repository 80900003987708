import React, { useContext, useEffect } from "react";
import Loader from "../components/common/Loader";
import jwt_decode from "jwt-decode";
import { getCookie, setCookie } from "../utils/Common";
import { RootContext } from "./RootContext";
import { ObjectType } from "../types/tableTypes";
import { permissions } from "../pages/EvaluateLoans/evaluateConstant";
import { customerData, fetchPartnerMap, getToken, orgBucketResponse } from "../services/network";
import short from "short-uuid";
import { delay } from "../utils/utility";

export const AuthLayer = (props: any) => {
  const {
    setDecodedTokenData,
    setUserPermissions,
    setDynamicLoader,
    dynamicConfig,
    orgData,
    setUserLoggedIn,
    setDscrPartner,
    setDSCROrgPartnerMapping,
    setTemplateLabels,
    userPermissions,
    setShowTermSheet,
    setShowKeyDealMetrics,
    setLoanTypes,
    setOrgData
  } = useContext(RootContext);

  const isAuthenticated = Boolean(getCookie("sizer_access_token"));
  let originatorData: any = getCookie("originatorData")
  originatorData = !["undefined", "null"].includes(originatorData) && JSON.parse(originatorData) || orgData;

  // const redirectLocation = window.location.pathname;
  const redirect_URI = "/evaluate-loan";
  // window?.location?.pathname === "/" ? "/evaluate-loan" : redirectLocation;

  useEffect(() => {
    const sessionToken: any = getCookie("sizer_access_token");
    const code: any = getCookie("code");
    const getToken = async () => {
      setCookie("fetchingToken", true);
      await generateToken();
      setCookie("fetchingToken", false);
    };
    if (!sessionToken && code && getCookie("fetchingToken") !== "true") {
      getToken();
    }
    const data = getCookie("loanTypes");
    const tempOrgData = getCookie("originatorData") || localStorage?.getItem("originatorData") || "{}";
    if (data) {
      setLoanTypes(JSON.parse(data));
    }
    if (!["undefined", "null"].includes(tempOrgData)) setOrgData(JSON.parse(tempOrgData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCreditLineData() {
    fetchPartnerMap()
      .then((res) => {
        setDscrPartner(res.data);
        const partnersData: any = {};
        res?.data?.map((creditLineData: any) => {
          partnersData[creditLineData?.id] = creditLineData?.programName
        });
        setDSCROrgPartnerMapping(partnersData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (isAuthenticated) {
      getCreditLineData();
    }
    if (isAuthenticated && userPermissions?.length) {
      let originatorData: any = getCookie("originatorData") || localStorage?.getItem("originatorData") || "{}";
      originatorData = !["undefined", "null"].includes(originatorData) && JSON.parse(originatorData) || orgData;
      const showTermSheetData = userPermissions.includes("generate_term_sheet") && originatorData?.accountType === "ORIGINATOR"
      setShowTermSheet(showTermSheetData);
      const decodedToken: any = jwt_decode(getCookie("sizer_access_token") || "");
      setDecodedTokenData(decodedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userPermissions, orgData])

  async function getCustomerData(token: any) {
    try {
      const decodedToken: any = jwt_decode(token);
      const decodedEmail = decodedToken.email;
      const { data } = await customerData(decodedEmail);
      const bucketPartyId = data.partyId;
      const orgBucketResp = await orgBucketResponse(bucketPartyId);
      const orgData: any = {};
      let orgId: string = "";
      if (orgBucketResp?.data?.accounts?.length) {
        const orgList: any[] = orgBucketResp.data.accounts.filter(
          (cObj: any) => cObj.accountType === "ORIGINATOR"
        );
        const isLATUser = orgBucketResp.data.accounts.find(
          (cObj: any) => cObj.accountType === "LAT"
        );
        if(isLATUser){
          setShowKeyDealMetrics(true)
          setCookie("latData", JSON.stringify(isLATUser))
          localStorage.setItem("latData", JSON.stringify(isLATUser));
        }
        orgId = orgList[0]?.partyId;
        setCookie("originatorData", JSON.stringify(orgList[0]));
        localStorage.setItem("originatorData", JSON.stringify(orgList[0]));
        setOrgData(orgList[0]);
      }
      const loanTypes = orgBucketResp?.data?.originatorAccountMap?.loanTypes?.map((loanType: any) => loanType?.loanTypeName);

      setDecodedTokenData(decodedToken);
      setCookie("partyId", data?.partyId ?? dynamicConfig.partyId);
      setCookie("orgId", orgId || dynamicConfig.clientId);
      setCookie("originatorAccountMap", JSON.stringify(orgBucketResp?.data?.originatorAccountMap));
      localStorage.setItem("originatorAccountMap", JSON.stringify(orgBucketResp?.data?.originatorAccountMap));
      setCookie("orgFundingType", orgBucketResp?.data?.originatorAccountMap?.fundingType);
      setCookie("loanTypes", JSON.stringify(loanTypes));
      setLoanTypes(loanTypes);
      setOrgData(orgData);
      if (orgBucketResp?.data?.originatorAccountMap?.category) {
        setCookie(
          "orgBucketCategory",
          orgBucketResp?.data?.originatorAccountMap?.category ?? null
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
      setCookie("fetchingToken", false);
    }
  }

  async function generateToken() {
    setDynamicLoader(true);
    if (!dynamicConfig) return;
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      const body = {
        client_id: dynamicConfig.ViteAppClientId,
        grant_type: "authorization_code",
        code: getCookie("code"),
        redirect_uri: `${dynamicConfig?.ViteAppHostRedirectUri}${location.pathname}`,
      };
      const response = await getToken(body, headers, dynamicConfig);

      if (response?.data && response?.data?.access_token) {
        // set token to the cookie
        const token = response?.data?.access_token;
        const decodedToken: ObjectType = jwt_decode(token);

        const tempObj: string[] = [];
        decodedToken?.realm_access?.roles?.forEach((role: string) => {
          if (permissions.includes(role)) {
            tempObj.push(role);
          }
        });

        setUserPermissions(tempObj);
        setUserLoggedIn(true);
        setCookie("userPermissions", JSON.stringify(tempObj));
        setCookie("sizer_access_token", response?.data?.access_token);
        setCookie("sizer_access_expiry", response?.data?.expires_in);
        setCookie("sizer_refresh_token", response?.data?.refresh_token);
        setCookie("sizer_refresh_expiry", response?.data?.refresh_expires_in);
        delay(1);
        await getCustomerData(response?.data?.access_token);
      } else {
        console.error("Invalid response data.");
      }
    } catch (error) {
      console.error("Error generating token:", error);
    } finally {
      setDynamicLoader(false);
    }
  }

  if (!isAuthenticated && !getCookie("code")) {
    const translator = short();
    const stateUUID = translator.uuid();
    const nonceUUID = translator.uuid();
    window.location.replace(
      `${dynamicConfig?.ViteAppRealms}/auth?client_id=${dynamicConfig?.ViteAppClientId}&redirect_uri=${dynamicConfig?.ViteAppHostRedirectUri}${redirect_URI}&state=${stateUUID}&nonce=${nonceUUID}&response_mode=query&response_type=code&scope=openid`
    );
  }

  if (getCookie("fecthingToken")) {
    return <Loader isOpen={true} />;
  }
  return props.children;
};