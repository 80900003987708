import { newLoanTypes } from "../../utils/Common";

export const dscrPropertyType = {
  title: "Property Type",
  key: "propertyType",
  fields: [
    {
      key: "singleFamilyDetatchedText",
      label: "singleFamilyDetatchedText",
      value: "Single Family/Detached",
      type: "button",
    },
    {
      key: "24UnitText",
      label: "24UnitText",
      value: "2-4 Unit",
      type: "button",
    },
    {
      key: "condoText",
      label: "condoText",
      value: "Condo",
      type: "button",
    },
    {
      key: "townHomeText",
      label: "townHomeText",
      value: "Towhome",
      type: "button",
    },
  ],
};

export const bridgePropertyType = {
  title: "Property Type",
  key: "propertyType",
  fields: [
    {
      key: "singleFamilyDetatchedText",
      label: "singleFamilyDetatchedText",
      value: "Single Family/Detached",
      type: "button",
    },
    {
      key: "24UnitText",
      label: "24UnitText",
      value: "2-4 Unit",
      type: "button",
    },
    {
      key: "condoText",
      label: "condoText",
      value: "Condo",
      type: "button",
    },
    {
      key: "multiFamilyMixedUseText",
      label: "multiFamilyMixedUseLabel",
      value: "Multifamily/MixedUse",
      type: "button"
    },
    {
      key: "townHomeText",
      label: "townHomeText",
      value: "Towhome",
      type: "button",
    },
  ],
};

export const loanType = {
  title: "Loan Type",
  key: "loanType",
  fields: [
    {
      key: "fixFlipText",
      label: "fixFlipText",
      value: "Fix & Flip",
      type: "button",
    },
    {
      key: "stabilizedText",
      label: "stabilizedText",
      value: "Stabilized",
      type: "button",
    },
    {
      key: "groundUpText",
      label: "groundUpText",
      value: "Ground Up",
      type: "button",
    },
  ]
};

export enum RolesType {
  createDSCR = "create_dscr",
  createBridge = "create_bridge",
  submitDSCR = "submit_dscr",
  submitBridge = "submit_bridge",
  lat = "lat"
}

export const permissions: string[] = [
  "submit_bridge",
  "submit_dscr",
  "create_dscr",
  "create_bridge",
  "lat",
  "generate_term_sheet",
  "view_term_sheets"
];

// TODO: Add the following to the productTypeConfig function when using the roles from account map data
// disable: isOriginator ? !roles?.includes(newLoanTypes?.dscrLoan) && !userPermissions?.includes("create_dscr") : !userPermissions?.includes("create_dscr"),
// disable: isOriginator ? !roles.includes(newLoanTypes?.bridgeLoan) && !userPermissions.includes("create_bridge") : !userPermissions.includes("create_bridge"),

export const productTypeConfig = (roles: string[], isOriginator?: boolean, userPermissions?: any) => [
  {
    field: "Bridge Loan",
    label: "bridgeLoanLabel",
    key: "bridgeLoan",
    type: "button",
    disable: !roles.includes("create_bridge"),
    subFields: [loanType, bridgePropertyType],
  },
  {
    field: "Investor DSCR",
    label: "investorDSCR",
    key: "investorDSCR",
    type: "button",
    disable: !roles?.includes("create_dscr"),
    subFields: [dscrPropertyType],
  },
];
