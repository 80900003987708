
import React, { useState } from "react";

import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import CollapseIcon from "../../assets/CollapseIcon.svg";
import ExpandIcon from "../../assets/ExpandIcon.svg";

import { checkIconButtonDisabled, handleIconButtonClick, renderSubComponentUI } from "../../pages/Loans/Common";
import { ObjectType } from "../../types/tableTypes";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "6px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function AccordionUI(props: ObjectType) {
  const {
    component,
    idx,
    label,
    data,
    expandLeverageAndTests,
    isApplicationSubmitted,
    loanType,
    translation,
    theme,
    loanContextData
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const transLabel = translation(label);
  return (
    <Accordion
      key={idx}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      data-testid="accordion-main"
    >
      {!expandLeverageAndTests && (
        <AccordionSummary
          expandIcon={<img src={isExpanded ? CollapseIcon : ExpandIcon} />}
        >
          <div>
            <h3>{transLabel}</h3>
          </div>
          <div>
            {component.showActionButtons ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                {component?.buttons?.map((ele: ObjectType, index: number) => {
                  return (
                    <IconButton
                      data-testid="accordion-button"
                      key={index}
                      disabled={checkIconButtonDisabled(
                        component,
                        ele.label,
                        isApplicationSubmitted,
                        data?.length
                      )}
                      onClick={() =>
                        handleIconButtonClick(
                          ele.label,
                          idx,
                          loanContextData
                        )
                      }
                      sx={{
                        borderRadius: "0% !important;",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>{translation(ele.label)}</span>
                      &ensp;
                      <img src={ele.icon} alt="button-icon" />
                      &ensp;
                      {index === 0 ? "| " : null}
                    </IconButton>
                  );
                })}
              </div>
            ) : null}
          </div>
        </AccordionSummary>
      )}
      {isExpanded ? (
        <AccordionDetails>
          {component?.fields?.map((ele: ObjectType, index: number) => {
            if (Array.isArray(data)) {
              if (Array.isArray(ele)) {
                return (
                  <div key={index} style={{ margin: "48px 0px 0px 12px" }}>
                    {renderSubComponentUI(
                      ele,
                      index,
                      data[idx]?.[ele[0]?.label],
                      component,
                      loanType,
                      theme,
                      translation,
                      loanContextData,
                      idx,
                    )}
                  </div>
                );
              }
              return (
                <div key={index} className="mt-48 ml-12">
                  {renderSubComponentUI(
                    ele,
                    index,
                    data[idx]?.[ele?.label],
                    component,
                    loanType,
                    theme,
                    translation,
                    loanContextData,
                    idx,
                  )}
                </div>
              );
            } else {
              return (
                <div key={index} className="mt-48 ml-12">
                  {renderSubComponentUI(
                    ele,
                    index,
                    data?.[ele?.label],
                    component,
                    loanType,
                    theme,
                    translation,
                    loanContextData,
                    idx,
                  )}
                </div>
              );
            }
          })}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
}
