import React from 'react'
import { Box, Grid, Popover, Typography } from "@mui/material";

type KDMPopOverPropTypes = {
    popOverData: any
    redirectionType: string | undefined
    icons: any
    openGoogleMap: (value: any) => void
}

const KDMPopOver = (props: KDMPopOverPropTypes ) => {
    const { 
        popOverData, 
        redirectionType, 
        icons,
        openGoogleMap
    } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

  return (
    <div>
        <img src={icons.InfoIcon} style={{cursor: "pointer"}} onClick={handleClick}/>
        <Popover
            id={"kdm-pop-over"}
            open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
      style={{ zIndex: 9999 }}
    >
      <Box >
        {popOverData?.map((ele: any, index: number) => {
          return (
            <Grid
              container
              justifyContent="space-between"
              key={ele}
              sx={{padding: "4px 4px 0px 4px"}}
            >
              <Typography
                style={{
                  color: redirectionType ? "#5e72e4" : "#000",
                  cursor: redirectionType ? "pointer" : "default",
                  fontWeight: "bold",               
                  justifyContent: "space-between",
                    fontSize: 12,
                    lineHeight: 1.83,
                    wordBreak: "break-all",
                    display: "flex",
                    padding: "2px 8px"
                }}
                onClick={() => redirectionType === "googleMap" && openGoogleMap(ele)}
              >
                {ele}
              </Typography>
            </Grid>
          );
        })}
      </Box>
    </Popover>
      
    </div>
  )
}

export default KDMPopOver
