import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MuiTable from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TestTabResultView from "./TestTabResultView";

export default function PartnerAndProgrammeSelection(props: any) {
  const {
    header,
    showSwitchTab,
    buttonGroupClasses,
    handleViewChange,
    view,
    views,
    tablesContents,
    EligiblePartnersTableRow,
    CustomTable,
    TestResultComponent,
    TestResultData,
    tableHeadCss,
    pricingAdjustmentsColsData,
    pricingAdjustmentsCols,
    finalPricingColsData,
    finalPricingCols,
  } = props;

  const {
    testTabData,
    isExpanded,
    expandLeverageAndTests,
    setIsExpanded,
    loanDetails,
    isDSCR,
    ratesAsOfDate,
    eligiblePartnerResults,
    changePartner,
    CustomCard,
    CustomChip,
    DSCROrgPartnerMapping,
    DownArrow,
    InfoIcon,
    NonCriticalTestIcon,
    CriticalTestIcon,
    isApplicationSubmitted,
    CheckCirclePass,
    gridTemplate,
    eligiblePartnersColumns,
    takeOutPartnerLists,
    isLATUser,
    LoaderSvg,
    ineligiblePartners,
    filteredPartners,
    showPricingOnlyForEligible,
    tableWidth,
    showRadioSelection
  } = TestResultData;

  return (
    <Grid container sx={{ display: "block" }}>
      {header ?
        <Grid item xs={6}>
          <Typography>{header}</Typography>
        </Grid>
        : null
      }
      <Grid item xs={6} style={{ margin: "16px" }}>
        <ButtonGroup>
          {views.map((cView: any) => {
            return <Button
              variant={view === cView.key ? "contained" : "outlined"}
              onClick={() => handleViewChange(cView.key)}
            >
              {cView.label}
            </Button>
          })}
        </ButtonGroup>
      </Grid>
      {view === "pricing" ?
        <Grid item xs={12}>
          <MuiTable component="div">
            <TableHead component="div" style={tableHeadCss ?? {}}>
              <EligiblePartnersTableRow
                gridTemplate={gridTemplate}
                loanDetails={loanDetails}
                eligiblePartnersColumns={eligiblePartnersColumns}
                eligiblePartnerResults={eligiblePartnerResults}
                isApplicationSubmitted={isApplicationSubmitted}
                takeOutPartnerLists={takeOutPartnerLists}
                changePartner={changePartner}
                isLATUser={isLATUser}
                showPricingOnlyForEligible={showPricingOnlyForEligible}
              />
            </TableHead>
          </MuiTable>
          <div style={{ display: "flex", minWidth: tableWidth }} id="parent-div">
            <div style={{ maxWidth: tableWidth, width: tableWidth, minWidth: tableWidth }} id="child-div">
              {tablesContents?.map((tableContent: any, index: number) => {
                 return <CustomTable
                  key={index}
                  columnsData={tableContent.columns}
                  rowsData={tableContent.rowData}
                  customTableRowStyle={{ display: "flex" }}
                  showFooter={false}
                />
              })}
              </div>
              {ineligiblePartners?.map((partner: any, idx: number) => {
                return <div style={{ maxWidth: "calc(50% - 150px)", width: "calc(50% - 150px)", border: "1px solid rgba(224, 224, 224, 1)", background: "white", display: "flex", justifyContent: "center", alignItems: "start", padding: "10%" }}>
                  <div>
                    <div className="rotate-container">
                      <img src={LoaderSvg} className="rotating-svg" />
                    </div>
                    <p style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#666666"
                    }}> Cannot price this loan due to eligibility fail. Please review the Test Result tab. </p></div>
                </div>
              })}
          </div>
        </Grid> : (
          TestResultData?.viewType === "programme-selection" ?
            <TestResultComponent
              tabData={testTabData}
              isExpanded={isExpanded}
              expandLeverageAndTests={expandLeverageAndTests}
              setIsExpanded={setIsExpanded}
              loanDetails={loanDetails}
              isLoanDSCR={isDSCR}
              ratesAsOfDate={ratesAsOfDate}
              eligiblePartnerResults={eligiblePartnerResults}
              changePartner={changePartner}
              CustomCard={CustomCard}
              CustomChip={CustomChip}
              DSCROrgPartnerMapping={DSCROrgPartnerMapping}
              DownArrow={DownArrow}
              InfoIcon={InfoIcon}
            />
            : <TestTabResultView
              tabData={testTabData}
              isExpanded={isExpanded}
              expandLeverageAndTests={expandLeverageAndTests}
              setIsExpanded={setIsExpanded}
              loanDetails={loanDetails}
              isLoanDSCR={isDSCR}
              ratesAsOfDate={ratesAsOfDate}
              eligiblePartnerResults={eligiblePartnerResults}
              changePartner={changePartner}
              CustomCard={CustomCard}
              CustomChip={CustomChip}
              DSCROrgPartnerMapping={DSCROrgPartnerMapping}
              DownArrow={DownArrow}
              InfoIcon={InfoIcon}
              NonCriticalTestIcon={NonCriticalTestIcon}
              CriticalTestIcon={CriticalTestIcon}
              isApplicationSubmitted={isApplicationSubmitted}
              CheckCirclePass={CheckCirclePass}
              showRadioSelection={showRadioSelection}
            />
        )}
    </Grid>
  )
}