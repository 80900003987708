import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider, QueryClient } from "react-query";

import { ThemeProvider, createTheme } from "@mui/material/styles";


import App from "./App";
import i18n from "./i18n";

import "./index.css";
import { createGenerateClassName, StylesProvider } from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#362866",
    },
  },
  typography: {
    fontFamily: `Poppins`,
    fontWeightRegular: 400,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'br', // customize prefix
  disableGlobal: true,       // disable global scope if necessary
});


const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          {/* <QueryClientProvider client={queryClient}> */}
            <App />
          {/* </QueryClientProvider> */}
        </ThemeProvider>
      </StylesProvider>
    </I18nextProvider>
  </React.StrictMode>
);
