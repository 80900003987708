import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import SignIn from "../pages/SignIn/SignIn";
import EvaluateLoan from "../pages/EvaluateLoans/EvaluateLoan";
import ListView from "../pages/ListView/ListView";
import LoanPage from "../pages/Loans/LoanPage";
import Redirect from "../pages/Redirect";

import { RootContext } from "../context/RootContext";

import { getCookie } from "../utils/Common";

import { ObjectType } from "../types/tableTypes";

import RouteGuard from "./RouteGuard";

export default function CustomRoutes() {
  const { setUserPermissions } = useContext(RootContext);

  useEffect(() => {
    const roles = getCookie("userPermissions") || "[]";
    if (roles) {
      const userPermissions = JSON.parse(roles);
      setUserPermissions(userPermissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routesMap = [
    // {
    //   path: "/",
    //   element: <SignIn />
    // },
    {
      path: "/evaluate-loan",
      element: <EvaluateLoan />
    }, {
      path: "/list-view",
      element: <ListView />
    }, {
      path: "/loan/:loanType/:propType?/:loanId",
      element: <LoanPage />
    }, {
      path: "*",
      element: <Redirect />
    }
  ];

  return (
    <Router>
      <Routes>
        {routesMap.map((routeEle: ObjectType, idx: number) => {
          return <Route
            key={idx}
            path={routeEle.path}
            element={
              <RouteGuard>
                {routeEle.element}
              </RouteGuard>
            }
          />
        })}
      </Routes>
    </Router>
  );
}
